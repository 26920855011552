import { smartStorage, hardRedirect } from "@/helpers";
import store from "@/store";
import $http from "@/helpers/http";

const actions = {
  BillingAndSubscription: "billing",
  BillingAndUpgrade: "upgradePlan",
  BuyEmailAccount: "buyMoreAccounts",
};

function handlePaymentRedirection(route) {
  return $http.post(`panel/payment/initPayment`, {
    action: actions[route.name],
  });
}

export default {
  beforeRouteEnter(to, _, next) {
    const { hideBilling, trials } = store.getters.paymentRoutingInfo;
    handlePaymentRedirection(to)
      .then((externalPayment) => {
        const shouldOpenPartnerUrl =
          externalPayment &&
          externalPayment.makePaymentUrl !== "inAppPayment" &&
          hideBilling;
        const isProductOnTrial = trials[to.params.addon]?.config.trialEnabled;
        if (shouldOpenPartnerUrl && !isProductOnTrial) {
          const cpURL = encodeURIComponent(
            `${window.location.origin}/postPayment`
          );
          const redirectURL = `${externalPayment.makePaymentUrl}?jwt=${externalPayment.jwt}&redirect_url=${cpURL}`;
          smartStorage.hostRedirectURL = redirectURL;
          hardRedirect(redirectURL, false, true);
        } else {
          next();
        }
      })
      .catch((err) => {
        next((vm) => {
          vm.showToast(err.desc || vm.$t("manageSubscriptions.commonError"));
        });
      });
  },
};
