import { smartStorage } from ".";
import { ADMIN_ROLE } from "./const";

export const getLoggedInUserRole = () => smartStorage.getItem("role");

export const getAccountId = () => smartStorage.getItem("accountId");

export const getLoggedInUserType = () => smartStorage.getItem("adminType");

export const getCurrentUserEmail = () =>
  smartStorage.getItem("loginEmail") || "";

export const isLoginViaPartnerPanel = () => {
  const role = getLoggedInUserRole();
  return [ADMIN_ROLE.TITAN, ADMIN_ROLE.TITAN_SUPPORT].includes(role);
};
