export const domainUnavailableReasons = {
  DOMAIN_ALREADY_EXISTS: {
    event_error_message: "Domain already in use",
    event_availability_message: "Unavailable",
  },
  INTERNAL_DOMAIN: {
    event_error_message: "Blocked - Internal",
    event_availability_message: "Blocked",
  },
  SPAM_DOMAIN: {
    event_error_message: "Blocked - Spam",
    event_availability_message: "Blocked",
  },
  TOP_BRAND: {
    event_error_message: "Blocked - Brand",
    event_availability_message: "Blocked",
  },
  BLOCK_REQUEST_FROM_PARTNER: {
    event_error_message: "Blocked - Partner",
    event_availability_message: "Unavailable",
  },
  DOMAIN_UNDER_VERIFICATION: {
    event_error_message: "Domain under verification",
    event_availability_message: "Unavailable",
  },
  DOMAIN_BELONGS_TO_CUSTOMER: {
    event_error_message: "Own domain",
    event_availability_message: "Own domain",
  },
  DISALLOWED_DOMAIN: {
    event_error_message: "Shared subdomain",
    event_availability_message: "Unavailable",
  },
  UNREGISTERED_DOMAIN: {
    event_error_message: "Unregistered domain",
    event_availability_message: "Unavailable",
  },
  NO_CHANGE: {
    event_error_message: "No changes made",
    event_availability_message: "Own domain",
  },
};

export const DOMAIN_UNAVAILABILITY_ERROR_MAP = {
  BLOCK_REQUEST_FROM_PARTNER: "DOMAIN_NOT_AVAILABLE",
  DISALLOWED_DOMAIN: "DISALLOWED_DOMAIN",
  DOMAIN_ALREADY_EXISTS: "DOMAIN_IN_USE",
  DOMAIN_BELONGS_TO_CUSTOMER: "DOMAIN_NOT_AVAILABLE",
  DOMAIN_UNDER_VERIFICATION: "UNVERIFIED_DOMAIN",
  INTERNAL_DOMAIN: "DOMAIN_NOT_AVAILABLE",
  SPAM_DOMAIN: "DOMAIN_NOT_AVAILABLE",
  TOP_BRAND: "DOMAIN_NOT_AVAILABLE",
  UNREGISTERED_DOMAIN: "UNREGISTERED_DOMAIN",
  NO_CHANGE: "NO_CHANGE",
};

export const ERROR_KEYS = {
  DOMAIN_IN_USE: "DOMAIN_IN_USE",
  DOMAIN_NOT_AVAILABLE: "DOMAIN_NOT_AVAILABLE",
  DOMAIN_NOT_REGISTERED: "DOMAIN_NOT_REGISTERED",
  EMPTY_DOMAIN: "EMPTY_DOMAIN",
  HAS_PROTOCOL: "HAS_PROTOCOL",
  NO_CHANGE: "NO_CHANGE",
  REQUEST_UNSUCCESSFUL: "REQUEST_UNSUCCESSFUL",
  DISALLOWED_DOMAIN: "SHARED_SUBDOMAIN",
  UNVERIFIED_DOMAIN: "UNVERIFIED_DOMAIN",
};
