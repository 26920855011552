<template>
  <div>
    <div class="input-wrapper send-details">
      <input
        type="text"
        class="input"
        :placeholder="placeholder"
        v-model.trim="recipient"
        autocomplete="new-password"
      />
      <span
        @click="sendEmail"
        class="addon-span"
        v-bind:class="{ invalid: !verifyEmail(recipient) }"
        >{{ $t("utility.send") }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SendDetails",
  data() {
    return {
      recipient: "",
      isSent: false,
    };
  },
  props: {
    email: String,
    password: String,
    mailFlow: String,
    view: Object,
    placeholder: String,
  },
  methods: {
    verifyEmail(email) {
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
        email
      );
    },
    sendEmail() {
      if (this.verifyEmail(this.recipient)) {
        if (!this.isSent) {
          this.isSent = true;
          this.http.postData(
            "panel/account/email/send-login-details",
            {
              email: this.email,
              recipient: this.recipient,
              domainName: smartStorage.getItem("domain"),
            },
            this.$root.headers,
            this.onSendEmailSuccess,
            this.onSendEmailError
          );
        }
      }
    },
    onSendEmailSuccess(data) {
      if (data) {
        this.showToast(
          this.$t("toast.detailsSent"),
          {
            type: "success",
            modalName: "create-new-email-account",
          },
          2000
        );
        this.isSent = false;
        this.recipient = "";
      }
    },
    onSendEmailError(response) {
      if (response && response.data && response.data.desc) {
        this.showToast(
          response.data.desc,
          {
            type: "success",
            modalName: "create-new-email-account",
          },
          2000
        );
        this.isSent = false;
      }
    },
  },
};
</script>
