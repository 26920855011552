<template>
  <div id="app" :data-theme="theme">
    <mobile-screen v-if="!shouldShowResponsiveLayout" />
    <div v-else>
      <div v-if="isOfacFlag">
        <Ofac
          :ofacDocumentation="ofacDocumentation"
          :supportEmail="supportEmail"
          :brandLogo="brandLogo"
        />
      </div>
      <div v-else-if="hideLoader || !authenticated">
        <div v-if="authenticated">
          <app-header
            :brandDetails="brandDetails"
            :planDisplayName="planDisplayName"
            :showPlanBadge="isPaidPlan || isTrialPlan"
            :logout="logout"
            :imageUrl="imageUrl"
            :domainList="domainList"
            v-if="!view.hideAppHeader && !isMobile"
          />

          <div v-if="!view.hideAppHeader && isMobile">
            <app-header-mobile
              :brandDetails="brandDetails"
              :imageUrl="imageUrl"
              :logout="logout"
              :type="DOMAIN_SWITCHER_TYPES.MOBILE"
              :paymentEnabled="paymentEnabled"
              :showPlanExpiredError="showPlanExpiredError"
              :determineBannerTextKey="determineBannerTextKey"
              :bannerButtonTxt="bannerButtonTxt"
              :redirectToBilling="redirectToBilling"
              :planDisplayName="planDisplayName"
            />
          </div>

          <!-- TODO:this modal implementation is not as per the expectation we wil revisit separately on this -->
          <!-- <domain-admin-welcome-screen
            v-if="domainList.length > 0 && isDomainAdmin"
          /> -->
          <!-- once you fix above TODO make sure to set if-else order -->
          <neo-welcome-screen
            v-if="brandDetails.showWelcomePopup && isFromNeo"
            @onClosePopup="isFromNeo = false"
          />
          <div
            :class="{
              'banner-for-widget': view.showBannerOnHomePage,
            }"
            v-if="
              view.name === 'defaultView' ||
              (view.showBannerOnHomePage && $route.name === view.home)
            "
          >
            <div
              :class="['error-validation', `level${showTopGutterSpace}`]"
              v-if="paymentEnabled && showPlanExpiredError"
            >
              <i /><span />
              {{
                $t(`bannerText.${determineBannerTextKey}.desc`, [
                  planDisplayName.toUpperCase(),
                ])
              }}
              <TitanButton
                type="transparent"
                class="active-now"
                small
                @click="redirectToBilling()"
                >{{
                  $t(`bannerText.planExpiredError.${bannerButtonTxt}`)
                }}</TitanButton
              >
            </div>
            <DNSBanner
              v-else-if="showDNSBanner && !view?.flags?.hideDNSBanner"
              :dnsLookupTimeout="dnsLookupTimeout"
              :domainNotFound="domainNotFound"
              :nsNotVerified="nsNotVerified"
              :getDnsAndEmailMedusaAttrs="getDnsAndEmailMedusaAttrs"
              :domainNotVerifiedOrNotConnected="domainNotVerifiedOrNotConnected"
              :poorEmailReputation="poorEmailReputation"
              :domainOwnershipNotVerified="domainOwnershipNotVerified"
              :bannerLoading="bannerLoading"
            />
            <!-- <div
              :class="['warning-validation', `level${showTopGutterSpace}`]"
              v-else-if="paymentEnabled && isPlanExpiring"
            >
              <i />{{
                $t("bannerText.planExpiredWarning.desc", [
                  planDisplayName.toUpperCase(),
                  daysUntilExpiry,
                ])
              }}
              <TitanButton
                type="transparent"
                class="active-now"
                small
                @click="redirectToBilling()"
                >{{
                  $t(
                    `bannerText.planExpiredWarning.${
                      isFreePlan ? "btnUpgrade" : "btnRenew"
                    }`
                  )
                }}</TitanButton
              >
            </div> -->
            <div
              :class="['warning-validation', `level${showTopGutterSpace}`]"
              v-else-if="paymentEnabled && isAddonExpiring"
            >
              <i />{{
                $t("bannerText.addonExpiredWarning.desc", [daysUntilExpiry])
              }}
              <TitanButton
                type="transparent"
                class="active-now"
                small
                @click="redirectToBilling()"
                >{{ $t("bannerText.addonExpiredWarning.btnText") }}</TitanButton
              >
            </div>
            <div
              :class="['warning-validation', `level${showTopGutterSpace}`]"
              v-else-if="paymentEnabled && autoRenewWarning"
            >
              <i />{{
                $t("bannerText.autoRenewWarning.desc", [formatDate(expiresOn)])
              }}
              <TitanButton
                type="transparent"
                class="active-now"
                small
                @click="
                  setLocalFlag(`skipAutoRenewWarning-${domainName}`, expiresOn)
                "
                >{{ $t("bannerText.autoRenewWarning.btnText") }}</TitanButton
              >
            </div>
            <div
              :class="['warning-validation', `level${showTopGutterSpace}`]"
              v-else-if="paymentEnabled && showCongratsBanner"
            >
              <!-- monthly hardcoded as this is specifically for tso -->
              <span class="emoji" />
              <div
                v-html="
                  $t('bannerText.showCongratsBanner.desc', [
                    cr(currentPlanPrice && currentPlanPrice.monthly),
                  ])
                "
              />
              <TitanButton
                type="transparent"
                class="active-now"
                small
                @click="setLocalFlag(`skipCongratsBanner-${domainName}`)"
                >{{ $t("bannerText.showCongratsBanner.btnText") }}</TitanButton
              >
            </div>
          </div>
        </div>
        <div
          :class="{
            main: true,
            'non-iframe-widget': inWidgetModeWithoutIframe,
          }"
        >
          <SideNav
            v-if="authenticated && hideLoader"
            :addons="addons"
            :brandDetails="brandDetails"
            :currency="currency"
            :currentPlan="currentPlan"
            :externalPayment="externalPayment"
            :isFreePlan="isFreePlan"
            :isPaidPlan="isPaidPlan"
            :isTrialPlan="isTrialPlan"
            :paymentEnabled="paymentEnabled"
            :planExpiredError="showPlanExpiredError"
            :titanMaxEnabled="titanMaxEnabled"
            :topGutterSpace="showTopGutterSpace"
            :view="view"
            :imageUrl="imageUrl"
            ref="appView"
          />
          <div
            class="router-container"
            :class="
              showTopGutterSpace && authenticated && !view.hideSideBar
                ? `view increase-padding-container level${showTopGutterSpace}`
                : authenticated
                ? view.name !== 'defaultView'
                  ? `${view.name}View`
                  : 'view'
                : ''
            "
          >
            <div
              v-if="
                view &&
                view.showModalAsPage &&
                $route.path !== view.initialRoute &&
                $route.name !== view.home &&
                showExtraStuffInFooter
              "
              class="widgetViewContainer"
            >
              <widget-breadcrumb-nav
                :key="$route.path"
                v-if="!view?.flags?.hideBreadcrumb && $route.name !== view.home"
              />
              <!-- <h4 v-else-if="$route.name === view.home">
                {{ $t("emailAccounts.createEmail.emailLabel") }}
                {{ domainName !== "" ? "@" + domainName : "" }}
              </h4> -->
              <a
                :class="[
                  'help-link-icon',
                  { rightAlignIcon: $route.path === '/' },
                ]"
                target="_blank"
                v-if="!hideIFrameHelpButton"
                :href="brandDetails.adminGuide"
              />
            </div>
            <router-view
              @authCallback="authCallback"
              @fetchAddons="fetchAddons"
              @fetchCardDetails="fetchCardDetails"
              @fetchCurrentPlan="fetchCurrentPlan"
              @fetchEmailAccounts="fetchEmailAccounts"
              @fetchPaymentURLDetails="fetchPaymentURLDetails"
              @pageLoaded="pageLoaded"
              @updateCreatedEmailAccounts="updateCreatedEmailAccounts"
              :addons="addons"
              :brandDetails="brandDetails"
              :createdEmailAccounts="createdEmailAccounts"
              :currency="currency"
              :currentPlan="currentPlan"
              :domainNotFound="domainNotFound"
              :externalPayment="externalPayment"
              :getOtherMedusaAttrb="getDnsAndEmailMedusaAttrs"
              :onDNSLookupSuccess="onDNSLookupSuccess"
              :partnerId="partnerId"
              :partnerURLs="partnerURLs"
              :plans="planList"
              :savedCard="savedCard"
              :setOfacParams="setOfacParams"
              :titanMaxEnabled="titanMaxEnabled"
              :trials="trials"
              :view="view"
              :planType="planType"
              :canUpgradePlan="canUpgradePlan"
              :isFreePlan="isFreePlan"
              :fetchingEntriDetails="bannerLoading"
            />
            <div
              class="powered-by-titan"
              v-if="
                view.showPoweredByTitan &&
                $route.path !== '/' &&
                showExtraStuffInFooter
              "
            >
              <img alt="powered by" src="./assets/powered-by-titan-grey.svg" />
            </div>
          </div>
        </div>
        <language-switcher
          v-if="!authenticated && isShowSwitcher"
        ></language-switcher>
      </div>
      <Loader v-else />
    </div>
    <widget-toast-screen />
    <common-modal />
  </div>
</template>

<script>
import AppHeaderMobile from "@/components/Utility/AppHeaderMobile.vue";
import CommonModal from "@/components/Utility/CommonModal.vue";
import DomainSwitcher from "@/components/Utility/DomainSwitcher.vue";
import { DOMAIN_SWITCHER_TYPES } from "@/helpers/domainHelpers";
import { addPlanTrialInfo } from "@/helpers/plansHelpers";
import brand from "./helpers/brand";
import {
  ADDONS_ON_TRIAL,
  CONTENT_DIMENSION_DID_UPDATE,
  EMAIL_ACCOUNTS_CHANGED,
  IFRAME_WIDTH_CHANGED,
  bindCurrencySymbol,
  clearStorage,
  dayDiff,
  formatDate,
  getCookie,
  getCurrencySymbol,
  getDefaultCurrency,
  getFontForPartner,
  isAppLoadedInsideIframe,
  isMobile,
  sendPostMessageToParentWindow,
  setCookie,
  setWebsiteMetaData,
  setViewAndTheme,
  getJWTpayloadData,
  isDomainVerifiedAndConnected,
  isDomainMXRecordVerified,
  isDomainSpfVerified,
  isEmailReputationGood,
  isDomainOwnershipVerified,
  getFilteredQueryParams,
  hyphenToCamelCase,
  isNeoBrand,
  isNeoBusinessDomain,
  hardRedirect,
  getAppSource,
  shouldShowResponsiveCP,
  isCancelSubFlagPresent,
  isSiteARecordVerified,
  isSiteCNameRecordVerified,
  isNeoCustomDomain,
  parseDnsRecords,
  isSiteVerifiedAndConnected,
  getCurrentUserEmail,
} from "./helpers";
import viewTypes from "./views";
import eventBus from "./helpers/event-bus";
import throttle from "lodash/throttle";
import { mapGetters, mapActions } from "vuex";
const AppHeader = () => import("./components/Utility/AppHeader.vue");
const SideNav = () => import("./components/Utility/SideNav.vue");
const Login = () => import("./components/Auth/Login.vue");
const LanguageSwitcher = () => import("./components/Utility/LangSwitcher.vue");
const Ofac = () => import("./components/Ofac/Ofac.vue");
const Loader = () => import("./components/Utility/Loader.vue");
const MobileScreen = () => import("./components/MobileScreen/MobileScreen.vue");
import { loadCurrentLanguageFile } from "./i18n";
import {
  sendOrderAndCustomerEvent,
  getCommonMedusaParams,
} from "@/helpers/medusaUtils";
import { partnerMatcher } from "@/helpers/partner";
import DomainAdminWelcomeScreen from "@/components/Auth/DomainAdminWelcomeScreen.vue";
import NeoWelcomeScreen from "@/components/Auth/NeoWelcomeScreen.vue";
import DNSBanner from "./components/Banner/DNSBanner.vue";
import {
  ERROR_BANNER_TYPE,
  FLOW_TYPES,
  ROUTE_ACTIONS,
  ROUTE_ACTIONS_PROPS,
} from "./helpers/const";
import {
  getLambdaUrl,
  LAMBDA_ENTITIES,
  LAMBDA_FUNCTION_NAMES,
} from "./helpers/lambdaHelpers";
import { ENTRI_SETUP_TYPE, getEntriConfig } from "./helpers/entriConfig";
import { getEntriSetupUrl, isSiteSetupMode } from "./helpers/entri";
import { MEDUSA_EVENTS_MAP } from "./helpers/events";
import { measureInitialAppLoad } from "../src/analytics/performance/pageTimings";
import queryParamStore from "./global/queryParamStore";

let observedElementDetails = { element: null, observer: null };

const BANNER_TYPES = {
  PLAN_EXPIRED: "plan_expired",
  ADDON_EXPIRED: "addon_expired",
  ADDON_RENEWAL_WARNING: "addon_renewal_warning",
  PLAN_RENEWAL_WARNING: "plan_renewal_warning",
  AUTO_RENEWAL_WARNING: "auto_renewal_warning",
};

const BANNER_BUTTON_TEXT = {
  UPGRADE: "btnUpgrade",
  RENEW: "btnRenew",
  REACTIVATE: "btnReactivate",
};

const BANNER_ACTIONS = {
  UPGRADE: "upgradePlan",
  RENEW: "renewNow",
};

const { GET_ENTRI_TOKEN } = LAMBDA_FUNCTION_NAMES;
const { NEO } = LAMBDA_ENTITIES;

export default {
  name: "App",
  components: {
    DomainSwitcher,
    AppHeaderMobile,
    CommonModal,
    AppHeader,
    LanguageSwitcher,
    Loader,
    Login,
    MobileScreen,
    Ofac,
    SideNav,
    DomainAdminWelcomeScreen,
    NeoWelcomeScreen,
    DNSBanner,
  },
  data() {
    return {
      addons: [],
      authenticated: true,
      brandDetails: brand.getDetails(),
      brandLogo: null,
      cr: bindCurrencySymbol.bind(this),
      createdEmailAccounts: [],
      currentPlan: {},
      domainList: [],
      domainName: smartStorage.getItem("domain"),
      enableDNSVerification: false,
      formatDate,
      hideLoader: false,
      hideIFrameHelpButton: false,
      imageUrl: "",
      isFromWebmail: false,
      isFromNeo: false,
      isMobile: isMobile(),
      isOfacFlag: false,
      isPaidPlan: false,
      isShowSwitcher: false,
      isTitanMail: false,
      isTrialPlan: false,
      loginType: null,
      lookupData: null,
      bannerLoading: false,
      ofacDocumentation: "",
      partnerId: null,
      partnerURLs: {},
      paymentEnabled: false,
      planDisplayName: "",
      planList: [],
      rebrandedToTitan: false,
      savedCard: null,
      showCongratsBanner: false,
      showExtraStuffInFooter: false,
      supportEmail: "",
      theme: null,
      planType: "",
      canUpgradePlan: false,
      shouldShowResponsiveLayout: true,
      autoRenewWarningAck: false,
      isEntriDetailsFetched: false,
    };
  },
  computed: {
    DOMAIN_SWITCHER_TYPES() {
      return DOMAIN_SWITCHER_TYPES;
    },
    ...mapGetters([
      "externalPayment",
      "hideBilling",
      "locale",
      "trials",
      "view",
      "domainConnectData",
      "domainID",
      "showDomainAdminPopup",
      "entriSetupPossible",
      "partnerInfo",
    ]),

    domainNotFound() {
      return this.domainConnectData.DomainNotFound;
    },
    nsNotVerified() {
      return !this.domainConnectData?.nsRecords?.length;
    },
    domainNotVerifiedOrNotConnected() {
      return !isDomainVerifiedAndConnected();
    },
    domainOwnershipNotVerified() {
      return !isDomainOwnershipVerified();
    },
    dnsLookupTimeout() {
      return this.domainConnectData.LookupTimeout;
    },
    poorEmailReputation() {
      return !isEmailReputationGood();
    },
    showDNSBanner() {
      return (
        this.domainNotFound ||
        this.nsNotVerified ||
        this.domainNotVerifiedOrNotConnected ||
        this.dnsLookupTimeout ||
        this.poorEmailReputation
      );
    },
    isDomainAdmin() {
      return this.showDomainAdminPopup;
    },
    inWidgetModeWithoutIframe() {
      return this.view.name === "widget" && !isAppLoadedInsideIframe();
    },
    amountToBePaidOnRenewal() {
      return this.currentPlan.amountToBePaidOnRenewal;
    },
    currentPlanDetails() {
      return this.planList.find(({ id }) => id === this.currentPlan.planId);
    },
    daysUntilExpiry() {
      return dayDiff(this.expiresOn);
    },
    subscriptionCancelled() {
      return isCancelSubFlagPresent(this.currentPlan.domainFlags);
    },
    bannerButtonTxt() {
      return this.isFreePlan &&
        ![
          BANNER_TYPES.ADDON_EXPIRED,
          BANNER_TYPES.ADDON_RENEWAL_WARNING,
        ].includes(this.visibleBannerType)
        ? BANNER_BUTTON_TEXT.UPGRADE
        : this.subscriptionCancelled && isNeoBrand()
        ? BANNER_BUTTON_TEXT.REACTIVATE
        : BANNER_BUTTON_TEXT.RENEW;
    },
    action() {
      return this.isFreePlan &&
        ![
          BANNER_TYPES.ADDON_EXPIRED,
          BANNER_TYPES.ADDON_RENEWAL_WARNING,
        ].includes(this.visibleBannerType)
        ? BANNER_ACTIONS.UPGRADE
        : BANNER_ACTIONS.RENEW;
    },
    determineBannerTextKey() {
      if (this.isFreePlan) {
        return ERROR_BANNER_TYPE.ADDON_EXPIRED_ERROR;
      }

      if (!isNeoBrand()) {
        return ERROR_BANNER_TYPE.PLAN_EXPIRED_ERROR;
      }

      if (this.subscriptionCancelled) {
        return isNeoBusinessDomain()
          ? ERROR_BANNER_TYPE.SUBSCRIPTION_CANCELLED_COSITE
          : ERROR_BANNER_TYPE.SUBSCRIPTION_CANCELLED;
      }

      return isNeoBusinessDomain()
        ? ERROR_BANNER_TYPE.SUBSCRIPTION_EXPIRED_PAYMENT_FAILURE_COSITE
        : ERROR_BANNER_TYPE.SUBSCRIPTION_EXPIRED_PAYMENT_FAILURE;
    },
    hasPlanExpired() {
      return this.daysUntilExpiry < 0;
    },
    brandName() {
      return this.brandDetails.name;
    },
    hasAddons() {
      return this.currentPlan.addons?.length > 0;
    },
    expiresOn() {
      return this.currentPlan.expiresOn;
    },
    autoRenewWarning() {
      return (
        this.visibleBannerType === BANNER_TYPES.AUTO_RENEWAL_WARNING &&
        !this.autoRenewWarningAck
      );
    },
    isAddonExpiring() {
      return this.visibleBannerType === BANNER_TYPES.ADDON_RENEWAL_WARNING;
    },
    // isPlanExpiring() {
    //   return this.visibleBannerType === BANNER_TYPES.PLAN_RENEWAL_WARNING;
    // },
    showPlanExpiredError() {
      return (
        this.hasPlanExpired &&
        (!this.isFreePlan ||
          (this.isFreePlan && this.amountToBePaidOnRenewal > 0))
      );
    },
    // flag is responsible to show plan/addon expiry message/warning banner
    visibleBannerType() {
      // handle plan expired banner
      if (this.hasPlanExpired) {
        // its a free plan but it had payable amount (which means it has addons)
        if (this.isFreePlan && this.amountToBePaidOnRenewal) {
          return BANNER_TYPES.ADDON_EXPIRED;
        } else {
          // all plans are expirable
          return BANNER_TYPES.PLAN_EXPIRED;
        }
      }

      // // handle plan expire warning banner, 7days before expiry
      // if (
      //   !this.showPlanExpiredError &&
      //   !this.currentPlan.autoRenew &&
      //   this.daysUntilExpiry <= 7
      // ) {
      //   // user is on free plan and has some addons purchased
      //   if (
      //     !this.currentPlanDetails.isPaid &&
      //     !this.currentPlanDetails.isTrial &&
      //     this.hasAddons
      //   ) {
      //     // with addon
      //     return BANNER_TYPES.ADDON_RENEWAL_WARNING;
      //   } else {
      //     return BANNER_TYPES.PLAN_RENEWAL_WARNING;
      //   }
      // }

      // handle auto renew warning banner, 3 days before expiry
      if (
        !this.showPlanExpiredError &&
        this.currentPlan.autoRenew &&
        this.daysUntilExpiry <= 3 &&
        this.daysUntilExpiry > 0 &&
        !this.autoRenewWarningAck
      ) {
        return BANNER_TYPES.AUTO_RENEWAL_WARNING;
      }
      return "";
    },
    titanMaxEnabled() {
      return !!this.addons.find((a) =>
        this.view.addonsWithFakedoor.includes(a.type)
      );
    },
    showTopGutterSpace() {
      let level = 0;
      const nsNotFound =
        this.lookupData &&
        this.lookupData.nsRecords &&
        !this.lookupData.nsRecords.length;

      if (this.showDNSBanner || this.domainNotFound || nsNotFound) {
        // level++; // incase we want to support stacking
        level = 1;
      }

      if (
        (this.showPlanExpiredError && this.paymentEnabled) ||
        (this.autoRenewWarning && this.paymentEnabled) ||
        // (this.isPlanExpiring && this.paymentEnabled) ||
        (this.isAddonExpiring && this.paymentEnabled) ||
        (this.showCongratsBanner && this.paymentEnabled)
      ) {
        // level++; // incase we want to support stacking
        level = 1;
      }

      return level;
    },
    isFreePlan() {
      return !this.isPaidPlan && !this.isTrialPlan;
    },
    currentPlanPrice() {
      return (
        this.currentPlan &&
        (this.isPaidPlan
          ? this.currentPlan.planDetails.pricing
          : this.currentPlan.paidPlan.pricing)
      );
    },
    currency() {
      const currencyCode =
        (this.currentPlan && this.currentPlan.currency) ||
        this.$root.defaultCurrency;
      return getCurrencySymbol(currencyCode);
    },
  },
  methods: {
    ...mapActions({
      setDomainList: "domainList",
      setDomainConnectData: "domainConnectData",
      setEntriSetupPossible: "entriSetupPossible",
      setEntriDnsProvider: "entriDnsProvider",
      setCardType: "paymentCardType",
    }),
    onIframeDimensionChange: throttle(
      function (event) {
        const eventName = event.data.event;
        if (eventName === IFRAME_WIDTH_CHANGED) {
          const element = document.getElementById("app");
          sendPostMessageToParentWindow(CONTENT_DIMENSION_DID_UPDATE, {
            height: element.scrollHeight,
            width: element.scrollWidth,
          });
        }
      },
      2000,
      { trailing: true }
    ),
    observeChangesInDOM(element) {
      const observer = new ResizeObserver(
        throttle(
          (entries) => {
            for (let entry of entries) {
              const element = entry.contentRect;
              sendPostMessageToParentWindow(CONTENT_DIMENSION_DID_UPDATE, {
                height: element.height,
                width: element.width,
              });
            }
          },
          2000,
          { trailing: true }
        )
      );
      observer.observe(element);
      return { element, observer };
    },
    /**
     * Triggers an analytics event to Medusa with the login information.
     */
    triggerLoginEvent() {
      if (smartStorage.getItem("isLoggedIn")) return;
      smartStorage.setItem("isLoggedIn", true);
      var eventObj = {
        eventName: "control_panel_logged_in",
        auto_login: this.loginType === "autoLogin",
        cp_type: isAppLoadedInsideIframe() ? "iframe" : "normal",
        browser_locales: navigator.languages,
        titan_locale: document.documentElement.getAttribute("lang"),
        partner_id: this.partnerId,
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          eventObj,
          this.domainName,
          "flock_order"
        )
      );
    },
    authCallback(loginType, partnerId) {
      this.setViewAndTheme();
      this.loginType = loginType;
      this.partnerId = partnerId;
      this.domainName = smartStorage.getItem("domain");
      this.$store.dispatch("partnerId", partnerId);
      if (loginType && partnerId) {
        this.triggerLoginEvent();
      }
      this.redirectToPath();
    },
    pageLoaded(flag) {
      this.showExtraStuffInFooter = flag;
    },
    /**
     * Sets the user's locale to the domain set by backend.
     * Locale preference is in the following order:
     * 1. locale parameter from the query string.
     * 2. locale parameter from panel/domain/list response
     * 3. locale from JWT (possibly deprecated)
     * 4. locale from browser
     * The locale from JWT is deprecated as backend folks claim there was never any locale in JWT.
     * @param {String} domainLocale - the locale of the domain response
     */
    setLocale(domainLocale) {
      const { locale: queryLocale, jwt: token } = this.$route.query;
      const { locale: jwtLangLocale } = token ? getJWTpayloadData(token) : {};
      console.log(
        "locale",
        queryLocale,
        domainLocale,
        jwtLangLocale,
        navigator.language
      );
      loadCurrentLanguageFile(
        queryLocale || domainLocale || jwtLangLocale || navigator.language
      );
    },
    setViewAndTheme() {
      const viewAndThemeObj = setViewAndTheme(
        this.$route.query.view,
        this.$route.meta.view,
        this.$route.query.customTheme,
        this.$route.meta.customTheme
      );
      this.$store.dispatch("view", viewTypes[viewAndThemeObj.view]);
      this.theme = viewAndThemeObj.theme;
      this.$store.dispatch("theme", this.theme);
      getFontForPartner(this.theme);
      if (this.view.hideScrollbar) {
        document.body.classList.add("without-scrollbar");
        document.documentElement.style.scrollbarWidth = "none"; // Firefox
      }
    },
    setOfacParams(ofacObj) {
      const {
        isOfacFlag,
        brandLogo,
        supportEmail,
        ofacDocumentation,
      } = ofacObj;
      this.isOfacFlag = isOfacFlag;
      this.brandLogo = brandLogo;
      (this.supportEmail = supportEmail),
        (this.ofacDocumentation = ofacDocumentation);
    },
    getDnsAndEmailMedusaAttrs() {
      const otherInfo = this.lookupData
        ? {
            mx: (this.lookupData.mxRecords || []).join(" , "),
            spf: (this.lookupData.spfRecords || []).join(" , "),
            ns: (this.lookupData.nsRecords || []).join(" , "),
            a: (this.lookupData.aRecords || []).join(" , "),
            domainconnect: this.lookupData.isDomainConnectDomain,
          }
        : {};

      return {
        adminEmail: getCurrentUserEmail(),
        no_of_pop_email_created: this.createdEmailAccounts.length,
        ...otherInfo,
      };
    },
    handleEntriSetupRedirection(setupType, setupFor) {
      const isAutomaticSetupType = setupType === ENTRI_SETUP_TYPE.AUTOMATIC;

      // medusa events
      const commonMedusaAttribute = {
        auto_domain_setup_supported: this.entriSetupPossible,
        connection_method: setupType,
      };

      if (!isSiteSetupMode(setupFor)) {
        this.medusaEvents.trackEvent({
          eventName: "mx_banner_clicked",
          source: getAppSource(),
          ...getCommonMedusaParams(),
          ...this.getDnsAndEmailMedusaAttrs(),
          ...commonMedusaAttribute,
        });
      }

      // if Entri Automatic setup possible
      if (isAutomaticSetupType) {
        const dnsSetupUrl = this.partnerInfo?.urls?.dnsRecordSetupUrl;
        const domainName = this.domainName;
        const domainId = this.domainID;
        hardRedirect(
          getEntriSetupUrl({
            setupFor,
            dnsSetupUrl,
            domainName,
            domainId,
          }),
          true,
          true
        );
      }
    },
    redirectToBilling() {
      const isNeoPaymentFailure =
        [
          ERROR_BANNER_TYPE.SUBSCRIPTION_EXPIRED_PAYMENT_FAILURE_COSITE,
          ERROR_BANNER_TYPE.SUBSCRIPTION_EXPIRED_PAYMENT_FAILURE,
        ].indexOf(this.determineBannerTextKey) > -1;

      if (isNeoPaymentFailure) {
        // open payment card section
        queryParamStore.set(ROUTE_ACTIONS.RENEW_CARD_PAYMENT, true);
      }

      this.$refs.appView.getJWTpayloadForUpgrade("/billing-and-upgrade", true, {
        [this.action]: true,
      });

      this.informMedusaAboutBanner("click");
    },
    setLocalFlag(flag, value) {
      smartStorage.setItem(`preserve__${flag}`, value || true);
      if (flag.indexOf("skipAutoRenewWarning") !== -1) {
        this.autoRenewWarningAck = true;
      } else if (flag.indexOf("skipCongratsBanner") !== -1) {
        this.showCongratsBanner = false;
      }
    },
    authenticate() {
      return this.$http
        .post("panel/authenticate", {
          domainName: this.domainName,
          externalToken: smartStorage.getItem("auth"),
        })
        .then(this.onAuthSuccess)
        .catch(this.onAuthFailure);
    },
    onAuthSuccess(data) {
      if (data) {
        smartStorage.authToken = this.setSafeValues(data, ["token"]);
        smartStorage.partnerId = this.setSafeValues(data, ["partnerId"]);
        this.$store.dispatch("partnerId", data.partnerId);
        this.$store.dispatch("partnerName", data.companyName);
        const showAltUserEmail =
          data.adminType === "customer" && !data.userEmail;
        if (showAltUserEmail) {
          smartStorage.showAltUserEmail = showAltUserEmail;
        } else {
          smartStorage.tempUserAltEmail = data.userEmail;
        }
        smartStorage.removeItem("auth");
        this.redirectToPath();
      }
    },
    onAuthFailure(response) {
      if (response && response.data && response.data.desc) {
        this.logout();
        this.showToast(response.data.desc);
      }
    },
    redirectToPath() {
      this.$root.headers = {
        headers: {
          Authorization: smartStorage.getItem("authToken"),
          "X-Auth": "controlPanel",
        },
      };
      if (smartStorage.getItem("partnerId") === undefined) {
        this.getPartnerInfo()
          .then(this.onSuccessPartnerFetch)
          .catch(this.onErrorPartnerFetch);
      }
      this.fetchPlans();
    },
    fetchPlans() {
      // FIXME: at some point we have to take default currency from server
      // may be based on timezone / country
      this.$root.defaultCurrency = getDefaultCurrency();

      return this.$http
        .get(`panel/billing/get-all-plans`)
        .then(this.onFetchPlanSuccess)
        .catch(this.onFetchPlanError);
    },
    onFetchPlanError() {
      // in case the app is not inside an iframe, log the user out.
      // if it's loaded inside an iframe, no need to do anything as the
      // partner side handles data transfer to the iframe
      if (!isAppLoadedInsideIframe()) {
        this.logout();
      }
      this.setPlansInStorage([]);
    },
    onFetchPlanSuccess(data) {
      this.planList = data.plans.map((p) => ({
        ...p,
        displayName: p.displayName || p.type,
      }));
      this.setPlansInStorage(this.planList);
      this.getAllDomains();
    },
    logout() {
      var eventObj = {
        eventName: "control_panel_logout_button_click",
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          eventObj,
          this.domainName,
          "flock_order"
        )
      );
      clearStorage();
      this.$router.push({ name: "Login" });
      this.authenticated = false;
    },
    getImapHost() {
      return this.$http.get("internal/getIMAPHost").then((data) => {
        smartStorage.imapHost = this.setSafeValues(data, ["imapHost"]);
      });
    },
    onErrorPartnerFetch() {
      this.isTitanMail = brand.deriveBrandFromURL() === brand.NAMES.TITAN;

      if (this.isTitanMail) {
        this.setDNSInfo();
      } else {
        this.setBrandDetails();
      }

      setWebsiteMetaData.call(this);
    },
    onSuccessPartnerFetch(data) {
      this.partnerId = data.partnerId;
      this.$store.dispatch("partnerId", data.partnerId);
      this.$store.dispatch("partnerName", data.companyName);
      this.$store.dispatch("theme", data.resources.cpThemeId);
      this.$store.dispatch("partnerInfo", data);
      this.$store.dispatch("isDomainPaid", this.currentPlan.isDomainPaid);
      this.triggerLoginEvent();
      this.supportEmail = data.emails && data.emails.supportEmail;
      this.ofacDocumentation = data && data.urls && data.urls.ofacSanctionGuide;
      const hideBilling = data.flags.hideBillingPageOnPaymentViaPartner;
      Object.keys(data.flags).forEach((iframeFlag) => {
        this.$store.dispatch("flags", {
          flag: iframeFlag,
          value: data.flags[iframeFlag],
        });
      });
      this.$store.dispatch(
        "hideBilling",
        hideBilling !== undefined ? hideBilling : false
      );
      this.hideIFrameHelpButton =
        (data.flags.hideIFrameHelpButton && isAppLoadedInsideIframe()) || false;
      this.imageUrl =
        data.resources &&
        (data.resources.controlPanelInvertedLogo
          ? data.resources.controlPanelInvertedLogo
          : data.resources.controlPanelLogoUrl);
      smartStorage.webmail = this.setSafeValues(data, ["urls", "webmail"]);
      this.domainName = smartStorage.getItem("domain");
      if (smartStorage.getItem("webmail") === "undefined") {
        // eslint-disable-next-line no-undef
        smartStorage.webmail = process.env.VUE_APP_OPEN_WEBMAIL_URL;
      }

      const currentPlanID = this.currentPlan.planId;
      const currentDomain = this.domainList.find(
        (ele) => ele.name === this.domainName
      );
      const domainCreationDiff = dayDiff(new Date(), currentDomain.createdAt);

      if (
        partnerMatcher.isTsoProTrialPlan(currentPlanID) &&
        domainCreationDiff > 0 &&
        domainCreationDiff <= 30 &&
        !smartStorage.getItem(`preserve__skipCongratsBanner-${this.domainName}`)
      ) {
        this.showCongratsBanner = true;
      }

      const { urls = {}, flags = {}, emails = {} } = data;
      this.$root.partnerURLs = urls;
      this.partnerURLs = { ...urls };
      this.paymentEnabled = this.setSafeValues(data, ["paymentEnabled"]);
      smartStorage.setItem("paymentEnabled", this.paymentEnabled || false);
      this.isTitanMail = flags.rebrandedToTitan;
      this.rebrandedToTitan = flags.rebrandedToTitan;
      this.enableDNSVerification = flags.enableDNSVerification;

      const brandOtherInfo = {
        supportEmail: emails.supportEmail,
        adminGuide: urls.adminGuide,
        billing: {
          termsOfUse: urls.termsOfUse,
          privacyPolicy: urls.privacyPolicy,
        },
      };

      if (this.enableDNSVerification) {
        // dns config is a common API and adding domain connect param to every dns config
        // call could add significant load system since it has external checks involved
        // so better to keep this flag based
        // currently checkDomainConnect would be enabled for godaddy (where forgoPayments flag is set)
        if (flags.forgoPayments) {
          smartStorage.setItem("checkDomainConnect", true);
        }

        this.setDNSInfo(brandOtherInfo);
      } else {
        this.setBrandDetails(brandOtherInfo);
        this.hideLoader = true;
        measureInitialAppLoad();
      }

      setWebsiteMetaData.call(this);
      this.informPendo(data.partnerId, currentDomain.createdAt);
    },
    informPendo(partnerId, domainCreatedOn) {
      const domainID = smartStorage.getItem("domainID");
      // eslint-disable-next-line no-undef
      pendo.initialize({
        visitor: {
          id: `${domainID}-order`,
          email: smartStorage.getItem("loginEmail"),
          // eslint-disable-next-line no-undef
          visitorSource: process.env.VUE_APP_PENDO_SOURCE,
          visitorType: "order",
        },
        account: {
          id: domainID,
          // eslint-disable-next-line no-undef
          accountSource: process.env.VUE_APP_PENDO_SOURCE,
          cpDomainName: this.domainName,
          cpPartnerId: partnerId,
          cpPlan: this.currentPlan.planDetails.type,
          cpDomainCreatedOn: domainCreatedOn,
        },
      });
    },
    onDNSLookupSuccess(data) {
      this.lookupData = data;
      this.setDomainConnectData(data);
      const domainConnectStatus = getCookie("domainConnectStatus");
      const prevDomainConnectStatus = getCookie("prevDomainConnectStatus");
      const currentDomainConnectStatus = `${data.mxVerified}-${data.spfVerified}`;
      const resultChanged =
        domainConnectStatus === "pending" &&
        prevDomainConnectStatus !== currentDomainConnectStatus;
      if (domainConnectStatus !== "pending" || resultChanged) {
        setCookie("domainConnectStatus", "", -1);
        setCookie("prevDomainConnectStatus", "", -1);
      }

      this.setBrandDetails(
        parseDnsRecords(data.expectedRecords, this.domainName)
      );

      this.hideLoader = true;
      measureInitialAppLoad();
      const mxVerified = isDomainMXRecordVerified();
      const spfVerified = isDomainSpfVerified();

      // check for email records
      if (mxVerified) {
        smartStorage.setItem("showCongratsNote", !!resultChanged);
        setCookie("domainConnectStatus", "", -1);
        setCookie("prevDomainConnectStatus", "", -1);
      } else if (isNeoCustomDomain()) {
        // if neo custom domain mx is not verified then check for entri setup
        this.getEntriSetupDetails();
      }

      this.fetchEntriDetailsIfMissing();

      if (mxVerified && spfVerified) {
        // once the mx and spf verified we dont need to keep
        // domain connect param in dns config api
        smartStorage.setItem("checkDomainConnect", false);
      }
    },
    fetchEntriDetailsIfMissing() {
      if (this.isEntriDetailsFetched) return false;

      const aVerified = isSiteARecordVerified();
      const cnameVerified = isSiteCNameRecordVerified();

      // check for site records
      if (isNeoCustomDomain() && !(aVerified && cnameVerified)) {
        this.getEntriSetupDetails();
      }
    },
    async getEntriSetupDetails() {
      const url = getLambdaUrl(NEO, GET_ENTRI_TOKEN);
      this.bannerLoading = true;
      this.http
        .postData(url)
        .then((response) => response)
        .then(async (data) => {
          const response = await entri.checkDomain(
            this.domainName,
            getEntriConfig(data.auth_token, {
              ...this.domainConnectData.expectedRecords,
              cname: [this.domainName],
            })
          );
          this.isEntriDetailsFetched = true;
          if (process.env.VUE_APP_ENV_FLAG !== "prod") {
            /* Log entri check domain response only on staging and development. */
            console.log("Entri response logged", response);
          }
          this.setEntriDnsProvider(response?.provider || "");
          switch (response.setupType) {
            case ENTRI_SETUP_TYPE.AUTOMATIC:
              this.setEntriSetupPossible(true);
              break;
            case ENTRI_SETUP_TYPE.MANUAL:
              this.setEntriSetupPossible(false);
              break;
            default:
              break;
          }
          // This might not needed as we do call this event on neo signup app
          sendOrderAndCustomerEvent.call(
            this,
            {
              eventName: MEDUSA_EVENTS_MAP.ENTRI_DOMAIN_CHECKED,
              domain_supported:
                response.setupType === ENTRI_SETUP_TYPE.AUTOMATIC,
              source_hook: "Control panel",
              domain_provider: response.provider,
            },
            false
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.bannerLoading = false;
        });
    },
    fetchEmailAccounts() {
      return this.$http.get(`panel/account/email/list`).then((data) => {
        if (data.accounts && data.accounts.length) {
          this.createdEmailAccounts = data.accounts;
          this.$store.dispatch(
            "currentDomainMailboxDetails",
            this.createdEmailAccounts
          );
          this.medusaEvents.send(
            this.medusaEvents.createEventObj(
              {
                eventName: "mx_banner_shown",
                source: getAppSource(),
                ...this.getDnsAndEmailMedusaAttrs(),
              },
              this.domainName,
              "flock_order"
            )
          );
        }
      });
    },
    updateCreatedEmailAccounts(list) {
      this.createdEmailAccounts = list;
    },
    onDnsLookupError() {
      this.setDomainConnectData({});
      this.setBrandDetails({
        mxItems: [],
        spfItems: [],
        domainOwnershipItems: [],
      });
      this.hideLoader = true;
      measureInitialAppLoad();
    },
    setDNSInfo(otherInfo) {
      this.setBrandDetails(otherInfo);
      if (this.enableDNSVerification) {
        this.runDnsLookup(
          (data) => {
            this.onDNSLookupSuccess(data);
            this.informMedusaAboutBanner();
          },
          (error) => {
            this.onDnsLookupError(error);
            this.informMedusaAboutBanner();
          },
          {
            source: "control_panel_banner",
          }
        );
      } else {
        this.hideLoader = false;
      }
    },
    setBrandDetails(otherInfo) {
      // set current brand and get its details
      brand.setDetails(otherInfo);
      this.brandDetails = brand.getDetails();
      this.brandLogo = this.brandDetails.logo;
    },
    getAllDomains() {
      return this.$http
        .get(`panel/domain/list`)
        .then(this.onDomainSuccess)
        .catch((e) => {
          this.hideLoader = true;
          measureInitialAppLoad();
          console.log(e);
        });
    },
    setPlanInfo(currentPlanDetails) {
      this.planType = currentPlanDetails && currentPlanDetails.type;
      smartStorage.setItem(
        "isPaid",
        currentPlanDetails && currentPlanDetails.isPaid
      );
      smartStorage.isTrial = currentPlanDetails && currentPlanDetails.isTrial;
      this.isPaidPlan = currentPlanDetails && currentPlanDetails.isPaid;
      this.isTrialPlan = currentPlanDetails && currentPlanDetails.isTrial;
      smartStorage.plan = currentPlanDetails
        ? currentPlanDetails.displayName
        : "";
      this.planDisplayName = currentPlanDetails
        ? currentPlanDetails.displayName
        : "";
    },
    fetchPaymentURLDetails(ac) {
      const billingActionType =
        this.hideBilling || this.view.openPaymentURLInDiffTab
          ? "billing"
          : null;
      const action = billingActionType || ac || "upgradePlan";
      return this.$http
        .post(`panel/payment/initPayment`, {
          action,
        })
        .then((data) => {
          let res = data;
          if (data.makePaymentUrl === "#") {
            this.paymentEnabled = false;
          } else {
            res = { ...data, action };
            this.$store.dispatch(
              "externalPayment",
              data.makePaymentUrl !== "inAppPayment" ? res : null
            );
          }
          return res;
        })
        .catch((error) => {
          console.error("Could not fetch payment details", error);
          this.$store.dispatch("externalPayment", null);
          return error;
        });
    },
    /**
     * Fetches the trial configuration from server.
     * @param addon {Object} - The addon for which configuration is to be fetched.
     */
    async fetchTrialConfig(addon) {
      const data = await this.$http.get("/panel/product/trial-config", {
        params: {
          product: addon.type,
        },
      });
      this.$store.dispatch("trials", {
        [addon.type]: data,
      });
    },
    /**
     * Fetches the trial config for all addons
     * @param addons {Array} - The array of addons obtained from GET /get-all-addons API for this account.
     */
    fetchAddonTrialConfigurations(addons) {
      for (const addonType of ADDONS_ON_TRIAL) {
        const addon = addons.find((a) => a.type === addonType);
        if (addon) {
          this.fetchTrialConfig(addon);
        }
      }
    },
    onDomainSuccess(data) {
      try {
        this.domainList = data.domains || [];
        this.setDomainList(this.domainList);
        if (this.isFromWebmail) {
          this.domainList = this.domainList.filter(
            (ele) => ele.name == this.domainName
          );
        }
        this.$root.domainList = this.domainList;
        // now currently plan will be decided from fetchPlans and not from domainList.
        let currentDomain = this.domainList.find(
          (ele) => ele.name === this.domainName
        );
        this.$store.dispatch("domainName", currentDomain.name);
        this.$store.dispatch("currentDomainDetails", currentDomain);
        this.setLocale(currentDomain.locale);
        let planId = currentDomain.planId || "";
        const currentPlanDetails = this.planList.find(
          (ele) => ele.id == planId
        );
        smartStorage.domainID = currentDomain.id;
        this.$store.dispatch("domainID", currentDomain.id);
        this.handleInternalRedirection();
        this.authenticated = true;
        this.setPlanInfo(currentPlanDetails);
        Promise.all([
          this.fetchPaymentURLDetails(),
          this.fetchCurrentPlan(),
          this.fetchCardDetails(),
          this.fetchEmailAccounts(),
        ])
          .then(this.fetchAddons)
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.getPartnerInfo()
              .then(this.onSuccessPartnerFetch)
              .catch(this.onErrorPartnerFetch);
          });
        this.getImapHost();
      } catch (e) {
        this.logout();
      }
    },
    handleInternalRedirection() {
      // `section` is a route name
      const section = smartStorage.getItem("section");
      // `action` is an identifier to decide what to do in that section/route
      const action = hyphenToCamelCase(smartStorage.getItem("action"));
      const actionProps = smartStorage.getItem("actionProps");
      if (section) {
        const launchEmail = smartStorage.getItem("launchEmail");
        const params = getFilteredQueryParams(["section"]);

        /**
         * WARN: lets avoid adding query params for internal redirection
         * we can achieve same with `queryParamStore`
         * we don't need to expose/maintain an another action specific params in url
         */
        switch (action) {
          case ROUTE_ACTIONS["LAUNCH-EMAIL-CREATION"]:
          case ROUTE_ACTIONS.LAUNCH_EMAIL_CREATION:
            smartStorage.launchEmailCreation = launchEmail
              ? decodeURIComponent(launchEmail)
              : "true";
            break;
          case ROUTE_ACTIONS.RENEW:
            params.set(FLOW_TYPES.RENEW_SUBSCRIPTION, "true");
            break;
          case ROUTE_ACTIONS.RENEW_CARD_PAYMENT:
            queryParamStore.set(ROUTE_ACTIONS.RENEW_CARD_PAYMENT, true);
            break;
          case ROUTE_ACTIONS.LAUNCH_HIGHER_PLAN_UPGRADE:
            params.set(ROUTE_ACTIONS.LAUNCH_HIGHER_PLAN_UPGRADE, "true");
            break;
          case ROUTE_ACTIONS.REACTIVATE_SUBSCRIPTION:
            params.set(ROUTE_ACTIONS.REACTIVATE_SUBSCRIPTION, "true");
            break;
          case ROUTE_ACTIONS.SHOW_PLAN_FEATURE:
            params.set(ROUTE_ACTIONS_PROPS.FEATURE_KEY, actionProps);
            break;
          default:
            break;
        }

        this.$router.replace(
          `/${section}${params ? `?${params.toString()}` : ""}`
        );
        this.cleanUpRedirectionQueryParams();
      } else if (
        // when authenticated user trying to access non-authenticated route
        // standalone mode
        (/login/i.test(this.$route.name) ||
          // widget mode
          this.$route.meta.view === "widget") &&
        this.$route.name !== this.view.home
      ) {
        this.$router.replace({ name: this.view.home });
      }
    },
    cleanUpRedirectionQueryParams() {
      // smartStorage.removeItems(["section", "action", "launchEmail"]);
      smartStorage.removeItem("section");
      smartStorage.removeItem("action");
      smartStorage.removeItem("launchEmail");
      smartStorage.removeItem("actionProps");
    },
    isNonEmptyObject(domainPricingAttr) {
      if (!domainPricingAttr || Object.keys(domainPricingAttr).length === 0) {
        return false;
      }
      return true;
    },
    setIsDiscountedPricingAndTrialInfoInPlans(currentPlan) {
      let planList = JSON.parse(smartStorage.planList);
      planList.forEach((plan) => {
        plan.planPricing.isDiscountedPricing =
          plan.planPricing[currentPlan.billingCycle].firstBillingCyclePrice <
          plan.planPricing[currentPlan.billingCycle].price;
      });
      planList = addPlanTrialInfo(planList, currentPlan);
      this.setPlansInStorage(planList);
    },
    setPlansInStorage(planList) {
      smartStorage.planList = JSON.stringify(planList);
    },
    fetchCurrentPlan(cb) {
      return this.$http
        .get(`panel/billing/get-current-plan`)
        .then((data) => {
          // success
          this.$store.dispatch("planId", data.planId); // get it from currentDetails store
          const currentPlanDetails = this.planList.find(
            (p) => p.id === data.planId
          );
          const domainDetails = this.domainList.find(
            (e) => e.name === this.domainName
          );
          const currentPlanDetailsStorePayload = {
            ...data,
            isTrial: currentPlanDetails.isTrial,
            isPaid: currentPlanDetails.isPaid,
            ...(currentPlanDetails.isTrial && {
              paidPlanId: currentPlanDetails.paidPlanId,
            }),
          };
          this.$store.dispatch(
            "currentPlanDetails",
            currentPlanDetailsStorePayload
          );
          this.currentPlan = {
            billingCycle: "yearly", // default
            ...data,
            domainDetails,
            planDetails: currentPlanDetails,
            showBillingCycleSwitcher: !data.billingCycle,
            remainingDays: this.daysUntilExpiry,
            isDomainPaid: this.isNonEmptyObject(data.domainPricingAttr)
              ? true
              : false,
          };
          this.canUpgradePlan = this.planList.some(
            (plan) => plan.id !== this.currentPlan.planId
          );
          this.$root.currentPlan = this.currentPlan;
          this.autoRenewWarningAck =
            smartStorage.getItem(
              `preserve__skipAutoRenewWarning-${this.domainName}`
            ) === this.currentPlan.expiresOn;

          if (currentPlanDetails.isTrial) {
            const paidPlan = this.planList.find(
              (p) => p.id === currentPlanDetails.paidPlanId
            );
            this.currentPlan.paidPlan = paidPlan;
          }
          smartStorage.emailAccountCount =
            data.noOfAccounts.limit || data.noOfAccounts.purchased;
          this.setPlanInfo(currentPlanDetails);

          if (cb) {
            cb(this.currentPlan);
          }
          this.setIsDiscountedPricingAndTrialInfoInPlans(
            currentPlanDetailsStorePayload
          );
        })
        .catch((err) => {
          console.log("FIXME handle error", err);
        });
    },
    informMedusaAboutBanner(action = "seen") {
      if (this.showDNSBanner) return;
      if (!this.visibleBannerType) return;
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          {
            eventName: `${this.visibleBannerType}_banner_${action}`,
            expiry_date: +new Date(this.expiresOn),
            current_plan: this.currentPlan?.planDetails?.displayName,
            addons: (this.currentPlan.addons || []).map((a) => {
              const addon = this.addons.find((ad) => ad.id === a.addonId) || {};
              return `ID - ${addon.id} Type - ${addon.type}`;
            }),
          },
          this.domainName,
          "flock_order"
        )
      );
    },
    fetchAddons() {
      return this.$http
        .get(`panel/billing/get-all-addons`)
        .then(
          (data) => {
            // success
            this.addons = data.addons || [];
            return this.addons;
          },
          (err) => {
            console.error("Could not fetch addons", err);
          }
        )
        .then(this.fetchAddonTrialConfigurations);
    },
    fetchCardDetails() {
      return this.$http
        .get(`panel/billing/get-card-details`)
        .then((data) => {
          this.savedCard = data;
          this.setCardType(data?.funding);
        })
        .catch((err) => {
          if (err.data?.code === "CARD_NOT_FOUND") {
            this.savedCard = null;
          }
          console.log("FIXME handle error", err);
        });
    },
    initiateGTM(trackerId) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", trackerId);
    },
    redirectToLogin() {
      clearStorage();
      this.authenticated = false;
      this.$router.push({ name: "Login" });
    },
    // Events on EventBus
    destroyFetchListeners() {
      eventBus.$off("go-to-login", this.redirectToLogin);
      eventBus.$off("fetchAddons", this.fetchAddons);
      eventBus.$off("fetchCurrentPlan", this.fetchCurrentPlan);
    },
    setupFetchListeners() {
      eventBus.$on("go-to-login", this.redirectToLogin);
      eventBus.$on("fetchAddons", this.fetchAddons);
      eventBus.$on("fetchCurrentPlan", this.fetchCurrentPlan);
    },
    fetchControlPanelSource() {
      // Fetch Source from which Control panel was loaded
      const availableSources = ["isFromWebmail", "isFromNeo"];
      const currentSource = availableSources.find(
        (sourceHook) => this.$route.query[sourceHook]
      );
      if (currentSource) {
        this[currentSource] = true;
      }
    },
  },
  mounted() {
    this.shouldShowResponsiveLayout = shouldShowResponsiveCP();

    if (isAppLoadedInsideIframe()) {
      observedElementDetails = this.observeChangesInDOM(
        document.getElementById("app")
      );
    }
    // this condition gets exce when user is on mobile (and not in iframe) and user is not logged-in
    // and based on showResponsiveLayout flag we show/hide mobile version on CP
    // ideally hideLoader gets assigned once the all API fetching is done
    // but this is special case due to neo (where we support mobile version of CP)
    // in titan we show error screen for mobile
    else if (!this.authenticated && this.isMobile) {
      this.hideLoader = true;
      measureInitialAppLoad();
    }
  },
  created() {
    if (isAppLoadedInsideIframe()) {
      window.addEventListener("message", this.onIframeDimensionChange);
    }
    this.setViewAndTheme();
    this.setupFetchListeners();
    this.fetchControlPanelSource();
    // eslint-disable-next-line no-undef
    if (process.env.VUE_APP_ENV_FLAG == "prod") {
      this.initiateGTM("GTM-M3SL88X");
    } else {
      this.initiateGTM("GTM-WNT45JL");
    }
    if (smartStorage.getItem("auth")) {
      this.authenticate();
    } else if (smartStorage.getItem("authToken")) {
      this.redirectToPath();
    } else {
      this.authenticated = false;
    }
    eventBus.$on("refetch-plans", this.fetchPlans);
    eventBus.$on("refetch-email-accounts", this.fetchEmailAccounts);
    eventBus.$on(
      "handleEntriSetupRedirection",
      this.handleEntriSetupRedirection
    );
  },
  beforeDestroy() {
    eventBus.$off("refetch-plans", this.fetchPlans);
    eventBus.$off("refetch-email-accounts", this.fetchEmailAccounts);
    eventBus.$off(
      "handleEntriSetupRedirection",
      this.handleEntriSetupRedirection
    );
    this.destroyFetchListeners();
    window.removeEventListener("message", this.onIframeDimensionChange);
    if (observedElementDetails.observer)
      observedElementDetails.observer.disconnect();
  },
  watch: {
    /**
     * Some partners need to know if the number of active/purchased/limit (available) mailboxes on this domain.
     * This is a way to notify them.
     * @param {EmailAccounts} emailAccounts - Consists of the `active`, `limit` and `purchased` mailboxes.
     */
    "currentPlan.noOfAccounts": function (emailAccounts, oldEmailAccounts) {
      if (
        emailAccounts.active !== oldEmailAccounts?.active ||
        emailAccounts.purchased !== oldEmailAccounts?.purchased ||
        emailAccounts.limit !== oldEmailAccounts?.limit
      ) {
        sendPostMessageToParentWindow(EMAIL_ACCOUNTS_CHANGED, emailAccounts);
      }
    },
    domainConnectData() {
      if (!isSiteVerifiedAndConnected() || !isDomainVerifiedAndConnected()) {
        this.fetchEntriDetailsIfMissing();
      }
    },
  },
};
</script>

<style lang="scss">
html {
  background: var(--primaryBgCol) no-repeat;
}
div,
p,
span,
th,
tr,
input,
li,
p,
h1,
h2,
h3,
h4 {
  color: var(--primaryTextCol);
}
input {
  background: var(--tabBgCol);
  color: var(--primaryTextCol);
}
#app {
  font-family: var(--app-font-type);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background: var(--primaryBgCol);
}
.link {
  color: var(--linkBtnCol);
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: var(--linkBtnCol);
    text-decoration: underline;
  }
}

.modal-open {
  overflow: hidden;
}
.warning-validation,
.error-validation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 68px;
  left: 0;
  background: var(--warningBgCol);
  padding: 7px;
  color: var(--genericTextCol);
  z-index: 3;
  font-size: 14px;

  &.level2 {
    top: 118px;
  }

  i {
    display: inline-block;
    width: 18px;
    height: 15px;
    background: url("./assets/warning-icon.svg") center no-repeat;
    background-size: contain;
    vertical-align: -2px;
    margin-right: 10px;
  }

  .emoji {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    display: inline-block;
    background: url("./assets/celebration.svg") center no-repeat;
    transform: scale(1.2); // necessary else icons looks very small
    vertical-align: text-bottom;
  }

  span {
    font-weight: 600;
    color: var(--genericTextCol);
    & + div {
      display: inline-block;
    }
  }

  .button {
    padding: 7px 18px;
  }

  .button:hover {
    text-decoration: none;
  }
}
.error-validation {
  border: solid 1px var(--errorSecBordCol);
  background-color: var(--errorSecTextCol);
  i {
    background: url("./assets/warning-icon-red.svg") center no-repeat;
  }

  &.secondary {
    border-color: var(--secondaryErrorBannerBorder);
    background-color: var(--secondaryErrorBannerBg);

    i {
      width: 16px;
      height: 16px;
      background: url("./assets/blue-info-icon.svg") center no-repeat;
      margin-right: 8px;
    }
  }
}
.banner-for-widget div {
  position: static;
}
.increase-padding-container {
  padding-top: 105px !important;

  &.level2 {
    padding-top: 150px !important;
  }
}

.active-now {
  margin-left: 10px !important;
}

.button {
  color: var(--primaryBtnTextCol);
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  background-color: var(--primaryBtnCol);
  padding: 10px 18px;
  border: 0;
  line-height: 150%;
  cursor: pointer;

  &.invalid {
    opacity: 0.5;
    cursor: default;
  }
  &.show-spinner {
    @include button-loader();
  }
  &.show-disabled-spinner {
    @include disabled-button-loader();
  }

  &:focus {
    outline: 0;
  }

  &.btn-danger,
  &.btn-danger:hover,
  &.btn-danger:focus,
  &.btn-danger:active {
    background-color: var(--errorPrimaryTextCol) !important;
    box-shadow: none !important;
    border: none !important;
  }

  &.button__gray {
    background: var(--lineSepaCol);
    border: 1px solid var(--lineSepaCol);
    color: var(--primaryTextCol);
    font-weight: normal;
    &:hover {
      background: var(--lineSepaCol);
    }
  }

  &.button__line-blue {
    background: transparent;
    border: 1px solid var(--transparent-btn);
    color: var(--transparent-btn);

    &:hover {
      background: transparent;
    }
  }

  &.button__transparent {
    background: transparent;
    border: 0;
    padding: 10px 8px;
  }

  &:nth-child(n + 2) {
    margin-left: 16px;
  }

  &:first-of-type {
    margin: 0;
  }
}
.widgetView {
  margin: 30px 0;
  width: 100%;
}
.widgetViewContainer {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .help-link-icon {
    width: 66px;
    height: 23px;
    background: url("./assets/help-grey.svg");
  }
  .rightAlignIcon {
    position: absolute;
    right: 0;
  }
}
.powered-by-titan {
  text-align: center;
  margin-top: 30px;
}
.view {
  margin-top: 50px;
  padding: 50px;
  margin-left: 280px;
  background: var(--primaryBgCol);
  min-height: calc(100vh - 68px);
  width: calc(100% - 280px);
}

.card-container {
  border-radius: 5px;
  background-color: var(--tabBgCol);
  padding: 24px;
  border: 1px solid var(--lineSepaCol);
  margin-top: 30px;
  header {
    font-size: 16px;
  }
}

h4.view-heading {
  font-size: 20px;
  font-weight: 600;
}

.card-heading {
  font-size: 16px;
  font-weight: 600;
}

.card-subheading {
  color: var(--secondaryTextCol);
}

/*commons*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}

.input-wrapper {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.input-addon-wrapper {
  position: relative;

  .password-hidden,
  .password-shown {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .input {
    padding: 9px 55% 9px 15px;
  }
}

.color-grey {
  color: var(--textBtnCol);
}

.color-blue {
  color: var(--primaryBtnCol);
}

.addon {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--readOnlyTxtBgCol);
  display: inline-block;
  padding: 9px;
  border-radius: 0 4px 4px 0;
  border-left: 1px solid var(--disableCol);
  line-height: normal;
  width: 50%;
  height: calc(100% - 3px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--primaryTextCol);
}

.input {
  border-radius: 4px;
  background-color: var(--tabBgCol);
  border: 1px solid var(--inputBorderCol);
  padding: 9px 15px;
  line-height: 20px;
  width: 100%;

  &[readonly="readonly"] {
    color: var(--secondaryTextCol);
    background-color: var(--readOnlyTxtBgCol);
  }

  &.has-error {
    border: 1px solid var(--errorPrimaryTextCol);
  }

  &:focus {
    border: 1px solid var(--inputFocusBorderCol);
    outline: 0;
  }
}

.error {
  color: var(--errorPrimaryTextCol);
  font-size: 12px;
  letter-spacing: normal;
}

.input.password.password-hidden {
  background: url("/assets/password-toggle-on.svg") center right no-repeat /
    contain;
}

.input.password.password-shown {
  background: url("/assets/password-toggle-off.svg");
}

.input::placeholder {
  color: var(--inputBorderCol);
}

p {
  line-height: 18px;
  color: var(--primaryTextCol);
  font-size: 14px;
}

div.toasted-container {
  z-index: 10;
  .toasted.toasted-primary {
    min-width: 300px;
    border-radius: 4px;
    background: var(--primaryTextCol);
    color: var(--tabBgCol);
    padding: 19px 24px;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.33;

    .ripple.action {
      text-decoration: none;
      color: var(--tabBgCol);
      font-size: 20px;
      padding: 0;
      margin: 0 0 0 20px;
      font-weight: normal;

      &:hover {
        color: var(--tabBgCol);
      }
    }
  }
}

.v-context.overflow-context-menu {
  background-color: var(--tabBgCol) !important;
  box-shadow: 0 0 8px -1px var(--boxShadowCol7) !important;
  width: 215px !important;
  right: -20px !important;
  bottom: -27px !important;
  position: absolute !important;
  ul {
    li {
      padding: 8px 24px !important;
      font-size: 14px;
      font-weight: 400;
      color: var(--primaryTextCol);
      text-align: left;

      &:hover {
        background: var(--readOnlyTxtBgCol) !important;
        color: var(--primaryTextCol) !important;
      }

      &:last-of-type,
      &:first-of-type {
        margin: 0 !important;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.without-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
.without-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar-thumb {
  border-radius: 30px;
  -webkit-box-shadow: inset 0 0 6px var(--boxShadowCol1);
}

.vue-tooltip {
  background: var(--primaryTextCol) !important;
  font-size: 12px !important;

  .tooltip-arrow {
    border-top-color: var(--primaryTextCol) !important;
  }
}

.search-container {
  text-align: right;

  .search-wrapper {
    position: relative;
    margin-right: 16px;

    .input {
      max-width: 290px;
      background: url("./assets/search-small.svg") no-repeat no-repeat 10px
        center/16px;
      padding-left: 30px;
    }

    .clear-search {
      width: 15px;
      height: 18px;
      background: url("./assets/cross.svg") center no-repeat / contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

/*Tags input CSS*/
.vue-tags-input {
  .ti-input {
    border-radius: 4px;
    background-color: var(--tabBgCol);
    border: 1px solid var(--inputBorderCol) !important;
    padding: 8px 15px !important;
    line-height: 20px;
    width: var(--inputBoxWidth);
    max-height: 250px;
    overflow: auto;

    &:focus {
      border: 1px solid var(--inputFocusBorderCol) !important;
      outline: 0 !important;
    }

    .ti-tag {
      user-select: none;
      margin: 4px !important;

      &:first-of-type {
        margin-left: 0 !important;
      }

      &:last-of-type {
        margin-right: 8px !important;
      }

      &.input-tag {
        background: var(--tabBgCol);
        color: var(--primaryTextCol);
        border: 1px solid var(--lineSepaCol);
        border-radius: 11px;
        padding: 4px 8px;
        font-weight: 500;
        font-size: 14px;
        /*margin-bottom: 3px !important;*/
        /*margin-top: 3px !important;*/
        margin: 3px 8px 3px 0 !important;
        word-break: break-all;

        &.ti-deletion-mark {
          background: var(--readOnlyTxtBgCol) !important;
        }
      }

      .ti-actions {
        margin-left: 8px !important;
        font-size: 12px !important;
        color: var(--secondaryTextCol) !important;
      }
    }

    .ti-new-tag-input-wrapper {
      padding: 0 !important;
      margin: 0 !important;
      background: var(--tabBgCol);

      input {
        font-size: 14px;
        line-height: 22px;
        caret-color: var(--primaryBtnCol);
        margin: 3px;
      }
    }
  }

  .ti-autocomplete {
    border-radius: 4px;
    box-shadow: 0 0 2px 2px var(--boxShadowCol2);
    background-color: var(--tabBgCol);
    border: 0 !important;
    margin-bottom: 30px;
    color: var(--primaryTextCol) !important;
    max-height: 200px;
    overflow: auto;

    .ti-item {
      padding: 9px 15px !important;
      background-color: var(--tabBgCol) !important;
      &.ti-selected-item {
        color: var(--primaryTextCol) !important;
      }

      & > div {
        padding: 0 !important;
      }
      &:hover {
        background-color: var(--dropdownItemBgCol) !important;
        div {
          color: var(--genericTextCol) !important;
        }
      }
    }

    .no-forwarder-email {
      padding: 12px 14px;
      background: var(--readOnlyTxtBgCol);

      p {
        margin: 0;
      }

      small {
        font-size: 12px;
      }
    }
  }

  &.ti-focus {
    .ti-input {
      border: 1px solid var(--inputFocusBorderCol) !important;
    }
  }
}
.main {
  display: flex;
}

.non-iframe-widget {
  padding: 40px;
}

.modal-overlay.iframe-mode {
  background: unset;
  padding-left: 0;
  position: unset;
  text-align: left;
  justify-content: flex-start;
  .modal-body-container {
    background: unset;
    text-align: left;
    padding-left: 0;
    box-shadow: unset;
    background: unset;
  }
}
.btn:hover {
  color: unset;
}

.no-scroll {
  overflow: hidden;
}

.font-w-500 {
  font-weight: 500;
}

@import "styles/variables";
@media screen and (max-width: $responsive-breakpoint) {
  .main {
    justify-content: center;
    display: block;
  }

  .view {
    width: 100%;
    padding: 0 16px;
    margin: 0;
  }
}
</style>
