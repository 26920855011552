import Vue from "vue";
import Vuex from "vuex";
import modalModule from "./modules/modal.store.js";
import { getTime } from "@/helpers/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    modal: modalModule,
  },
  /* Global State */
  state: () => ({
    /** Configuration for the payment */
    externalPayment: null,
    /** Hide Billing if Payment is managed via partner. */
    hideBilling: null,
    theme: "defaultTheme",
    /** All products for which trials are enabled. */
    trials: {},
    /** Configuration for default vs iframe(widget) views */
    view: {},
    locale: "en",
    partnerId: null,
    domainID: null,
    planId: null,
    partnerName: null,
    flags: {},
    domainName: "",
    domainConnectData: {},
    brandDetails: {},
    currentPlanDetails: {},
    currentDomainDetails: {},
    currentDomainMailboxDetails: {},
    purchaseAndBillingDetails: {},
    domainList: [],
    showDomainAdminPopup: false,
    dkimUnverifiedKey: {},
    dkimVerifiedKeys: [],
    partnerInfo: {},
    paymentCardType: null,
    isDomainPaid: false,
    entriSetupPossible: false,
    entriDnsProvider: null,
    showAutoRenew: false,
    lastUpdated: "11/12/2024",
  }),
  /* Global Getters */
  getters: {
    externalPayment: (state) => state.externalPayment,
    hideBilling: (state) => state.hideBilling,
    theme: (state) => state.theme,
    trials: (state) => state.trials,
    view: (state) => state.view,
    locale: (state) => state.locale,
    paymentRoutingInfo({ externalPayment, hideBilling, trials, view }) {
      return {
        externalPayment,
        hideBilling,
        trials,
        view,
      };
    },
    partnerId: (state) => state.partnerId,
    domainID: (state) => state.domainID,
    planId: (state) => state.planId,
    partnerName: (state) => state.partnerName,
    // For handling Iframe interaction for different partners
    flags: (state) => state.flags,
    domainName: (state) => state.domainName,
    domainConnectData: (state) => state.domainConnectData,
    brandDetails: (state) => state.brandDetails,
    currentPlanDetails: (state) => state.currentPlanDetails,
    currentDomainDetails: (state) => state.currentDomainDetails,
    currentDomainMailboxDetails: (state) => state.currentDomainMailboxDetails,
    purchaseAndBillingDetails: (state) => state.purchaseAndBillingDetails,
    domainList: (state) => state.domainList,
    showDomainAdminPopup: (state) => state.showDomainAdminPopup,
    dkimUnverifiedKey: (state) => state.dkimUnverifiedKey,
    dkimVerifiedKeys: (state) => state.dkimVerifiedKeys,
    partnerInfo: (state) => state.partnerInfo,
    paymentCardType: (state) => state.paymentCardType,
    isDomainPaid: (state) => state.isDomainPaid,
    entriSetupPossible: (state) => state.entriSetupPossible,
    entriDnsProvider: (state) => state.entriDnsProvider,
    showAutoRenew: (state) => state.showAutoRenew,
  },
  /**
   * Global Actions:
   * Why are there so many actions for each property?
   * Each action can dispatch more actions, or just commit a mutation.
   * We could directly commit these into the store from the component too.
   * However, for cases where some value is dependent on another value, it makes sense to dispatch.
   */
  actions: {
    externalPayment({ commit }, payload) {
      commit("externalPayment", payload);
    },
    hideBilling({ commit }, payload) {
      commit("hideBilling", payload);
    },
    theme({ commit }, payload) {
      commit("theme", payload);
    },
    trials({ commit }, payload) {
      commit("trials", payload);
    },
    view({ commit }, payload) {
      commit("view", payload);
    },
    locale({ commit }, payload) {
      commit("locale", payload);
    },
    partnerId({ commit }, payload) {
      commit("partnerId", payload);
    },
    domainID({ commit }, payload) {
      commit("domainID", payload);
    },
    planId({ commit }, payload) {
      commit("planId", payload);
    },
    partnerName({ commit }, payload) {
      commit("partnerName", payload);
    },
    flags({ commit }, payload) {
      commit("flags", payload);
    },
    domainName({ commit }, payload) {
      commit("domainName", payload);
    },
    domainConnectData({ commit }, payload) {
      commit("domainConnectData", {
        ...(payload || {}),
        lastUpdated: getTime(),
      });
    },
    brandDetails({ commit }, payload) {
      commit("brandDetails", payload);
    },
    currentPlanDetails({ commit }, payload) {
      commit("currentPlanDetails", payload);
    },
    currentDomainDetails({ commit }, payload) {
      commit("currentDomainDetails", payload);
    },
    currentDomainMailboxDetails({ commit }, payload) {
      commit("currentDomainMailboxDetails", payload);
    },
    purchaseAndBillingDetails({ commit }, payload) {
      commit("purchaseAndBillingDetails", payload);
    },
    domainList({ commit }, payload) {
      commit("domainList", payload);
    },
    showDomainAdminPopup({ commit }, payload) {
      commit("showDomainAdminPopup", payload);
    },
    // action to handle unverified dkim key
    dkimUnverifiedKey({ commit }, payload) {
      commit("dkimUnverifiedKey", payload);
    },
    // action to handle verified dkim keys
    dkimVerifiedKeys({ commit }, payload) {
      commit(
        "dkimVerifiedKeys",
        // as we want to keep enabled key in top
        Array.isArray(payload)
          ? payload.sort((a, b) => (a.isEnabled && !b.isEnabled ? -1 : 0))
          : payload
      );
    },
    // action to add unverified key to verified array
    dkimAddToVerifiedKeyList({ commit, state }) {
      commit("dkimVerifiedKeys", [
        { ...state.dkimUnverifiedKey, isEnabled: true },
        ...state.dkimVerifiedKeys.map((d) => ({ ...d, isEnabled: false })),
      ]);
      commit("dkimUnverifiedKey", null);
    },
    // action to enable provided key
    // we also mark other keys as isEnable: false
    enableDkimKey({ dispatch, state }, payload) {
      dispatch(
        "dkimVerifiedKeys",
        state.dkimVerifiedKeys.map((k) => ({
          ...k,
          isEnabled: k.dkimId === payload,
        }))
      );
    },
    // action to delete dkim key from store
    deleteDkimKey({ dispatch, state }, payload) {
      dispatch(
        "dkimVerifiedKeys",
        state.dkimVerifiedKeys.filter((k) => k.dkimId !== payload)
      );
    },
    partnerInfo({ commit }, payload) {
      commit("partnerInfo", payload);
    },
    paymentCardType({ commit }, payload) {
      commit("paymentCardType", payload);
    },
    isDomainPaid({ commit }, payload) {
      commit("isDomainPaid", payload);
    },
    entriSetupPossible({ commit }, payload) {
      commit("entriSetupPossible", payload);
    },
    entriDnsProvider({ commit }, payload) {
      commit("entriDnsProvider", payload);
    },
    showAutoRenew({ commit }, payload) {
      commit("showAutoRenew", payload);
    },
  },
  /* Global Mutations */
  mutations: {
    externalPayment(state, payload) {
      state.externalPayment = payload;
    },
    hideBilling(state, payload) {
      state.hideBilling = payload;
    },
    theme(state, payload) {
      if (payload) {
        state.theme = payload;
      }
    },
    trials(state, payload) {
      state.trials = {
        ...state.trials,
        ...payload,
      };
    },
    locale(state, payload) {
      state.locale = payload;
    },
    view(state, payload) {
      state.view = payload;
    },
    partnerId(state, payload) {
      state.partnerId = payload;
    },
    domainID(state, payload) {
      state.domainID = payload;
    },
    planId(state, payload) {
      state.planId = payload;
    },
    partnerName(state, payload) {
      state.partnerName = payload;
    },
    flags(state, payload) {
      state.flags[payload.flag] = payload.value;
    },
    domainName(state, payload) {
      state.domainName = payload;
    },
    domainConnectData(state, payload) {
      state.domainConnectData = payload;
    },
    brandDetails(state, payload) {
      state.brandDetails = payload;
    },
    currentPlanDetails(state, payload) {
      state.currentPlanDetails = payload;
    },
    currentDomainDetails(state, payload) {
      state.currentDomainDetails = payload;
    },
    currentDomainMailboxDetails(state, payload) {
      state.currentDomainMailboxDetails = payload;
    },
    purchaseAndBillingDetails(state, payload) {
      state.purchaseAndBillingDetails = payload;
    },
    domainList(state, payload) {
      state.domainList = payload;
    },
    showDomainAdminPopup(state, payload) {
      state.showDomainAdminPopup = payload;
    },
    dkimUnverifiedKey(state, payload) {
      state.dkimUnverifiedKey = payload;
    },
    dkimVerifiedKeys(state, payload) {
      state.dkimVerifiedKeys = payload;
    },
    partnerInfo(state, payload) {
      state.partnerInfo = payload;
    },
    paymentCardType(state, payload) {
      state.paymentCardType = payload;
    },
    isDomainPaid(state, payload) {
      state.isDomainPaid = payload;
    },
    entriSetupPossible(state, payload) {
      state.entriSetupPossible = payload;
    },
    entriDnsProvider(state, payload) {
      state.entriDnsProvider = payload;
    },
    showAutoRenew(state, payload) {
      state.showAutoRenew = payload;
    },
  },
});
