import { PLAN_TYPES } from "@/const/neo.constant";
import { BILLING_FREQUENCY, BILLING_MONTHS } from "./const";

/* lite => lite
    pro => starter
    premium => standard
    ultra => max
*/
export const getNeoPlanType = (planType) => {
  if (planType.startsWith("pro")) return PLAN_TYPES.STARTER;
};

// In lite plan feature we have introduced a new requirement where monthly billing cycle should only be shown for starter plan only if current billing cycle is monthly otherwise it should be hidden.
export const getSupportedBillingCyclesForNeo = (
  billingCycles,
  planType,
  currentBillingCycle
) => {
  if (!billingCycles) {
    return [];
  }
  if (!planType || getNeoPlanType(planType) !== PLAN_TYPES.STARTER) {
    return billingCycles;
  }

  return billingCycles.filter((billingCycle) => {
    if (billingCycle === BILLING_FREQUENCY.MONTHLY) {
      return currentBillingCycle === BILLING_FREQUENCY.MONTHLY;
    }

    return true;
  });
};

export const getFreeDomainPeriodForNeo = (billingCycle) => {
  const freeDomainPeriods = [
    BILLING_FREQUENCY.YEARLY,
    BILLING_FREQUENCY.QUARTERLY,
    BILLING_FREQUENCY.MONTHLY,
  ];
  return (
    freeDomainPeriods.find(
      (period) => BILLING_MONTHS[billingCycle] >= BILLING_MONTHS[period]
    ) || BILLING_FREQUENCY.YEARLY
  );
};
