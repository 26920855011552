import { EMAIL_VALIDITY_ERROR_CODES } from "./const";
import { getDomainFromEmail } from "./domain";
import { http } from "./http";
import {
  LAMBDA_ENTITIES,
  LAMBDA_FUNCTION_NAMES,
  getLambdaUrl,
} from "./lambdaHelpers";

export const getUserNameFromEmail = (email) => {
  return email.split("@")?.[0];
};

async function checkEmailAvailability(payload) {
  const lambdaUrl = getLambdaUrl(
    LAMBDA_ENTITIES.NEO,
    LAMBDA_FUNCTION_NAMES.CHECK_EMAIL_AVAILABILITY
  );
  return new Promise((resolve, reject) => {
    new http().postData(lambdaUrl, { payload }, {}, resolve, reject);
  });
}

export const emailAvailabilityStatus = async (emailAddress) => {
  const email = emailAddress.toLowerCase();
  const username = getUserNameFromEmail(email);
  let res;
  try {
    res = await checkEmailAvailability(
      { emails: [email], domainName: getDomainFromEmail(email) },
      {}
    );
  } catch (e) {
    console.error(e);
  }

  if (!res) {
    return {
      isAvailable: false,
      errorCode: EMAIL_VALIDITY_ERROR_CODES.NETWORK_ERROR,
    };
  }

  const { availabilityStatus: { [email]: { isAvailable, reason } } = {} } = res;
  if (isAvailable) return { isAvailable, errorCode: "" };
  if (EMAIL_VALIDITY_ERROR_CODES[reason]) {
    return {
      isAvailable,
      errorCode: EMAIL_VALIDITY_ERROR_CODES[reason],
      values: { username },
    };
  }
  return {
    isAvailable,
    errorCode: EMAIL_VALIDITY_ERROR_CODES.DEFAULT,
    values: { username },
  };
};
