<script>
import eventBus from "@/helpers/event-bus";
export default {
  name: "DeleteAccount",
  props: {
    isCatchAllEmail: Boolean,
    user: Object,
    onRemove: Function,
    view: Object,
    isLastAdminAccount: Boolean,
  },
  data() {
    return {
      isSent: false,
      showPrevWarn: !!(this.user.addons && this.user.addons.length),
    };
  },
  computed: {
    deleteType() {
      if (this.isCatchAllEmail && this.user.isAdmin) {
        return "deleteAdminAndCatchAllAccount";
      } else if (this.isCatchAllEmail) {
        return "deleteCatchAllAccount";
      } else if (this.user.isAdmin && this.isLastAdminAccount) {
        return "deleteLastAdmin";
      } else if (this.user.isAdmin) {
        return "deleteAdmin";
      } else {
        return "deleteAccount";
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    deleteAccount() {
      if (this.user.isAdmin && this.isLastAdminAccount) {
        this.close();
        return;
      }
      if (!this.isSent) {
        this.isSent = true;
        return this.http.postData(
          "panel/account/email/delete",
          {
            email: this.user.email,
            domainName: smartStorage.getItem("domain"),
          },
          this.$root.headers,
          this.onSuccess,
          this.onError
        );
      }
    },
    onSuccess(data) {
      if (data) {
        eventBus.$emit("fetchCurrentPlan");
        this.close();
        this.onRemove(this.user);
        const message = this.user.isAdmin
          ? this.$t("toast.adminAccountDeleted", [this.user.email])
          : this.user.email + " " + this.$t("toast.isDeleted");
        this.showToast(message, {
          type: "success",
          modalName: "delete-account",
        });
        this.isSent = false;
        this.$parent.getEmailList();
        var eventObj = {
          eventName: "mail_account_deleted",
          type: "pop",
        };
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            eventObj,
            this.user.email,
            "flock_account"
          )
        );
      }
    },
    onError(response) {
      if (response && response.data && response.data.desc) {
        this.close();
        this.showToast(response.data.desc, {
          type: "error",
          modalName: "delete-account",
        });
        this.isSent = false;
      }
    },
    skipDeleteWarn() {
      if (this.deleteType === "deleteAccount") {
        this.deleteAccount(); // direct delete
      } else {
        this.showPrevWarn = false;
      }
    },
  },
  mounted() {
    if (this.$refs.button) {
      this.$refs.button.focus();
    }
  },
};
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        :class="[
          'modal-body-container',
          {
            modalPage: view.showModalAsPage,
          },
        ]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <button
          type="button"
          class="btn-close"
          @click="close"
          v-if="!view.showModalAsPage"
          aria-label="Close modal"
        >
          &times;
        </button>
        <widget-breadcrumb-nav
          :isModal="true"
          modalName="delete-account"
          v-if="view.showModalAsPage"
          @onNavClick="close"
        />
        <div class="modal-content-wrapper" v-if="showPrevWarn">
          <div>
            <h4>
              {{
                $t(`emailAccounts.deleteAcAndAddon.heading`, [
                  user.addonHeadings[0],
                ])
              }}
            </h4>
            <p class="email">{{ user.email }}</p>
            <p class="color-grey">
              {{
                $t(`emailAccounts.deleteAcAndAddon.deleteAccountQuestion`, [
                  user.addonHeadings[0],
                ])
              }}
            </p>
            <div class="input-wrapper">
              <button ref="button" class="button" @click="skipDeleteWarn">
                {{ $t(`emailAccounts.deleteAcAndAddon.confirmBtn`) }}
              </button>
              <button class="button button__line-blue" @click="close">
                {{ $t(`emailAccounts.deleteAcAndAddon.cancelBtn`) }}
              </button>
            </div>
          </div>
        </div>
        <div class="modal-content-wrapper" v-if="!showPrevWarn">
          <div>
            <h4>{{ $t(`emailAccounts.${deleteType}.heading`) }}</h4>
            <p class="email">{{ user.email }}</p>
            <p class="color-grey">
              {{ $t(`emailAccounts.${deleteType}.deleteAccountQuestion`) }}
            </p>
            <div class="input-wrapper">
              <button
                ref="button"
                class="button confirmBtn"
                @click="deleteAccount"
              >
                {{ $t(`emailAccounts.${deleteType}.confirmBtn`) }}
              </button>
              <button
                class="button button__line-blue cancelBtn"
                @click="close"
                v-if="!isLastAdminAccount"
              >
                {{ $t(`emailAccounts.${deleteType}.cancelBtn`) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.confirmBtn {
  border: 1px solid var(--secondaryBtnCol);
  background: var(--secondaryBtnCol);
}
.cancelBtn {
  border: 1px solid var(--secondaryBtnCol);
  color: var(--secondaryBtnCol);
  background-color: var(--tabBgCol);
}
</style>
