import Vue from "vue";

import $http, { http } from "../helpers/http";
import * as helpers from "../helpers";
import smartStorage from "../helpers/smart-storage";
import { MEDUSA_EVENTS_MAP } from "../helpers/events";
import queryParamStore from "./queryParamStore";

export const setGlobalFlags = () => {
  const {
    urlParser,
    medusa,
    setSafeValues,
    validation,
    getPartnerInfo,
    runDnsLookup,
  } = helpers;
  const urlParameters = urlParser();
  const {
    action,
    auth,
    domain,
    email,
    freeEmailCreation,
    section,
    source,
    sourceClient,
    actionProps,
  } = urlParameters;
  // auth - token (slt)
  // domain - domain
  // section - route to redirect
  // action - action to perform on page/route
  // freeEmailCreation - to show create email modal

  if (section) {
    helpers.clearStorage();
  }

  if (auth) {
    helpers.clearStorage();
    smartStorage.auth = auth;
  }
  if (domain) smartStorage.domain = domain;
  if (section) smartStorage.section = section;
  if (action) smartStorage.action = action;
  if (freeEmailCreation) smartStorage.launchEmailCreation = freeEmailCreation;
  if (email) smartStorage.launchEmail = email;
  if (actionProps) smartStorage.actionProps = actionProps;

  if (source) {
    sessionStorage.setItem("source", source);
  }

  if (sourceClient) {
    queryParamStore.setAndClear("sourceClient", sourceClient);
  }

  // The original axios, the way it was meant to be used.
  Vue.prototype.$http = $http;
  Vue.prototype.http = new http();
  Vue.prototype.medusaEvents = medusa;
  Vue.prototype.validation = validation;
  Vue.prototype.setSafeValues = setSafeValues;
  Vue.prototype.getPartnerInfo = getPartnerInfo;
  Vue.prototype.smartStorage = smartStorage;
  Vue.prototype.runDnsLookup = runDnsLookup;
  Vue.config.productionTip = false;

  window.smartStorage = smartStorage;
  window.MEDUSA_EVENTS_MAP = MEDUSA_EVENTS_MAP;
  window.buildVersion = process.env.VUE_APP_BUILD_VERSION;
};
