// const DEFAULT_TRIAL_DURATION_IN_DAYS = 15;

import { PLAN_FEATURE_MAP } from "@/helpers/const";
import { isNeoBrand, isNeoBusinessDomain } from "@/helpers/index";
import * as helper from "@/helpers/index";
import store from "@/store";

const {
  READ_RECEIPTS,
  CONTACT_GROUPS,
  EMAIL_TEMPLATES,
  TITAN_AI,
  MULTIPLE_EXTERNAL_FORWARDER,
  STORAGE,
  NEO_DOMAIN,
  CUSTOM_DOMAIN,
  NEO_SITE,
  MAIL_SEND,
  APPOINTMENT_BOOKING,
} = PLAN_FEATURE_MAP;

/**
 * Converts a given string into camelCase format. This function can handle strings
 * with spaces, hyphens, and underscores as separators.
 *
 * @param {string} planName - The string to be converted to camelCase.
 * @returns {string} The camelCase version of the input string.
 *
 * @example
 * planNameToCamelCase('hello_world');
 * planNameToCamelCase('hello world');
 * planNameToCamelCase('hello-world');
 * // returns 'helloWorld'
 *
 */
export const planNameToCamelCase = (planName) => {
  return planName
    .split(/[-_\s]+/)
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
};

const _sanitizeNeoFeatures = (planList) => {
  if (!isNeoBrand()) return;

  // remove custom domain feature for neo business domain, and neo domain feature for neo custom domains
  const featureToRemove = isNeoBusinessDomain() ? CUSTOM_DOMAIN : NEO_DOMAIN;
  planList.forEach((plan) => {
    plan.features = plan.features.filter(
      (feature) => feature.type !== featureToRemove
    );
  });
};

const formatFeatureValue = (featureKey, attrs) => {
  switch (featureKey) {
    case READ_RECEIPTS:
    case CONTACT_GROUPS:
    case EMAIL_TEMPLATES:
    case TITAN_AI:
    case MULTIPLE_EXTERNAL_FORWARDER:
      if (attrs.limit) {
        return { tx_key: `${featureKey}.val_tx`, val: [attrs.limit] };
      }
      break;

    case STORAGE:
      if (attrs.quota_in_mb) {
        return {
          tx_key: `${featureKey}.val_tx`,
          val: [helper.toGB(attrs.quota_in_mb)],
        };
      }
      break;

    case CUSTOM_DOMAIN:
      if (attrs) {
        return {
          tx_key: `${featureKey}.val_tx`,
          val: { noOfDays: attrs.trialInDays },
        };
      }
      break;

    case NEO_SITE:
      if (attrs) {
        return {
          tx_key: `${featureKey}.val_tx`,
        };
      }
      break;

    case MAIL_SEND:
      if (attrs) {
        return {
          tx_key: `${featureKey}.val_tx`,
          val: { hourlyLimit: attrs.hourlyLimit, dailyLimit: attrs.dailyLimit },
        };
      }
      break;

    default:
      return null;
  }
};

const _isPaidCounterpartOfCurrentPlan = (plan, currentPlan) => {
  return currentPlan.paidPlanId === plan.id;
};

export const addPlanTrialInfo = (allPlans, currentPlan) => {
  return allPlans.map((plan) => {
    if (_isPaidCounterpartOfCurrentPlan(plan, currentPlan)) {
      plan.showTrialnfo = true;
    }
    return plan;
  });
};

export const getUserVisiblePlans = (allPlans, currentPlan) => {
  return allPlans.filter((plan) => {
    if (plan.id === currentPlan.planId)
      // Show current plan in list only if it is not under trial
      return !currentPlan.isTrial;
    return true;
  });
};

const getDefaultFeatureName = (featureKey) => {
  // Make first letter uppercase for each word in featureKey
  return featureKey
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getPlanFeaturesComparison = (plansToShow) => {
  _sanitizeNeoFeatures(plansToShow);

  plansToShow.sort((plan1, plan2) => plan1.zIndex - plan2.zIndex);

  const featureGroupsMap = new Map();

  // Process each plan and its features
  plansToShow.forEach((plan, index) => {
    plan.features.forEach((feature) => {
      const featureGroup = feature.featureGroup || "";
      const displayOrder = feature.displayOrder;

      if (!featureGroupsMap.has(featureGroup)) {
        featureGroupsMap.set(featureGroup, []);
      }

      let featureList = featureGroupsMap.get(featureGroup);
      let featureItem = featureList.find((f) => f.featureKey === feature.type);

      if (!featureItem) {
        featureItem = { featureKey: feature.type, plans: [], displayOrder };
        featureList.push(featureItem);
      }

      // Use formatFeatureValue to determine the value to set
      const formattedValue = formatFeatureValue(feature.type, feature.attrs);
      featureItem.plans[index] =
        formattedValue || featureItem.plans[index] || true;

      // Set display name for feature, if translation is not available
      featureItem.displayName = getDefaultFeatureName(feature.type);

      // If the plan is not ultra, disable appointment booking, as it is only available in ultra plan
      if (
        isNeoBrand() &&
        !plan.type.includes("ultra") &&
        feature.type === APPOINTMENT_BOOKING
      ) {
        featureItem.plans[index] = false;
      }
    });

    plan.features.sort((a, b) => a.displayOrder - b.displayOrder);
  });

  // Sort features within each group by display order
  featureGroupsMap.forEach((features) => {
    features.sort((a, b) => a.displayOrder - b.displayOrder);
  });

  // Convert Map to Array, include min display order for sorting groups
  const featureGroupsArray = Array.from(
    featureGroupsMap,
    ([featureGroupName, features]) => ({
      featureGroupName,
      features,
      minDisplayOrder: Math.min(...features.map((f) => f.displayOrder)),
    })
  );

  // Sort groups based on min display order
  featureGroupsArray.sort((a, b) => a.minDisplayOrder - b.minDisplayOrder);

  const groupedFeatures = featureGroupsArray.map((group) => ({
    categoryName: group.featureGroupName,
    features: group.features,
  }));

  return {
    groupedFeatures,
  };
};

export const isFreePlan = () => {
  const currentPlan = store.getters.currentPlanDetails;
  return !currentPlan?.isTrial && !currentPlan?.isPaid;
};
