/**
 * This Vue Mixin contains all the billing related methods.
 */
import { dateDiff, dayDiff, toGB, isSizeInGB } from "@/helpers";
import addon from "./addon";

const featureOrder = {
  storage: 1,
  mobile_apps: 2,
  web_mail: 3,
  calendar: 4,
  read_receipts: 5,
  video_calling: 6,
};

export default {
  created() {
    if (!this.plans || !this.currentPlan || !this.addons) {
      console.error(
        "The mixin needs plans, currentPlan and addons as a scoped property."
      );
    }
  },
  mixins: [addon],
  methods: {
    updatedRecords(records) {
      return records
        .map((r) => {
          r.features = r.features.map((f) => {
            if (f.type === "storage") {
              f.attrs = { ...f.attrs }; // create new copy of attr and then update, do not update original data
              const quota = parseInt(f.attrs.quota_in_mb, 10);
              const convertedQuota = (
                f.attrs.quota_in_gb || toGB(f.attrs.quota_in_mb)
              ).toString();
              if (isSizeInGB(quota)) {
                f.attrs.quota_in_gb = convertedQuota;
                delete f.attrs.quota_in_mb;
              } else {
                f.attrs.quota_in_mb = convertedQuota;
              }
            }

            return f;
          });

          return {
            ...r,
            pricing: r.pricing || {
              monthly: 0,
              yearly: 0,
              semesterly: 0,
              quarterly: 0,
              two_yearly: 0,
              four_yearly: 0,
            },
          };
        })
        .sort((a, b) => a.zIndex > b.zIndex);
    },
  },
  computed: {
    updatedPlans() {
      const plansToRender = (this.plans || [])
        // .filter(p => (p.type.indexOf('trial') === -1)) // filter out trial plans
        .map((p) => ({
          ...p,
          title: p.displayName
            ? p.displayName.toUpperCase()
            : p.type.toUpperCase(),
          features: (p.features || []).sort(
            (f1, f2) => featureOrder[f1.type] - featureOrder[f2.type]
          ),
          // rearrange/sort features based on featureOrder
        }))
        .sort((p1, p2) => p1.zIndex - p2.zIndex); // rearrange/sort plans based on zIndex
      return this.updatedRecords(plansToRender);
    },
    billingDetails() {
      const addonsMap = {};
      const billingDetails = [];
      const curPlan = this.updatedPlans.find(
        (p) => p.id == this.currentPlan.planId
      );
      const planRemainingDays = dayDiff(this.currentPlan.expiresOn);
      const planRemainingMonths = dateDiff(
        new Date(),
        this.currentPlan.expiresOn
      );

      if (curPlan) {
        // this.currentPlan.zIndex = curPlan.zIndex;

        if (this.currentPlan.noOfAccounts.purchased) {
          let paidPlan = { displayName: "", pricing: {} }; // default value to avoid script break
          if (curPlan.isTrial && curPlan.paidPlanId) {
            paidPlan =
              this.updatedPlans.find((p) => p.id == curPlan.paidPlanId) ||
              paidPlan;
            paidPlan = { ...paidPlan };
            paidPlan.title = this.$t(
              "billingAndUpgrade.plans.billingDetailsName",
              [paidPlan.displayName.toUpperCase()]
            );
          }

          // < 1.5 month = 2month
          if (
            planRemainingMonths.monthDiff >= 1 &&
            planRemainingMonths.dayDiff > 15
          ) {
            planRemainingMonths.monthDiff++;
          }

          billingDetails.push({
            ...this.currentPlan,
            ...curPlan,
            subType: "plan",
            title: this.$t("billingAndUpgrade.plans.billingDetailsName", [
              curPlan.displayName.toUpperCase(),
            ]),
            subHeading: this.$tc(
              `billingAndUpgrade.billingSection.noOfMailboxes`,
              this.currentPlan.noOfAccounts.active
            ),
            accounts: this.currentPlan.noOfAccounts.purchased,
            amountToBePaidOnRenewal:
              curPlan.isPaid || curPlan.isTrial
                ? this.currentPlan.amountToBePaidOnRenewal
                : 0,
            paidPlan,
            purchaseStatus: this.currentPlan.domainDetails.status,
            remainingMonths: planRemainingMonths.monthDiff,
            remainingDays:
              !curPlan.isPaid && !curPlan.isTrial
                ? null
                : curPlan.isTrial
                ? planRemainingDays
                : planRemainingDays < 7 // for non trial plan show badge when its < 7 days remaining
                ? planRemainingDays
                : null,
            // included: !curPlan.isPaid ? this.$t(`billingAndUpgrade.billingSection.hostingIncluded`) : null
          });
        }

        (this.currentPlan.addons || []).forEach((a) => {
          addonsMap[a.addonId] = addonsMap[a.addonId] || {
            activeAddons: [],
            suspendedAddons: [],
            renewAddons: [],
            amountToBePaidOnRenewal: 0,
            purchaseStatus: null,
          };

          addonsMap[a.addonId]["purchaseStatus"] = a.status;
          addonsMap[a.addonId]["amountToBePaidOnRenewal"] +=
            a.amountToBePaidOnRenewal || 0;
          if (a.status === "suspended") {
            addonsMap[a.addonId]["suspendedAddons"].push(a.attachedTo);
          } else {
            addonsMap[a.addonId]["activeAddons"].push(a.attachedTo);
          }

          if (a.renewOnExpiry) {
            addonsMap[a.addonId]["renewAddons"].push(a.attachedTo);
          }
        });

        Object.keys(addonsMap).forEach((a) => {
          const addon = this.addons.find((ad) => ad.id == a); // dont compare type
          if (addon) {
            const title = this.getAddonDetails(addon, "title");
            billingDetails.push({
              ...addon,
              subType: "addon",
              id: addon.id,
              title,
              isPaid: addon.isPaid,
              subHeading: this.$t(
                `billingAndUpgrade.billingSection.noOfAccounts`,
                [
                  addonsMap[a].renewAddons.length,
                  addonsMap[a].renewAddons.length > 1 ? "s" : "",
                ]
              ),
              accounts: addonsMap[a].renewAddons.length,
              amountToBePaidOnRenewal: addonsMap[a].amountToBePaidOnRenewal,
              pricing: addon.pricing,
              included: null,
              attachedTo: addonsMap[a].activeAddons,
              suspendedAddons: addonsMap[a]["suspendedAddons"],
              purchaseStatus:
                !addonsMap[a].activeAddons.length &&
                addonsMap[a].suspendedAddons.length
                  ? "suspended"
                  : "active",
              remainingDays:
                !curPlan.isPaid && !curPlan.isTrial && planRemainingDays < 7
                  ? // for non trial plan show badge when its < 7 days remaining
                    planRemainingDays
                  : null,
            });
          }
        });
      }

      return billingDetails;
    },
  },
};
