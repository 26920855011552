import { BILLING_MONTHS } from "./const";
/**
 * Transforms a billing cycle string by replacing underscores with spaces and optionally capitalizing each word.
 *
 * @param {string} billingCycle - The billing cycle string to format.
 * @param {boolean} [shouldCapitalize=false] - Flag indicating whether the first letter of each word should be capitalized.
 * @returns {string} The formatted billing cycle string.
 */
export const getFormattedBillingCycle = (
  billingCycle,
  shouldCapitalize = false
) => {
  let string = billingCycle.replace(/_/g, " ");

  if (shouldCapitalize) {
    string = string.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  return string;
};

/**
 *
 * @param {String[]} billingCycles
 */
export const sortBillingCycles = (billingCycles) => {
  if (!billingCycles) {
    return billingCycles;
  }

  return billingCycles.sort((a, b) => {
    const a1 = BILLING_MONTHS[a] || Number.MAX_SAFE_INTEGER;
    const a2 = BILLING_MONTHS[b] || Number.MAX_SAFE_INTEGER;
    return a1 - a2;
  });
};
