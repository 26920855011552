<template>
  <div @click="clickHandler" class="back-btn">
    <img src="../../assets/left-arrow.svg" alt="left-arrow" width="20" />
    <span>{{ $t("utility.back") }}</span>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "BackButton",
  props: {
    path: {
      type: String,
      default: "",
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
  methods: {
    goBack() {
      if (this.path) {
        router.push(this.path);
      } else {
        router.back();
      }
    },
    clickHandler(e) {
      console.log("running this", e);
      if (this.onClick) return this.onClick(e);
      return this.goBack(e);
    },
  },
};
</script>

<style scoped lang="scss">
.back-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 20px 0px;
  cursor: pointer;

  span {
    color: var(--linkBtnCol);
    font-size: 15px;
  }
}
</style>
