import axios from "axios";
import { isEuRegion } from "./regions";
import { CUSTOMER, ORDER } from "@/helpers/const";
import smartStorage from "./smart-storage";
import { getDeviceInfo } from "./userAgent";

const FLOCK_ACCOUNT = "flock_account";
const FLOCK_ORDER = "flock_order";
const TITAN_CUSTOMER = "titan_customer";

// Avoiding cyclic depency so we used the method here instead of helpers/index.js"
const isCustomerEvent = (userType) => userType === CUSTOMER;
const isOrderEvent = (userType) => userType === ORDER;
function getOS() {
  const deviceInfo = getDeviceInfo();
  return deviceInfo.os.name;
}

function getBrowser() {
  const deviceInfo = getDeviceInfo();
  return deviceInfo.browser.name;
}

function getDevice() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

const device = getDevice() ? "mobile" : "desktop";
const browser = getBrowser();
const os = getOS();

function getEventType(userType, email) {
  if (isCustomerEvent(userType)) {
    return TITAN_CUSTOMER;
  } else if (isOrderEvent(userType)) {
    return FLOCK_ORDER;
  } else if (email) {
    return FLOCK_ACCOUNT;
  }
  return FLOCK_ORDER;
}

function getEventEntityType(userType, email, domain) {
  if (isOrderEvent(userType)) {
    return +smartStorage.getItem("domainID");
  } else if (isCustomerEvent(userType)) {
    return +smartStorage.getItem("bllUserId");
  }
  return email || domain;
}

class Medusa {
  /**
   * Creates an event object and track it to Medusa.
   * Providing an `email` attribute automatically makes this event of `flock_account` type.
   * @param {Object} event The event attributes that you want to send to Medusa
   * @returns {Promise} Promise that resovles when events are tracked.
   */
  trackEvent(event) {
    const domain = smartStorage.getItem("domain");
    const { email, user_type } = event;
    const type = getEventType(user_type, email);
    return this.send({
      entityId: getEventEntityType(user_type, email, domain),
      product: "email",
      service: "control_panel",
      type,
      attrs: {
        ...event,
        browser,
        device,
        domain_name: domain,
        eventTs: +new Date(),
        os,
      },
    });
  }

  send(eventObj) {
    const medusaUrl = isEuRegion
      ? process.env.VUE_APP_MEDUSA_EU_URL
      : process.env.VUE_APP_MEDUSA_URL;
    return axios
      .post(medusaUrl, eventObj)
      .then(() => {
        if (process.env.VUE_APP_ENV_FLAG !== "prod") {
          /* Log events only on staging and development. */
          console.log("Event logged", eventObj);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  entityChange(eventObj) {
    const medusaEntityChangeUrl = process.env.VUE_APP_MEDUSA_ENTITY_CHANGE_URL;
    return axios
      .post(medusaEntityChangeUrl, eventObj, {
        headers: {
          "Content-Type": "application/json",
          "X-CP-Token": smartStorage.getItem("authToken"),
        },
      })
      .then(() => {
        if (process.env.VUE_APP_ENV_FLAG !== "prod") {
          /* Log entity change events only on staging and development. */
          console.log("Entity change Event logged", eventObj);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  createEventObj(obj, entity, type, hattrs) {
    const eventObj = {
      product: "email",
      service: "control_panel",
      type: type,
      entityId: entity,
      attrs: {
        ...obj,
        os: getOS(),
        browser: getBrowser(),
        device: getDevice() ? "mobile" : "desktop",
        eventTs: +new Date(),
        domain_name: smartStorage.getItem("domain"),
      },
    };
    if (hattrs !== undefined) {
      eventObj.hattrs = hattrs;
    }
    return eventObj;
  }
}

export default new Medusa();
