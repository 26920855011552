import {
  DEFAULT_CURRENCY,
  CURRENCY_ENTITY_CODE as ALL_CURRENCIES,
} from "@/helpers";

/**
 * function to return currency symbol
 * if symbol is not present then just retun currency code
 */
export const getCurrencySymbol = (code) => {
  const CODE = (code || "").toUpperCase();
  return ALL_CURRENCIES[CODE] || CODE;
};

export function formatCurrency(num) {
  // This function returns minimum of 2 decimal places for any number.
  // in Other words , if we have 5 digits after decimal, the number will remain as it is
  // whereas if we have 1 or 0 digits after decimal, it will append two digits afetr decimal.
  // I/P : 13.1 => O/P :  13.10
  // I/P : 13.1234 => O/P :  13.1234
  if (!num) return "0";
  if (typeof num !== "string") num = num.toString();
  return Number(num).toFixed(Math.max((num.split(".")[1] || "").length, 2));
}

export function decode(str) {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec);
  });
}

export default {
  props: {
    currency: {
      type: String,
      default: DEFAULT_CURRENCY,
    },
  },
  created() {
    if (!this.currency) {
      console.error(
        "The currency mixin needs the attribute currency in either data, props or computed properties in the component."
      );
    }
  },
  filters: {
    convert(amount, currency) {
      return `${decode(getCurrencySymbol(currency))}${formatCurrency(amount)}`;
    },
    format(value) {
      return formatCurrency(value);
    },
  },
  methods: {
    getFormattedCurrency(value) {
      if (value === null) {
        return "";
      }
      return `${getCurrencySymbol(this.currency)}${formatCurrency(value)}`;
    },
  },
};
