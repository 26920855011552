/**
 * This is a helper file that will be used to post any information that we want to a Flock channel.
 * Each feature data (information tidbit), along with its channel URL is to be created in a separate function
 * and passed to the `postWebhookToFlockChannel` function which will send the data to Flock.
 */

import { WEBHOOK_CHANNEL_IDS } from "@/helpers/const";

const getWebhookChannelUrl = (webhookID) => {
  return `${process.env.VUE_APP_WEBHOOK_BASE_URL}${webhookID}`;
};

const { DOMAIN_CHANGE } = WEBHOOK_CHANNEL_IDS;
const ENV = process.env.VUE_APP_ENV_FLAG || "prod";

/**
 * Domain Change Feature
 * @param domainDetails
 * @returns {Promise<{requestSuccessful: boolean} | {requestSuccessful: boolean}>}
 */
export const postDomainChangeRequestToFlockChannel = async (domainDetails) => {
  const url = getWebhookChannelUrl(DOMAIN_CHANGE[ENV]);

  const { oldDomain, newDomain, domainId, email } = domainDetails;

  const data = {
    flockml: `<flockml>
        <b>Domain Change Request</b> <br/>
        User Email: ${email} <br/>
        Old Domain: ${oldDomain} <br/>
        Old Domain ID: ${domainId} <br/>
        New Domain: ${newDomain} <br/>
        -----------------------------------------------------------------
        </flockml>`,
  };

  return await postWebhookToFlockChannel(url, data);
};

/**
 * Sends a POST request to a specific Flock channel using a webhook.
 *
 * @param {string} url - The webhook URL of the Flock channel.
 * @param {Object} data - The JSON data to be sent to the Flock channel.
 * @returns {Promise<Object>} A Promise that resolves to an object. If the request is successful, the object contains a `requestSuccessful: true` property; otherwise, it contains a `requestSuccessful: false` property.
 *
 */
const postWebhookToFlockChannel = (url, data) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
      return {
        requestSuccessful: true,
      };
    })
    .catch((error) => {
      console.error("Failed to post to webhook Error:", error);
      return {
        requestSuccessful: false,
      };
    });
};
