<script>
export default {
  name: "UnsuspendAccount",
  props: {
    user: Object,
    multiSuspension: Boolean,
    suspensionType: String,
    view: Object,
  },
  data: function () {
    return {
      isSent: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    unsuspendAccount() {
      if (!this.isSent) {
        this.isSent = true;
        this.http.postData(
          "panel/account/email/unsuspend",
          {
            email: this.user.email,
            domainName: smartStorage.getItem("domain"),
            suspensionType: this.suspensionType,
          },
          this.$root.headers,
          this.onUnSuspendSuccess,
          this.onUnSuspendError
        );
      }
    },
    onUnSuspendSuccess(data) {
      if (data) {
        this.close();
        this.user.status = data.newStatus || "active";
        this.showToast(
          this.user.email + " " + this.$t("toast.reactivated"),
          {
            type: "success",
            modalName: "unsuspend-account",
          },
          2000
        );
        this.isSent = false;
        var eventObj = {
          eventName: "mail_account_reactivated",
        };
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            eventObj,
            this.user.email,
            "flock_account"
          )
        );
      }
    },
    onUnSuspendError(response) {
      if (response && response.data && response.data.desc) {
        this.close();
        const errorCode = response.data.code;
        const message =
          errorCode === "NOT_AUTHORIZED"
            ? this.$t(`emailAccounts.emailList.serverError.${errorCode}`)
            : response.data.desc;
        this.showToast(
          message,
          {
            type: "error",
            modalName: "unsuspend-account",
          },
          2000
        );
        this.isSent = false;
      }
    },
  },
};
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        :class="[
          'modal-body-container suspend-account-modal',
          { modalPage: view.showModalAsPage },
        ]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
          v-if="!view.showModalAsPage"
        >
          &times;
        </button>
        <widget-breadcrumb-nav
          :isModal="true"
          modalName="unsuspend-account"
          v-if="view.showModalAsPage"
          @onNavClick="close"
        />
        <div class="modal-content-wrapper">
          <div>
            <h4>
              {{ $t("emailAccounts.reactivateAccount.heading") }}
            </h4>
            <p class="email">{{ user.email }}</p>
            <p class="color-grey">
              {{
                multiSuspension
                  ? $t("emailAccounts.reactivateAccount.subHeading2")
                  : $t("emailAccounts.reactivateAccount.subHeading1")
              }}
            </p>
            <div class="input-wrapper">
              <button class="button" @click="unsuspendAccount">
                {{ $t("emailAccounts.reactivateAccount.unsuspendButton") }}
              </button>
              <button class="button button__line-blue" @click="close">
                {{ $t("emailAccounts.reactivateAccount.cancelButton") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.suspend-account-modal {
  p {
    line-height: 22px;
  }
}
</style>
