<template>
  <div class="domain-edit-modal">
    <div class="modal-heading">
      <h4>{{ $t("domainEditModal.heading") }}</h4>
      <div>{{ $t("domainEditModal.subHeading") }}</div>
    </div>

    <div class="input-wrapper">
      <input
        type="text"
        class="input"
        :class="{ 'b-red': hasError }"
        v-model="newDomainName"
        v-bind:placeholder="$t('domainEditModal.inputPlaceholder')"
        :disabled="processing"
        ref="domainInput"
      />
      <div class="error" :class="{ hidden: !hasError }" v-html="errorMessage" />
      <div>
        <div>
          <input type="checkbox" v-model="domainOwnershipVerified" />
        </div>
        <div
          v-html="
            $tc('domainEditModal.checkboxLabel', 1, {
              0: `<span class='domain-name'>${
                newDomainName || newDomainNameFallback
              }</span>`,
            })
          "
        />
      </div>
    </div>

    <div class="button-wrapper">
      <button
        :disabled="
          !domainOwnershipVerified || newDomainName === '' || processing
        "
        class="btn btn-primary"
        :class="{ processing: processing }"
        @click="handleUpdateDomainBtnClick"
      >
        <span v-if="newDomainName !== ''">
          {{ $t("domainEditModal.btnText", [newDomainName]) }}
        </span>
        <span v-else>{{ $t("genericText.update") }}</span>
      </button>
    </div>

    <div class="footer" v-html="$t('domainEditModal.footerText')" />
  </div>
</template>

<script>
import DomainEditSuccessModal from "@/components/DomainActivation/DomainEditModal/DomainEditSuccessModal.vue";
import {
  DOMAIN_UNAVAILABILITY_ERROR_MAP,
  domainUnavailableReasons,
  ERROR_KEYS,
} from "@/components/DomainActivation/DomainEditModal/DomainEditHelpers";

import {
  calculateDateDifference,
  getCurrentUserEmail,
  hasProtocolOrWwwSubdomain,
} from "@/helpers";
import { COMMON_MODAL_EMITTERS } from "@/helpers/const";
import eventBus from "@/helpers/event-bus";
import { MEDUSA_EVENTS_MAP } from "@/helpers/events";
import {
  getLambdaUrl,
  LAMBDA_ENTITIES,
  LAMBDA_FUNCTION_NAMES,
} from "@/helpers/lambdaHelpers";
import {
  getCommonMedusaParams,
  sendOrderAndCustomerEvent,
} from "@/helpers/medusaUtils";
import { postDomainChangeRequestToFlockChannel } from "@/helpers/webhookHelpers";
import { mapGetters } from "vuex";

const getErrorMessages = (vm) => {
  return {
    EMPTY_DOMAIN: vm.$t("domainEditModal.errorMessages.emptyDomain"),
    NO_CHANGE: vm.$t("domainEditModal.errorMessages.noChange"),
    DOMAIN_NOT_AVAILABLE: vm.$t(
      "domainEditModal.errorMessages.domainNotAvailable"
    ),
    DOMAIN_IN_USE: vm.$t("domainEditModal.errorMessages.domainInUse"),
    DOMAIN_NOT_REGISTERED: vm.$t(
      "domainEditModal.errorMessages.domainNotRegistered"
    ),
    UNVERIFIED_DOMAIN: vm.$t("domainEditModal.errorMessages.unverifiedDomain"),
    REQUEST_UNSUCCESSFUL: vm.$t(
      "domainEditModal.errorMessages.requestUnsuccessful"
    ),
    HAS_PROTOCOL: vm.$t("domainEditModal.errorMessages.hasProtocol"),
    SHARED_SUBDOMAIN: vm.$t("domainEditModal.errorMessages.sharedSubdomain"),
  };
};
const {
  CHECK_MAIL_ORDER_AVAILABILITY,
  CHECK_DOMAIN_REGISTRATION,
} = LAMBDA_FUNCTION_NAMES;
const { NEO } = LAMBDA_ENTITIES;

const {
  CHANGE_DOMAIN_AVAILABILITY_CHECKED,
  CHANGE_DOMAIN_REQUESTED,
  DOMAIN_AVAILABILITY_CHECKED,
} = MEDUSA_EVENTS_MAP;

const DOMAIN_NAME_FALLBACK = "example.com";

export default {
  name: "DomainEditModal",
  data() {
    return {
      currentDomain: this.domainName,
      domainOwnershipVerified: false,
      errorMessage: "",
      hasError: false,
      newDomainName: this.currentDomain,
      newDomainNameFallback: DOMAIN_NAME_FALLBACK,
      newDomainNameservers: null,
      processing: false,
      userEmail: getCurrentUserEmail(),
    };
  },
  props: ["sourceHook"],
  methods: {
    resetError() {
      this.hasError = false;
      this.errorMessage = "";
    },
    showErrorMessage(messageKey) {
      this.hasError = true;
      this.errorMessage = getErrorMessages(this)[messageKey];
    },
    setProcessing(isProcessing) {
      this.processing = isProcessing;
    },
    isDomainValid() {
      let isValid = true;
      const hasProtocolOrWww = hasProtocolOrWwwSubdomain(this.newDomainName);

      if (this.newDomainName === "") {
        // show empty error
        this.showErrorMessage(ERROR_KEYS.EMPTY_DOMAIN);
        isValid = false;
      }

      if (hasProtocolOrWww) {
        // show protocol error
        this.showErrorMessage(ERROR_KEYS.HAS_PROTOCOL);
        isValid = false;
      }

      if (this.newDomainName === this.currentDomain) {
        this.showErrorMessage(ERROR_KEYS.NO_CHANGE);
        this.logChangeDomainAvailabilityCheckMedusa(
          false,
          DOMAIN_UNAVAILABILITY_ERROR_MAP.NO_CHANGE
        );
        isValid = false;
      }

      return isValid;
    },
    checkDomainRegistration() {
      const url = getLambdaUrl(NEO, CHECK_DOMAIN_REGISTRATION);
      return this.http
        .postData(url, {
          domainName: this.newDomainName,
        })
        .then((res) => {
          const { registered, nameServers = null } = res;
          this.newDomainNameservers = nameServers && nameServers.join(", ");
          return registered;
        })
        .catch((err) => {
          console.error(err);
          // we will not block the user in case the registration check fails for whatever reason
          return true;
        });
    },
    checkDomainAvailability() {
      return new Promise((resolve, reject) => {
        const url = getLambdaUrl(NEO, CHECK_MAIL_ORDER_AVAILABILITY);

        this.http.postData(
          url,
          {
            domainName: this.newDomainName,
          },
          {},
          (res) => {
            if (res.statusCode === 200) {
              const { isDomainAvailable, unAvailabilityReason = "" } = res;

              this.logChangeDomainAvailabilityCheckMedusa(
                isDomainAvailable,
                unAvailabilityReason
              );

              resolve(res);
            } else {
              reject(new Error("Unexpected status code: " + res.statusCode));
            }
          },
          (err) => {
            if (err.data?.statusCode === 400) {
              const { code } = err.data;
              if (code === DOMAIN_UNAVAILABILITY_ERROR_MAP.DISALLOWED_DOMAIN) {
                this.logChangeDomainAvailabilityCheckMedusa(false, code);
              }
              reject(err.data);
            } else {
              reject(err);
            }
          }
        );
      });
    },
    async handleUpdateDomainBtnClick() {
      this.resetError();

      if (!this.domainOwnershipVerified || !this.isDomainValid()) {
        return;
      }

      this.setProcessing(true);

      /** Check if the domain is a valid, registered domain **/
      const isDomainRegistered = await this.checkDomainRegistration();
      if (!isDomainRegistered) {
        this.showErrorMessage("DOMAIN_NOT_REGISTERED");
        this.logChangeDomainAvailabilityCheckMedusa(
          false,
          DOMAIN_UNAVAILABILITY_ERROR_MAP.UNREGISTERED_DOMAIN
        );
        this.setProcessing(false);
        return;
      }

      /** Check if the domain is available or already attached to Neo **/
      try {
        const domainAvailability = await this.checkDomainAvailability();
        const {
          isDomainAvailable = false,
          unAvailabilityReason = "",
          code = ERROR_KEYS.REQUEST_UNSUCCESSFUL,
        } = domainAvailability;

        if (isDomainAvailable) {
          const domainDetails = {
            oldDomain: this.currentDomain,
            newDomain: this.newDomainName,
            domainId: this.domainID,
            email: this.userEmail,
          };

          this.logMedusaEvent(CHANGE_DOMAIN_REQUESTED, {
            source_hook: this.sourceHook,
            new_domain: this.newDomainName,
          });

          postDomainChangeRequestToFlockChannel(domainDetails).then((res) => {
            const { requestSuccessful } = res;
            if (requestSuccessful) {
              // close current modal and show success screen
              eventBus.$emit(COMMON_MODAL_EMITTERS.OPEN_MODAL, {
                component: DomainEditSuccessModal,
                props: {
                  newDomainName: this.newDomainName,
                },
                modalWidth: "500px",
                replaceCurrentModal: true,
              });
            } else {
              // show error message
              this.showErrorMessage(ERROR_KEYS.REQUEST_UNSUCCESSFUL);
              this.setProcessing(false);
            }
          });
        } else {
          // The domain is not available, show error based on reason or default to generic reason as fallback
          let errorMessageKey =
            DOMAIN_UNAVAILABILITY_ERROR_MAP[unAvailabilityReason] ||
            ERROR_KEYS[code];

          this.showErrorMessage(errorMessageKey);
          this.setProcessing(false);
        }
      } catch (e) {
        this.showErrorMessage(
          ERROR_KEYS[e.code] || ERROR_KEYS.REQUEST_UNSUCCESSFUL
        );
        this.setProcessing(false);
      }
    },
    logChangeDomainAvailabilityCheckMedusa(
      isDomainAvailable,
      unAvailabilityReason
    ) {
      const {
        autoRenew,
        expiresOn,
        isTrial,
        noOfAccounts,
      } = this.currentPlanDetails;

      let commonPayload = {
        auto_renew_enabled: autoRenew,
        domain_name_length: this.newDomainName.split(".")[0].length,
        domain_nameserver: this.newDomainNameservers,
        mailbox_count: noOfAccounts.active,
        new_domain: this.newDomainName,
        source_hook: this.sourceHook,
      };

      if (isTrial) {
        const freeTrialDaysRemaining = calculateDateDifference(expiresOn);
        commonPayload = {
          ...commonPayload,
          free_trial_days_left: freeTrialDaysRemaining,
        };
      }

      let finalPayload;
      if (isDomainAvailable) {
        finalPayload = {
          ...commonPayload,
          domain_availability: "Available",
        };
        this.logMedusaEvent(
          CHANGE_DOMAIN_AVAILABILITY_CHECKED,
          finalPayload,
          false
        );
        this.logMedusaEvent(
          DOMAIN_AVAILABILITY_CHECKED,
          {
            ...finalPayload,
            user_type: "customer",
          },
          false
        );
      } else {
        finalPayload = {
          ...commonPayload,
          domain_availability:
            domainUnavailableReasons[unAvailabilityReason]
              .event_availability_message,
          error:
            domainUnavailableReasons[unAvailabilityReason].event_error_message,
        };
        this.logMedusaEvent(
          CHANGE_DOMAIN_AVAILABILITY_CHECKED,
          finalPayload,
          false
        );
        this.logMedusaEvent(
          DOMAIN_AVAILABILITY_CHECKED,
          {
            ...finalPayload,
            user_type: "customer",
          },
          false
        );
      }
    },
    logMedusaEvent(eventName, params, sendBothOrderAndCustomer = true) {
      if (sendBothOrderAndCustomer) {
        sendOrderAndCustomerEvent.call(this, {
          eventName,
          ...params,
        });
      } else {
        this.medusaEvents.trackEvent({
          ...getCommonMedusaParams(),
          eventName,
          ...params,
        });
      }
    },
  },
  created() {
    this.currentDomain = this.domainName;
    this.newDomainName = this.domainName;
  },
  mounted() {
    this.$refs.domainInput.focus();
  },
  computed: {
    ...mapGetters(["domainName", "domainID", "currentPlanDetails"]),
  },
};
</script>

<style lang="scss" scoped>
.domain-edit-modal {
  padding: 40px;
  font-size: 14px;
  line-height: 22px;

  .modal-heading {
    margin-bottom: 60px;
  }

  .input-wrapper {
    margin-bottom: 60px;

    .input {
      &[disabled] {
        background: var(--readOnlyTxtBgCol);
        user-select: none;
      }
    }

    & > div:not(.error) {
      display: flex;
      gap: 10px;
      margin-top: 12px;
    }

    ::v-deep .domain-name {
      font-weight: 600;
      display: inline-block;
      word-break: break-word;
    }

    .error {
      margin-top: 12px;
      font-size: 14px;

      ::v-deep a {
        font-weight: 600;
      }

      &.hidden {
        display: none;
      }
    }

    input[type="checkbox"] {
      margin-top: 5px;
    }
  }

  .button-wrapper {
    margin-bottom: 60px;

    .btn {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      word-wrap: normal;
      height: 40px;

      span {
        color: inherit;
      }

      &.processing {
        background: var(--primaryBtnCol)
          url("../../../assets/loading-spinner-white.svg") no-repeat 10px center /
          20px;
        padding-left: 35px;
      }

      &:hover {
        color: var(--primaryBtnTextCol);
      }
    }

    div {
      color: var(--tertiaryTextCol);
      word-break: break-word;
    }
  }

  .footer {
    padding: 20px;
    background: var(--lightBlueBg);
    border-top: 1px solid var(--lightBlueBorder);
    font-size: 16px;
    font-weight: 500;
    margin: 0 -56px -56px;
    text-align: center;

    ::v-deep a {
      font-weight: 600;
    }
  }
}
</style>
