<template>
  <div class="mobile-home-container">
    <div class="wrapper">
      <plan-card />
      <mobile-nav-bar />
      <change-billing-cycle
        @close="toggleBillingCycleModal"
        v-if="showChangeBillingCycleModal"
        :addons="addons"
        :billingDetails="billingDetails"
        :currency="currency"
      />
    </div>
  </div>
</template>

<script>
import PlanCard from "./PlanCard.vue";
import MobileNavBar from "../MobileNavBar/MobileNavBar.vue";
import ChangeBillingCycle from "../BillingAndSubscription/ChangeBillingCycle.vue";
import { billing, currency as currencyMixin } from "@/mixins";
import { FLOW_TYPES } from "@/helpers/const";
import { removeQueryParamFromRoute } from "@/helpers/router";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";

const { CHANGE_BILLING_CYCLE } = FLOW_TYPES;

export default {
  components: {
    PlanCard,
    MobileNavBar,
    ChangeBillingCycle,
  },
  props: { plans: Array, addons: Array, currentPlan: Object },
  mixins: [billing, currencyMixin],
  computed: {
    showChangeBillingCycleModal() {
      return this.$route.query[CHANGE_BILLING_CYCLE] === "true" || false;
    },
  },
  methods: {
    toggleBillingCycleModal(refetchCurrentPlan) {
      if (this.showChangeBillingCycleModal) {
        this.removeQueryParam();
      } else {
        this.$route.query[CHANGE_BILLING_CYCLE] = "true";
      }
      if (refetchCurrentPlan) {
        this.$emit("fetchCurrentPlan");
      }
    },
    removeQueryParam() {
      removeQueryParamFromRoute(
        this.$route,
        this.$router,
        CHANGE_BILLING_CYCLE
      );
    },
  },
  mounted() {
    measurePagePerformance();
  },
};
</script>

<style scoped>
.wrapper {
  padding: 20px 16px;
}
</style>
