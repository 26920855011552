<template>
  <div class="header-wrapper">
    <div class="main-header">
      <div @click="goToHomePage">
        <img :src="imageUrl" alt="logo" height="20" class="logo" />
        <div class="app-name">
          {{ $t(`pageHeader.headerItem.${brandDetails.name}`) }}
        </div>
      </div>
      <div @click="openMailApp()">
        <img src="../../assets/webmail-icon.svg" alt="mail-icon" />
        {{ $t("mail") }}
      </div>
    </div>
    <domain-switcher :logout="logout" :type="type" />
    <div
      class="plan-error-banner"
      v-if="paymentEnabled && showPlanExpiredError"
    >
      <i />
      <div>
        <div class="banner-description">
          {{
            $t(`bannerText.${determineBannerTextKey}.desc`, [
              planDisplayName.toUpperCase(),
            ])
          }}
        </div>
        <TitanButton type="transparent" small @click="redirectToBilling()">{{
          $t(`bannerText.planExpiredError.${bannerButtonTxt}`)
        }}</TitanButton>
      </div>
    </div>
  </div>
</template>

<script>
import DomainSwitcher from "@/components/Utility/DomainSwitcher.vue";
import { openMailApp } from "@/helpers/mobile";

export default {
  name: "AppHeaderMobile",
  components: { DomainSwitcher },
  props: {
    brandDetails: Object,
    logout: Function,
    domainList: Array,
    imageUrl: String,
    type: String,
    paymentEnabled: Boolean,
    showPlanExpiredError: Boolean,
    determineBannerTextKey: String,
    bannerButtonTxt: String,
    redirectToBilling: Function,
    planDisplayName: Object,
  },
  computed: {
    defaultDomainName() {
      return smartStorage.getItem("domain");
    },
  },
  methods: {
    openMailApp,
    goToHomePage() {
      this.$router.push({ name: "mobile Home" });
    },
  },
};
</script>

<style scoped lang="scss">
.header-wrapper {
  .main-header {
    padding: 16px;
    background: var(--headerBgCol);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    z-index: 99999;

    & > div {
      display: flex;
      align-items: center;
      color: var(--primaryBtnTextCol);

      img {
        margin-right: 4px;

        &.logo {
          margin-right: 8px;
        }
      }

      .app-name {
        color: var(--primaryBtnTextCol);
      }
    }
  }

  .plan-error-banner {
    display: flex;
    background-color: var(--errorSecTextCol);
    padding: 12px;
    margin: 16px 16px 0;
    border-radius: 4px;

    i {
      display: inline-block;
      min-width: 20px;
      height: 20px;
      background: url("../../assets/warning-icon-red.svg") center no-repeat;
      background-size: contain;
      vertical-align: -2px;
      margin-right: 10px;
    }

    .banner-description {
      margin-bottom: 12px;
    }
  }
}
</style>
