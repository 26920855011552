var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dnsLookupTimeout || _vm.domainNotFound)?_c('div',{staticClass:"error-validation"},[_c('i'),_vm._v(_vm._s(_vm.$t( `bannerText.${ _vm.dnsLookupTimeout ? "dnsTimeout" : "DomainNotFound" }.desc` ))+" "),_c('TitanButton',{staticClass:"active-now",attrs:{"small":"","type":"transparent"},on:{"click":function($event){return _vm.$router.push({
          path: '/domain-verification',
          query: {
            source_hook: 'dns_zone_inactive_banner',
          },
        })}}},[_vm._v(_vm._s(_vm.$t("importEmail.details.learnMore")))])],1):(_vm.domainNotVerifiedOrNotConnected)?_c('div',{staticClass:"error-validation",class:{ bannerHeight: _vm.bannerLoading }},[(_vm.bannerLoading)?_c('div',{staticClass:"shimmer animate"}):_c('div',{staticClass:"banner-wrapper"},[_c('i'),_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('bannerText.domainActivate.strongText', [
            _vm.smartStorage.getItem('domain'),
            _vm.brandDetails.name,
          ])
        )}}),(_vm.isNeoCustomDomain && _vm.entriSetupPossible)?_c('div',[_c('button',{staticClass:"connect-automatically-btn",on:{"click":_vm.emitAutomaticSetup}},[_vm._v(" "+_vm._s(_vm.$t("bannerText.domainActivate.connectAutomatically"))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t("bannerText.domainActivate.oR")))]),_c('router-link',{staticClass:"connect-manual-btn",attrs:{"to":"/domain-verification?source_hook=control_panel_dns_setup_banner"},nativeOn:{"click":function($event){return _vm.emitManualSetup.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("bannerText.domainActivate.connectManually"))+" ")])],1):_c('router-link',{staticClass:"button button__line-blue active-now",attrs:{"to":"/domain-verification?source_hook=control_panel_dns_setup_banner"},nativeOn:{"click":function($event){return _vm.emitManualSetup.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("bannerText.domainActivate.btnText"))+" ")])],1)]):(_vm.poorEmailReputation)?_c('div',{class:['error-validation', { secondary: _vm.onlyDkimVerificationPending }]},[_c('i'),_c('span',[_vm._v(_vm._s(_vm.$t("emailReputation.statusBar.error", [ _vm.$t(`emailReputation.statusBar.errorType.${_vm.emailReputationErrorType}`), ])))]),_c('titan-button',{attrs:{"className":'transparent ml-2'},on:{"click":_vm.handleEmailReputationBannerClick}},[_c('router-link',{staticClass:"no-decoration",attrs:{"to":"/email-reputation"}},[_vm._v(_vm._s(_vm.$t("bannerText.emailReputation.btnText"))+" ")])],1)],1):(_vm.nsNotVerified)?_c('div',{staticClass:"error-validation"},[_c('i'),_vm._v(_vm._s(_vm.$t("bannerText.nsNotFound.desc", [_vm.brandDetails.name]))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }