import store from "@/store";
import { getAppSource, isNeoBrand, isNeoBusinessDomain } from "@/helpers";
import { CUSTOMER, NEO_OFFERING } from "@/helpers/const";

const getOrderStatusOfCurrentDomain = () => {
  const currentDomain = store.getters.domainName;
  const domainFromStore = store.getters.domainList.find(
    (domain) => domain.name == currentDomain
  );
  return domainFromStore ? domainFromStore?.status : "";
};

const getBillingFrequency = () => {
  const currentPlan = store.getters.currentPlanDetails;
  return currentPlan.autoRenew
    ? currentPlan.renewalBillingCycle
    : currentPlan.billingCycle;
};

export const isBillingFrequencyChanged = () => {
  const currentPlan = store.getters.currentPlanDetails;
  return currentPlan.billingCycle !== getBillingFrequency();
};

// Write common medusa params of each here in this file
export const getCommonMedusaParams = () => {
  return {
    order_status: getOrderStatusOfCurrentDomain(),
    domain_name: store.getters.domainName,
    partner_id: +store.getters.partnerId,
    customer_id: +smartStorage.getItem("bllUserId"),
    plan_id: +store.getters.planId,
    plan_name: smartStorage.getItem("plan"),
    order_id: +store.getters.domainID,
    user_type: "order",
    entityId: +store.getters.domainID,
    neo_offering: isNeoBrand()
      ? isNeoBusinessDomain()
        ? NEO_OFFERING.COSITE
        : NEO_OFFERING.CUSTOM_DOMAIN
      : "",
    existing_account_count: +store.getters.currentDomainMailboxDetails?.length,
    billing_cycle: store.getters.currentPlanDetails.billingCycle,
    expiry_date: +new Date(store.getters.currentPlanDetails.expiresOn),
    source: getAppSource(),
    ...(smartStorage.getItem("role") === "accountAdmin" && {
      account_id: parseInt(smartStorage.getItem("accountId")),
    }),
    ...(isBillingFrequencyChanged()
      ? {
          new_billing_cycle: getBillingFrequency(),
        }
      : {}),
  };
};

export const getCommonMedusaParamsForUpgradePlan = (plan) => {
  return {
    new_plan_id: plan.id,
    new_plan_name: plan.type,
  };
};

export const getCommonMedusaParamsForCustomerEvents = () => {
  return {
    ...getCommonMedusaParams(),
    user_type: CUSTOMER,
    entityId: +smartStorage.getItem("bllUserId"),
  };
};

export function sendOrderAndCustomerEvent(event = {}, isCustomerEvent = true) {
  this.medusaEvents.trackEvent({
    ...getCommonMedusaParams(),
    ...event,
  });
  isNeoBrand() &&
    isCustomerEvent &&
    this.medusaEvents.trackEvent({
      ...getCommonMedusaParamsForCustomerEvents(),
      ...event,
      eventName: `${CUSTOMER}_${event.eventName}`,
    });
}

export function triggerPurchaseAndBillingEvents(
  restMedusaParams,
  isCustomerEvent = true
) {
  const { eventName } = restMedusaParams;
  const billingParams = store.getters.purchaseAndBillingDetails;
  this.medusaEvents.trackEvent({
    ...getCommonMedusaParams(),
    ...billingParams,
    ...restMedusaParams,
  });
  isNeoBrand() &&
    isCustomerEvent &&
    this.medusaEvents.trackEvent({
      ...getCommonMedusaParamsForCustomerEvents(),
      ...billingParams,
      ...restMedusaParams,
      eventName: `${CUSTOMER}_${eventName}`,
    });
}
