import * as helper from "@/helpers/index";
import { toast } from "@/mixins";
import store from "@/store";
import Generator from "./generate-password";
import merge from "lodash/merge";
import smartStorage from "./smart-storage";
import { getAppSource } from "./url";
import { sendOrderAndCustomerEvent } from "./medusaUtils";
import {
  DNS_ERROR_TYPES,
  NEO_BUSINESS_DOMAINS,
  BILLING_PRICE,
  NEO_HOST_NAMES,
  partnerWiseFontFamily,
  PLAN_FEATURE_MAP,
  BILLING_CYCLE_SOURCES_LIST,
  SUBSCRIPTION_KEYS,
  CP_SOURCES_MAP,
  ADMIN_TYPE,
} from "./const";
import { MEDUSA_EVENTS_MAP } from "./events";
import { PAYMENT_ACTION } from "./iframeEvents";
import { NEO_PARTNER_ID_NUMBER } from "./partner";
import $http from "./http";
import queryParamStore from "@/global/queryParamStore";
import { isSiteSetupMode } from "./entri";

export function isProdEnv() {
  return process.env.VUE_APP_ENV_FLAG === "prod";
}

/**
 * function to do below conversion
 * hello-world => helloWorld
 * @param {string} str
 * @returns string
 */
export function hyphenToCamelCase(str) {
  return (str || "").replace(/-([a-z])/g, (s) => s[1].toUpperCase());
}

/**
 * function to get all query params in map format
 * @returns { paramKey: paramVal }
 */
export function getAllQueryParams() {
  const queryParams = new URLSearchParams(window.location.search);
  return Array.from(queryParams.keys()).reduce((acc, paramKey) => {
    return { ...acc, [paramKey]: queryParams.get(paramKey) };
  }, {});
}

/**
 * function to return filtered query params in url format
 * @param {[string]} paramsToBeFiltered
 */
export function getFilteredQueryParams(paramsToBeFiltered = []) {
  const allParamsMap = getAllQueryParams();
  const uriParams = new URLSearchParams();
  Object.keys(allParamsMap).forEach((k) => {
    if (paramsToBeFiltered.indexOf(k) !== -1) return false;
    uriParams.set(k, allParamsMap[k]);
  });

  return uriParams;
}

export function getJWTpayloadData(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function urlParser(params = null) {
  let urlParams = null;
  /* eslint-disable */
  return (function getURLParameters() {
    if (urlParams !== null) {
      return urlParams;
    }
    var hasFragMentedURL = window.location.hash !== "";
    /*
      This line takes care of routing mechanism if any router is used.
      If a router is used, a `/` will be appended after `#`, like => `index.html/#/flockClient=xyz&..`
      and without routing, the hash would be `index.html/#flockClient=xyz&..`
      So we remove first 2 letters for routing and only one for non-routing cases.
      it checks if the hash is #flockClient or #/flockClient.
     */
    var subStringIndex = window.location.hash[1] !== "/" ? 1 : 2;
    var match,
      pl = /\+/g,
      search = /([^&=]+)=?([^&]*)/g,
      searchStr,
      decode = function (s) {
        return decodeURIComponent(s.replace(pl, " "));
      },
      query = "";
    if (params === null) {
      query = hasFragMentedURL
        ? window.location.hash.substring(subStringIndex)
        : "";
      query = window.location.search.substring(1) + "&" + query;
    } else {
      query = params;
    }
    urlParams = {};
    match = search.exec(query);
    while (match) {
      urlParams[decode(match[1])] = decode(match[2]);
      match = search.exec(query);
    }
    if (urlParams["?section"]) {
      urlParams.section = urlParams["?section"];
      delete urlParams["?section"];
    }
    return urlParams;
  })();
}

/**
 * function to check whether provided size is in GB or not (more than 1000 mb)
 * @param {*} sizeInMb
 * @returns boolean
 */
export function isSizeInGB(sizeInMb) {
  return sizeInMb >= 1000;
}

/**
 * function to return the size unit
 * @param {number} sizeInMb
 * @returns string
 */
export function getSizeUnit(sizeInMb) {
  return isSizeInGB(sizeInMb) ? "GB" : "MB";
}

/**
 * function to return formatted storage size
 * @param {number} sizeInMb
 * @returns string
 * @example 1000 => 1 GB
 * @example 100 => 100 MB
 */
export function getFormattedStorageSize(sizeInMb) {
  return `${toGB(sizeInMb)} ${getSizeUnit(sizeInMb)}`;
}

/**
 * function to get available storage space
 * @param {Number} used - used space
 * @param {Number} limit - max limit
 * @param {Boolean} dontCovert - flag to skip conversion
 * @returns {Number} - available space gb/mb
 */
export function getAvailableSpace(used, limit, dontCovert) {
  let availableSpace = limit <= used ? 0 : limit - used;
  return dontCovert
    ? availableSpace
    : `${toGB(availableSpace)} ${getSizeUnit(availableSpace)}`;
}

/**
 * function to convert mb to gb (if applicable)
 * @param {Number} val - mb value
 * @returns {Number} - in gb/mb
 */
export function toGB(val) {
  if (isSizeInGB(val)) {
    return (val / 1000).toFixed(2).replace(".00", ""); // remove empty zeros if any
  } else {
    return val;
  }
}

export function calculateChargeUtil({
  paymentPayload,
  addons,
  billingCycleMap,
  isPlanPurchaseFlow,
  returnDurationInMonths,
  getAddonDetails,
}) {
  return $http
    .post(`panel/billing/calculate-charge`, paymentPayload)
    .then((data) => {
      // success
      if (isPlanPurchaseFlow) {
        data.calculation.addons = (data.calculation.addons || []).map((ad) => {
          return {
            ...ad,
            title: getAddonDetails(
              addons.find((a) => a.id === ad.addonId),
              "title"
            ),
          };
        });
      }
      let calculatedCharge = data;
      let calculation = data.calculation;
      let calAfterUpdate = data.calculation.afterUpdate;
      let _paymentPayload = {
        ...paymentPayload,
        clientTrackingId: Generator.generate({
          length: 10,
          numbers: true,
        }),
      };
      let durationBilledFor = "";

      if (data.calculation.durationBilledFor.years) {
        if (returnDurationInMonths) {
          durationBilledFor += `${
            data.calculation.durationBilledFor.years * 12
          } months`;
        } else {
          durationBilledFor += `${data.calculation.durationBilledFor.years} ${
            data.calculation.durationBilledFor.years > 1 ? "years" : "year"
          } `;
        }
      }
      if (data.calculation.durationBilledFor.months) {
        durationBilledFor += `${data.calculation.durationBilledFor.months} ${
          data.calculation.durationBilledFor.months > 1 ? "months" : "month"
        } `;
      }
      if (data.calculation.durationBilledFor.days) {
        durationBilledFor += `${data.calculation.durationBilledFor.days} ${
          data.calculation.durationBilledFor.days > 1 ? "days" : "day"
        } `;
      }
      // value of duration of current billing cycle should be 1
      let showProratedHelperText =
        data.calculation.durationBilledFor[
          billingCycleMap[calAfterUpdate.billingCycle]
        ] !== 1;
      // loop through each cycle(except current billing cycle) and check whether any value set or not
      if (!showProratedHelperText) {
        Object.keys(data.calculation.durationBilledFor).forEach((cycle) => {
          if (
            cycle !== billingCycleMap[calAfterUpdate.billingCycle] &&
            data.calculation.durationBilledFor[cycle]
          ) {
            showProratedHelperText = true;
          }
        });
      }
      let billingPeriod = `${formatDate()} - ${formatDate(
        calAfterUpdate.expiresOn
      )}`;
      return {
        calculatedCharge,
        calculation,
        calAfterUpdate,
        _paymentPayload,
        durationBilledFor,
        showProratedHelperText,
        billingPeriod,
      };
    });
}

/**
 * function to avoid calculate charge code duplication
 * (imp - always bind function to `this` before calling,
 * prefer function.call(this, param))
 * @todo : Include this in a global mixin to avoid binding this.
 * @param {Function} next - next step
 * @param {Object} paymentPayload - payload object
 * @param {Boolean} isPlanPurchaseFlow - to do plan purchase specific calc
 * @param returnDurationInMonths
 */
export async function calculateCharge(
  next,
  paymentPayload,
  isPlanPurchaseFlow,
  returnDurationInMonths = false
) {
  this.showButtonSpinner = true;

  try {
    const {
      calculatedCharge,
      calculation,
      calAfterUpdate,
      _paymentPayload,
      durationBilledFor,
      showProratedHelperText,
      billingPeriod,
    } = await calculateChargeUtil({
      paymentPayload,
      addons: this.addons,
      isPlanPurchaseFlow,
      billingCycleMap: this.billingCycleMap,
      returnDurationInMonths,
      getAddonDetails: this.getAddonDetails,
    });

    this.calculatedCharge = calculatedCharge;
    this.calculation = calculation;
    this.calAfterUpdate = calAfterUpdate;
    this.paymentPayload = _paymentPayload;
    this.durationBilledFor = durationBilledFor;
    this.showProratedHelperText = showProratedHelperText;
    this.billingPeriod = billingPeriod;
    next(); // procced to step 2 || order summary
  } catch (e) {
    this.handleAPIError(e);
  } finally {
    this.showButtonSpinner = false;
  }
}

/**
 * Removes a specific query parameter or all query parameters from the URL.
 * @param {string} [paramKey] - The key of the query parameter to remove. If not provided, all parameters will be removed.
 */
export const clearQueryParam = (paramKey) => {
  const url = new URL(window.location);

  if (paramKey) {
    // Remove the specified query parameter
    url.searchParams.delete(paramKey);
  } else {
    // Clear all query parameters
    url.search = "";
  }

  // Update the URL without reloading the page
  window.history.replaceState(null, "", url);
};

export function setCookie(cname, cvalue, exdays, dontAppendDomain) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays || 1) * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  if (!dontAppendDomain) {
    cname = `${cname}-${smartStorage.getItem("domain")}`;
  }
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function redirectToSameORDifferentTab(route, openInDifferentTab) {
  if (openInDifferentTab) {
    window.open(route, "_blank");
  } else {
    window.location.href = route;
  }
}

export function hardRedirect(route, skip, openInDifferentTab) {
  if (skip) {
    // do not preserve data, possibly external route hence
    redirectToSameORDifferentTab(route, openInDifferentTab);
    return true;
  }
  const newRoute = smartStorage.preserve ? smartStorage.preserve(route) : route;
  redirectToSameORDifferentTab(newRoute, openInDifferentTab);
}

export function getNeoSignupAppUrl() {
  const domainList = store.getters.domainList;
  let queryParams = {
    source_hook: "neoAdminPanel",
    clearDomain: true,
    isCustomerAccount: false,
  };

  if (smartStorage.getItem("adminType") === ADMIN_TYPE.CUSTOMER) {
    queryParams = {
      ...queryParams,
      auth_token: smartStorage.getItem("authToken"),
      name: smartStorage.getItem("userName"),
      alternateEmail: smartStorage.getItem("altEmail"),
      token_expiry: smartStorage.getItem("authTokenExpiry"),
      hasActiveOrders: domainList.length > 0,
      customer_id: smartStorage.getItem("bllUserId"),
      isCustomerAccount: true,
    };
  }

  return helper.getUrl(process.env.VUE_APP_NEO_PURCHASE_FLOW_URL, queryParams);
}

export function hardReload(skip) {
  hardRedirect(window.location.href, skip);
}

export function getCookie(cname, dontAppendDomain) {
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  if (!dontAppendDomain) {
    cname = `${cname}-${smartStorage.getItem("domain")}=`;
  }

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(cname) == 0) {
      return c.substring(cname.length, c.length);
    }
  }
  return "";
}

/**
 * common function to return feature (storage) details
 * @param {Array} features
 * @param {String} featureToFind
 */
export function getFeatureDetails(features, featureToFind) {
  const respFeature = features.find((f) => f.type === featureToFind);
  // eslint-disable-next-line no-unused-vars
  const attrs = Object.keys(respFeature.attrs || {});
  const respAttr = getRespectiveAttr(respFeature);
  const attrValue = respFeature.attrs[respAttr];

  return { respFeature, respAttr, attrValue };
}

/**
 * common function to return feature attribute
 * @param {Object} feature
 */
export function getRespectiveAttr(feature) {
  const attrs = Object.keys(feature.attrs || {});

  switch (feature.type) {
    case PLAN_FEATURE_MAP.STORAGE:
      return feature.attrs.quota_in_gb ? "quota_in_gb" : "quota_in_mb";

    case PLAN_FEATURE_MAP.READ_RECEIPTS:
      return feature.attrs.trial_in_days && !isNeoBrand()
        ? "trial_in_days"
        : feature?.attrs?.limit
        ? "limit"
        : "text";

    case PLAN_FEATURE_MAP.CONTACT_GROUPS:
      return feature?.attrs?.limit ? "limit" : "text";

    case PLAN_FEATURE_MAP.MAIL_SEND:
      return "text";

    default:
      return attrs[0] || "text";
  }
}

export function sortAlphabetically(c1, c2) {
  if (c1.name < c2.name) {
    return -1;
  } else if (c1.name > c2.name) {
    return 1;
  }
  return 0;
}

export function setWebsiteMetaData() {
  const brandDetails = store.getters.brandDetails;
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = brandDetails.favIcon;
  document.getElementsByTagName("head")[0].appendChild(link);
  document.title = brandDetails.appName;
}

export function getPartnerInfo(config) {
  const brandParam = smartStorage.getItem("domainID")
    ? { domainId: smartStorage.getItem("domainID") }
    : { brandedHost: window.location.host };
  const url = "/internal/getPartnerInfo";
  const mergedConfig = merge(config, { params: brandParam });
  return this.$http.get(url, mergedConfig);
}

export function verifyDkim(dkimId, successCb = () => {}, errorCb = () => {}) {
  this.$http
    .post(
      `/panel/domain/dkim/enable?`,
      {
        domainId: smartStorage.getItem("domainID"),
        dkimId,
      },
      this.$root.headers
    )
    .then((res) => {
      store.dispatch("domainConnectData", {
        ...store.getters.domainConnectData,
        dkimVerified: true,
      });
      successCb(res);
    })
    .catch((err) => {
      errorCb(err);
      this.showToast(
        this.$t(
          `DKIM.error.${err.data.code}` ||
            "billingAndUpgrade.addonPurchase.accountSelectSection.commonError"
        )
      );
    });
}

export const buildLookupQueryParams = (lookups) =>
  (lookups || ["mx", "spf", "ns", "a", "cname"]).reduce(
    (acc, e, i) => `${acc}${i ? "&" : ""}lookups=${e}`,
    ""
  );

export function runDnsLookup(
  successCb = () => {},
  errorCb = () => {},
  config = {}
) {
  const { setupMode, entriSetupPossible, lookups, ...params } = config;
  const { source } = params || {};
  const url = `/internal/domain/dns-banner-config?${buildLookupQueryParams(
    lookups
  )}`;
  const _isSiteSetupMode = isSiteSetupMode(setupMode);
  const entriMedusaAttrb = {
    dns_setup_for: _isSiteSetupMode ? "Site" : "Email",
    ...(entriSetupPossible
      ? {
          auto_domain_setup_supported: entriSetupPossible,
        }
      : {}),
  };
  // source -> Either a button click from corresponding components or initially called when app is loaded
  const isInitialRender = source === "control_panel_banner";
  return this.$http
    .get(url, {
      params: {
        checkDomainConnect:
          smartStorage.getItem("checkDomainConnect") === "true",
        recheck: true,
        ...params,
      },
    })
    .then((response) => {
      store.dispatch("domainConnectData", response);
      successCb(response);
      if (source) {
        const isSetupComplete =
          (_isSiteSetupMode && isSiteVerifiedAndConnected()) ||
          isDomainVerifiedAndConnected();
        sendOrderAndCustomerEvent.call(this, {
          eventName: isSetupComplete
            ? MEDUSA_EVENTS_MAP.CONNECT_DOMAIN_COMPLETED
            : MEDUSA_EVENTS_MAP.CONNECT_DOMAIN_FAILED,
          source: getAppSource(),
          ...entriMedusaAttrb,
        });
      }
    })
    .catch((error) => {
      const dnsErrorType = error?.data?.code;
      let DomainNotFound = false;
      let LookupTimeout = false;
      switch (dnsErrorType) {
        case DNS_ERROR_TYPES.LOOKUP_TIMEOUT:
          LookupTimeout = true;
          setCookie("domainConnectStatus", "unknown", 1);
          break;
        case DNS_ERROR_TYPES.HOST_NOT_FOUND:
        case DNS_ERROR_TYPES.DOMAIN_NOT_FOUND:
          DomainNotFound = true;
          break;
        default:
          setCookie("domainConnectStatus", "unknown", 1);
      }
      // Do not trigger in initial render
      if (source && !isInitialRender) {
        sendOrderAndCustomerEvent.call(this, {
          eventName: MEDUSA_EVENTS_MAP.CONNECT_DOMAIN_FAILED,
          source: getAppSource(),
          ...entriMedusaAttrb,
        });
      }
      errorCb(error);
      this.showToast(
        this.$t(
          "billingAndUpgrade.addonPurchase.accountSelectSection.commonError"
        )
      );
      store.dispatch("domainConnectData", {
        ...store.getters.domainConnectData,
        DomainNotFound,
        LookupTimeout,
      });
    });
}

export const getBllFormattedError = (code) => ({
  data: {
    code,
  },
});

// reference - https://stackoverflow.com/a/49201872
export function dateDiff(startingDate, endingDate, converYearToMonths = true) {
  var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
  if (!endingDate) {
    endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
  }
  var endDate = new Date(endingDate);
  if (startDate > endDate) {
    var swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  var startYear = startDate.getFullYear();
  var february =
    (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
      ? 29
      : 28;
  var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  var yearDiff = endDate.getFullYear() - startYear;
  var monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  if (converYearToMonths && yearDiff) {
    monthDiff += yearDiff * 12;
    yearDiff = 0;
  }

  var finalDateString = "";
  if (yearDiff) {
    finalDateString += `${yearDiff} ${yearDiff > 1 ? "Year" : "Years"}`;
  }

  if (monthDiff) {
    finalDateString += `${monthDiff} ${monthDiff > 1 ? "Month" : "Months"}`;
  }

  if (dayDiff) {
    finalDateString += `${dayDiff} ${dayDiff > 1 ? "Day" : "Days"}`;
  }

  return {
    finalDateString,
    yearDiff,
    monthDiff,
    dayDiff,
  };
}

/**
 * function to cal number of days between two dates
 * @param {*} startDate
 * @param {*} endDate
 * @reference - https://www.geeksforgeeks.org/how-to-calculate-the-number-of-days-between-two-dates-in-javascript/
 */
export function dayDiff(endDate, startDate) {
  const date1 = startDate ? new Date(startDate) : new Date();
  const date2 = new Date(endDate);
  const diffTime = date2.getTime() - date1.getTime(); // to get -ve diff
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

// reference - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
export function formatDate(date, opt) {
  var options = opt || { year: "numeric", month: "short", day: "numeric" };
  return (date ? new Date(date) : new Date()).toLocaleDateString(
    "en-US",
    options
  );
}

export function toCapitalise(str, shouldLowerCase) {
  if (str) {
    return (shouldLowerCase ? str.toLowerCase() : str).replace(
      /^./,
      str[0].toUpperCase()
    );
  } else {
    return "";
  }
}

export function getTime() {
  const locale = document.documentElement.getAttribute("lang");
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options = {
    timeZone,
    timeZoneName: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const dateTime = new Intl.DateTimeFormat(locale, options).format(new Date());
  return dateTime;
}

class Validation {
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}

export const validation = new Validation();

export function setSafeValues(data, arr) {
  var x = arr[0];
  if (typeof data !== "object") {
    return data;
  }
  if (typeof data[x] === "undefined") {
    return undefined;
  } else {
    arr.shift();
    data = data[x];
    return setSafeValues(data, arr);
  }
}

export function decode(str) {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec);
  });
}

export function proceedForAutoLogin(data) {
  if (data) {
    smartStorage.authToken = setSafeValues(data, ["token"]);
    smartStorage.partnerId = setSafeValues(data, ["partnerId"]);
    smartStorage.domain = setSafeValues(data, ["primaryDomain"]);
    const showAltUserEmail = data.adminType === "customer" && !data.userEmail;
    if (showAltUserEmail) {
      smartStorage.showAltUserEmail = showAltUserEmail;
    } else {
      smartStorage.tempUserAltEmail = data.userEmail;
    }
  }
}

/**
 * helper to make sure String(URL) has slash at end.
 * as per new BLL URL scheme there could be slash at end
 * helper make sure URL's are consistent across
 * @param {String} url
 * @returns {String}
 */
export function withSlash(url) {
  if (!url.toString().endsWith("/")) {
    return `${url}/`;
  }

  return url;
}

export const billingCycleMap = {
  monthly: 1,
  quaterly: 3,
  biannual: 6,
  yearly: 12,
  two_yearly: 24,
  three_yearly: 36,
  four_yearly: 48,
  semesterly: 6,
};

/**
 * function to check whether num has decimal or not
 * @param {string|integer} num
 * @returns boolean
 */
export function hasDecimal(num) {
  return num % 1 != 0;
}

export function scaleUpToTwoDecimals(num) {
  // This function returns minimum of 2 decimal places for any number.
  // in Other words , if we have 5 digits after decimal, the number will remain as it is
  // whereas if we have 1 or 0 digits after decimal, it will append two digits after decimal.
  // I/P : 13.1 => O/P :  13.10
  // I/P : 13.1234 => O/P :  13.1234
  // I/P : 1 => O/P : 1
  if (!num && num !== 0) return "0";
  if (typeof num !== "string") num = num.toString();
  return hasDecimal(num)
    ? Number(num).toFixed(Math.max((num.split(".")[1] || "").length, 2))
    : Number(num).toFixed(2);
}

export function setViewAndTheme(
  queryParamView,
  metaView,
  queryParamTheme,
  metaTheme
) {
  let root = document.documentElement;
  const viewAttr = root.getAttribute("view");

  // TODO: Find a better way to do this. Need refactorization for that
  const view =
    queryParamView ||
    metaView ||
    viewAttr ||
    (isMobile() ? "mobile" : "defaultView");

  const theme =
    queryParamTheme ||
    metaTheme ||
    document.documentElement.getAttribute("theme") ||
    "defaultTheme";
  root.setAttribute("view", view);
  root.setAttribute("theme", theme);
  return {
    view,
    theme,
  };
}

export function isMobile() {
  let check = false;
  // return false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function getFontForPartner(partner) {
  partner = partner.replace(/\W/g, "");
  const currentFontTypesURL = partnerWiseFontFamily[partner];
  currentFontTypesURL?.length &&
    currentFontTypesURL.forEach((fontURL) => {
      let link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = fontURL;
      document.head.appendChild(link);
    });
}

export function isAppLoadedInsideIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const isNeoControlPanel = NEO_HOST_NAMES.includes(
  window.location.hostname
);

export function shouldHideHelpIconForPartner() {
  return smartStorage.getItem("theme") === "wordpress";
}

export function getRandomId(type) {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${type}-${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export function getWebmailUrl(email = "") {
  const partnerWebmail = store.getters.partnerInfo?.urls?.webmail;
  const webmailUrl =
    partnerWebmail ||
    (isNeoBrand()
      ? process.env.VUE_APP_NEO_WEBMAIL_URL
      : process.env.VUE_APP_TITAN_WEBMAIL_URL);
  return getUrl(webmailUrl, {
    imap_host:
      smartStorage.getItem("imapHost") || process.env.VUE_APP_IMAP_HOST,
    clearSession: true,
    email_account: email,
  });
}

export function openWebmail(email) {
  window.open(getWebmailUrl(email));
}

export function handleRedirectionOnPartnerSide(jwt) {
  const {
    action,
    titanOrderId,
    domainName,
    customerId,
    planType,
    noOfAccounts,
    expiryDate,
  } = getJWTpayloadData(jwt);
  sendPostMessageToParentWindow(PAYMENT_ACTION, {
    action,
    titanOrderId,
    domainName,
    customerId,
    planType,
    noOfAccounts,
    expiryDate,
  });
}

/**
 * Sends out a post message to parent window with the given data
 * @param {String} event - The event name
 * @param {Object} data - A JS Object with the information for the parent.
 */
export function sendPostMessageToParentWindow(event, data) {
  window.parent?.postMessage(
    {
      event,
      data,
    },
    "*" //or "parentPageURL"
  );
}

function toggleCopyText(coptBtn) {
  const { view = {} } = store.getters;
  if (view.showModalAsPage) {
    const prevCopyText = coptBtn.textContent;
    const copiedMsg = this.$t("utility.copied");
    coptBtn.textContent = copiedMsg;
    setTimeout(() => {
      coptBtn.textContent = prevCopyText;
    }, 3000);
  }
}
/**
 * Copies data to clipboard
 * @param {String} id - id of the corresponding element for this data needs to be copied
 * */

export function copyToClipboard(id, cb = () => {}) {
  const target = document.getElementById(id);
  const copyBtn = document.querySelector(`#${id} + span`);
  let { showToast } = toast.methods;
  showToast = showToast.bind(this);
  toggleCopyText = toggleCopyText.bind(this);
  const copied = this.$t("utility.copiedToClipboard");
  const notCopied = this.$t("utility.unableCopy");
  let input = document.createElement("input");
  document.body.appendChild(input);
  input.value = target.textContent.trim();
  input.select();
  try {
    let a = document.execCommand("copy");
    showToast(copied);
    cb();
    document.body.removeChild(input);
    toggleCopyText(copyBtn);
  } catch {
    navigator.clipboard
      .writeText(target.textContent.trim())
      .then(function () {
        showToast(copied);
        cb();
        toggleCopyText(copyBtn);
      })
      .catch(function () {
        showToast(notCopied);
      });
  }
}

export function isNeoBusinessDomain() {
  const isNeoDomain = NEO_BUSINESS_DOMAINS.some((neoDomain) => {
    const regex = new RegExp(`${neoDomain}`, "gi");
    return regex.test(store.getters.domainName);
  });
  return isNeoDomain;
}

export function isNeoBrand() {
  const partnerId = store.getters.partnerId;
  return partnerId
    ? partnerId == NEO_PARTNER_ID_NUMBER[process.env.VUE_APP_ENV_FLAG]
    : location.hostname?.split(".").indexOf("neo") !== -1;
}

export function isNeoCoSiteDomain() {
  return isNeoBrand() && isNeoBusinessDomain();
}

export function isNeoCustomDomain() {
  return isNeoBrand() && !isNeoBusinessDomain();
}

export const getControlPanelSource = () => {
  return isNeoControlPanel ? "NeoApplication" : "ControlPanel";
};

export function isDomainOwnershipVerified() {
  const lookup = store.getters.domainConnectData;
  const { domainOwnershipVerified } = lookup;
  if (!isNeoBrand()) return true;
  return !!domainOwnershipVerified;
}

export function isDomainSpfVerified() {
  const { spfVerified } = store.getters.domainConnectData;
  return spfVerified;
}

export function isDomainDkimVerified() {
  const { dkimVerified } = store.getters.domainConnectData;
  return dkimVerified;
}

// method to return corresponding error type for email rep
// keeping this function as we might need to handle more cases in future
export function getEmailReputationErrorType() {
  const isDkimVerified = isDomainDkimVerified();
  return !isDkimVerified ? "onlyDkim" : "";
}

export function isEmailReputationGood() {
  return isDomainDkimVerified();
}

export function isDomainMXRecordVerified() {
  const { mxVerified } = store.getters.domainConnectData;
  return mxVerified;
}

export function isSiteARecordVerified() {
  const { aVerified } = store.getters.domainConnectData;
  return aVerified;
}

export function isSiteCNameRecordVerified() {
  const { cNameVerified } = store.getters.domainConnectData;
  return cNameVerified;
}

export function isSiteVerifiedAndConnected() {
  return isSiteARecordVerified() && isSiteCNameRecordVerified();
}

export function isDomainVerifiedAndConnected() {
  return (
    isDomainOwnershipVerified() &&
    isDomainMXRecordVerified() &&
    isDomainSpfVerified()
  );
}

export function getDnsRecordStatus(checking, isVerified, isUnknown) {
  if (checking) return "checking";
  if (isUnknown) return "unknown";
  if (isVerified) return "verified";
  return "unverified";
}

/**
 * this function stores login related info in smartStorage
 * @param {object} - login data
 */
export function storeLoginData(data) {
  const {
    token,
    partnerId,
    actor: { role, attrs = {}, id },
    bllUserId,
    primaryDomain,
    userEmail,
    expiryEpochTimestamp,
    adminType,
    isDomainAdmin,
  } = data;

  Object.entries({
    authToken: token,
    partnerId,
    role,
    bllUserId,
    domain: primaryDomain,
    tempUserAltEmail: userEmail,
    altEmail: userEmail,
    authTokenExpiry: expiryEpochTimestamp,
    adminType,
    isDomainAdmin,
    userName: attrs.userName,
    accountId: id,
  }).forEach(([key, value]) => {
    value !== undefined && smartStorage.setItem(key, value);
  });
  store.dispatch("showDomainAdminPopup", isDomainAdmin);
}

export function getUrl(_url, params = {}) {
  let url = new URL(_url);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  return url?.toString();
}

export function isEmptyObject(obj) {
  return !obj || !Object.keys(obj).length;
}

/**
 * function to sort the billing cycle by its price
 * @param {*} billingCycles { monthly: 2, yearly: 10 }
 * @param {*} orderBy
 * @returns [{ billingCycle: 'monthly', price: 2 }]
 */
export const orderBillingCycles = (billingCycles, orderBy = 1) => {
  return Object.entries(billingCycles)
    .sort(([aK, aV], [bK, bV]) => (aV > bV ? orderBy : orderBy * -1))
    .map(([aK, aV]) => ({ billingCycle: aK, price: aV }));
};

/**
 * Checks if the given domain name starts with 'http://', 'https://', or 'www.'.
 *
 * @param {string} domainName - The domain name to check.
 * @returns {boolean} Returns true if the domain name starts with 'http://', 'https://', or 'www.'; otherwise false.
 *
 * @example
 * // returns true
 * hasProtocolOrWwwSubdomain('https://example.com');
 *
 * @example
 * // returns false
 * hasProtocolOrWwwSubdomain('example.com');
 */
export const hasProtocolOrWwwSubdomain = (domainName) => {
  const pattern = /^(http:\/\/|https:\/\/|www\.)/;
  return pattern.test(domainName);
};

// function to conv time (h, m, s) to millisec
export const getMilliSeconds = (h = 0, m = 0, s = 0) =>
  (h * 60 * 60 + m * 60 + s) * 1000;

/**
 *
 * @param {array} domainFlags - The domain Flags for current plan.
 * @returns {boolean} Returns true if current plan has subscription cancelled key includes in array; otherwise false
 */
export const isCancelSubFlagPresent = (domainFlags) => {
  return domainFlags?.includes(SUBSCRIPTION_KEYS.CANCEL_SUBSCRIPTION);
};

/**
 *
 * @param {array} domainFlags - The domain Flags for current plan.
 * @returns {boolean} Returns true if current plan has expiry extension key includes i array, otherwise false
 */
export const isExpiryExtensionSubFlagPresent = (domainFlags) => {
  return domainFlags?.includes(SUBSCRIPTION_KEYS.EXPIRY_EXTENSION);
};

/**
 *
 * @param {object} expiryDate - Domain details.
 * @returns {key} Returns key 'firstBillingCyclePrice' for discounted and 'price' for non-discounted
 */
export const planPricingKey = (params) => {
  const {
    isTrial,
    firstBillingCycleUntil,
    sourceHook,
    trialUntil,
    expiresOn,
  } = params;

  if (isTrial) {
    return BILLING_PRICE.FIRST_BILLING_CYCLE_PRICE;
  }

  const currentDate = +new Date();
  const firstBillingCycleUntilDate = +new Date(firstBillingCycleUntil);
  const trialUntilDate = +new Date(trialUntil);
  const expiresOnDate = +new Date(expiresOn);

  if (trialUntilDate && trialUntilDate === expiresOnDate) {
    // This condition will execute only for custom domain not for co.site domain as we don't have trial for co.site domain
    return BILLING_PRICE.FIRST_BILLING_CYCLE_PRICE;
  }

  if (
    firstBillingCycleUntilDate &&
    currentDate < firstBillingCycleUntilDate &&
    !BILLING_CYCLE_SOURCES_LIST.includes(sourceHook)
  ) {
    // This will execute for both type of domain, custom and co.site
    return BILLING_PRICE.FIRST_BILLING_CYCLE_PRICE;
  }

  return BILLING_PRICE.PRICE;
};

/**
 *
 * @param {date} expiresOn - The expiry date for current plan.
 * @returns {boolean} Returns true if the Difference between Expiry date and Current date is less than or equal to 30days else false
 */
export const planCanBeRenewed = (expiresOn) => {
  if (!isNeoBrand()) {
    return true;
  }
  const currentDate = +new Date();
  const expiresOnDate = +new Date(expiresOn);
  const noOfDays = Math.floor(
    Math.abs(expiresOnDate - currentDate) / (1000 * 60 * 60 * 24)
  );

  return noOfDays <= 30;
};

export const isDevMode = () => {
  return (
    process.env.VUE_APP_ENV_FLAG === "dev" ||
    window.location.hostname.includes("localhost")
  );
};

export const hasForgoPayments = () => {
  return store.getters.partnerInfo?.flags?.forgoPayments;
};

export const canShowRenewOption = () => {
  return (
    !hasForgoPayments() &&
    planCanBeRenewed(store.getters.currentPlanDetails?.expiresOn)
  );
};

// TODO: Make a separate storage for short-lived query params
export const shouldShowResponsiveCP = () => {
  const queryParamKey = "sourceClient";
  const queryParamValue = queryParamStore.get(queryParamKey);

  const { IOS, ANDROID } = CP_SOURCES_MAP;

  let shouldShow = true;

  if (isMobile()) {
    if (!isNeoControlPanel) {
      shouldShow = false;
    }

    switch (queryParamValue) {
      case IOS:
      case ANDROID:
        shouldShow = false;
        break;

      default:
        break;
    }
  }
  return shouldShow;
};

export * from "./currency";
export * from "./discount";
export * from "./theme";
export { getBLLBaseURL } from "./http";
export { default as smartStorage, clearStorage } from "./smart-storage";
export { default as medusa } from "./medusa";
export { default as EventBus } from "./event-bus";
export { default as ViewTypes } from "../views";
export * from "./url";
export * from "./addons";
export * from "./iframeEvents";
export * from "./mathUtils";
export * from "./auth";
export * from "./dnsRecordsHelpers";
