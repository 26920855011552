<template>
  <div>
    <div class="error-validation" v-if="dnsLookupTimeout || domainNotFound">
      <i />{{
        $t(
          `bannerText.${
            dnsLookupTimeout ? "dnsTimeout" : "DomainNotFound"
          }.desc`
        )
      }}
      <TitanButton
        small
        type="transparent"
        class="active-now"
        @click="
          $router.push({
            path: '/domain-verification',
            query: {
              source_hook: 'dns_zone_inactive_banner',
            },
          })
        "
        >{{ $t("importEmail.details.learnMore") }}</TitanButton
      >
    </div>
    <div
      class="error-validation"
      v-else-if="domainNotVerifiedOrNotConnected"
      :class="{ bannerHeight: bannerLoading }"
    >
      <div v-if="bannerLoading" class="shimmer animate" />
      <div v-else class="banner-wrapper">
        <i /><span
          v-html="
            $t('bannerText.domainActivate.strongText', [
              smartStorage.getItem('domain'),
              brandDetails.name,
            ])
          "
        />
        <div v-if="isNeoCustomDomain && entriSetupPossible">
          <button @click="emitAutomaticSetup" class="connect-automatically-btn">
            {{ $t("bannerText.domainActivate.connectAutomatically") }}
          </button>
          <span>{{ $t("bannerText.domainActivate.oR") }}</span>
          <router-link
            to="/domain-verification?source_hook=control_panel_dns_setup_banner"
            @click.native="emitManualSetup"
            class="connect-manual-btn"
            >{{ $t("bannerText.domainActivate.connectManually") }}
          </router-link>
        </div>
        <router-link
          v-else
          class="button button__line-blue active-now"
          to="/domain-verification?source_hook=control_panel_dns_setup_banner"
          @click.native="emitManualSetup"
          >{{ $t("bannerText.domainActivate.btnText") }}
        </router-link>
        <!-- Not needed as per product requirement
          <div class="change-domain" v-if="domainOwnershipNotVerified">
          <span class="separator"></span>
          <span>
            {{ $t("bannerText.changeDomain.strongText") }}
            <a href="javascript:void(0)" @click="handleChangeDomainClick">{{
              $t("bannerText.changeDomain.linkText")
            }}</a>
          </span>
        </div> -->
      </div>
    </div>
    <div
      :class="['error-validation', { secondary: onlyDkimVerificationPending }]"
      v-else-if="poorEmailReputation"
    >
      <i /><span>{{
        $t("emailReputation.statusBar.error", [
          $t(`emailReputation.statusBar.errorType.${emailReputationErrorType}`),
        ])
      }}</span>
      <titan-button
        :className="'transparent ml-2'"
        @click="handleEmailReputationBannerClick"
      >
        <router-link to="/email-reputation" class="no-decoration"
          >{{ $t("bannerText.emailReputation.btnText") }}
        </router-link></titan-button
      >
    </div>
    <div class="error-validation" v-else-if="nsNotVerified">
      <i />{{ $t("bannerText.nsNotFound.desc", [brandDetails.name]) }}
    </div>
  </div>
</template>
<script>
import DomainEditModal from "@/components/DomainActivation/DomainEditModal/DomainEditModal.vue";
import {
  calculateDateDifference,
  isNeoBrand,
  isNeoBusinessDomain,
  isNeoControlPanel,
  isDomainDkimVerified,
  getEmailReputationErrorType,
  getAppSource,
} from "@/helpers";
import eventBus from "@/helpers/event-bus";
import { MEDUSA_EVENTS_MAP } from "@/helpers/events";
import { mapGetters } from "vuex";
import {
  getCommonMedusaParams,
  sendOrderAndCustomerEvent,
} from "@/helpers/medusaUtils";
import { COMMON_MODAL_EMITTERS, ENTRI_SETUP_MODE } from "@/helpers/const";
import { ENTRI_SETUP_TYPE } from "@/helpers/entriConfig";

const BANNER_TYPES = {
  dnsLookupTimeout: "dnsLookupTimeout",
  domainNotFound: "domainNotFound",
  domainNotVerifiedOrNotConnected: "domainNotVerifiedOrNotConnected",
  poorEmailReputation: "poorEmailReputation",
  nsNotVerified: "nsNotVerified",
};

export default {
  data() {
    return {
      isNeoCustomDomain:
        (isNeoBrand() || isNeoControlPanel) && !isNeoBusinessDomain(),
      AUTOMATIC: ENTRI_SETUP_TYPE.AUTOMATIC,
      MANUAL: ENTRI_SETUP_TYPE.MANUAL,
    };
  },
  watch: {
    triggerEntriDnsSetupBannerShown(status) {
      if (status) {
        const entriMedusaAttrb = {
          dns_setup_for: "Email",
          auto_domain_setup_supported: this.entriSetupPossible,
        };
        sendOrderAndCustomerEvent.call(this, {
          eventName: MEDUSA_EVENTS_MAP.CONNECT_DOMAIN_BANNER_VIEWED,
          source_hook: "control_panel_banner",
          source: getAppSource(),
          ...entriMedusaAttrb,
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "domainConnectData",
      "brandDetails",
      "currentPlanDetails",
      "entriSetupPossible",
    ]),
    triggerEntriDnsSetupBannerShown() {
      const hasDnsError = this.dnsLookupTimeout || this.domainNotFound;
      return (
        !hasDnsError &&
        !this.bannerLoading &&
        this.domainNotVerifiedOrNotConnected
      );
    },
    bannerPriority() {
      // Lower Index => Higher Priority
      return {
        [BANNER_TYPES.dnsLookupTimeout]: 1,
        [BANNER_TYPES.domainNotFound]: 2,
        [BANNER_TYPES.domainNotVerifiedOrNotConnected]: 3,
        [BANNER_TYPES.poorEmailReputation]: 4,
        [BANNER_TYPES.nsNotVerified]: 5,
      };
    },
    emailReputationErrorType() {
      return getEmailReputationErrorType();
    },
    onlyDkimVerificationPending() {
      return !isDomainDkimVerified();
    },
  },
  props: {
    domainNotFound: {
      type: Boolean,
    },
    nsNotVerified: {
      type: Boolean,
    },
    domainNotVerifiedOrNotConnected: {
      type: Boolean,
    },
    dnsLookupTimeout: {
      type: Boolean,
    },
    poorEmailReputation: {
      type: Boolean,
    },
    domainOwnershipNotVerified: {
      type: Boolean,
    },
    bannerLoading: { type: Boolean },
    getDnsAndEmailMedusaAttrs: {
      type: Function,
    },
  },
  methods: {
    emitAutomaticSetup() {
      eventBus.$emit(
        "handleEntriSetupRedirection",
        ENTRI_SETUP_TYPE.AUTOMATIC,
        ENTRI_SETUP_MODE.EMAIL
      );
      this.sendDomainBannerClicked(
        ENTRI_SETUP_TYPE.AUTOMATIC,
        ENTRI_SETUP_MODE.EMAIL
      );
    },
    emitManualSetup() {
      eventBus.$emit(
        "handleEntriSetupRedirection",
        ENTRI_SETUP_TYPE.MANUAL,
        ENTRI_SETUP_MODE.EMAIL
      );
      this.sendDomainBannerClicked(
        ENTRI_SETUP_TYPE.MANUAL,
        ENTRI_SETUP_MODE.EMAIL
      );
    },
    sendDomainBannerClicked(setupType, setupFor) {
      const commonMedusaAttribute = {
        auto_domain_setup_supported: this.entriSetupPossible,
        connection_method: setupType,
      };

      sendOrderAndCustomerEvent.call(this, {
        eventName: MEDUSA_EVENTS_MAP.CONNECT_DOMAIN_BANNER_CLICKED,
        ...this.getDnsAndEmailMedusaAttrs(),
        ...commonMedusaAttribute,
        dns_setup_for: setupFor,
      });
    },
    isAnyHigherPriorityBanner(currBannerName) {
      const currBannerPriorityNumber = this.bannerPriority[currBannerName];
      return Object.entries(this.bannerPriority).some(
        ([bannerName, bannerPriority]) => {
          return this[bannerName] && currBannerPriorityNumber > bannerPriority;
        }
      );
    },
    handleEmailReputationBannerClick() {
      this.medusaEvents.trackEvent({
        ...getCommonMedusaParams(),
        eventName: MEDUSA_EVENTS_MAP.EMAIL_REPUTATION_BANNER_CLICKED,
      });
    },
    handleChangeDomainClick() {
      const sourceHook = "Control panel banner";

      const {
        autoRenew,
        expiresOn,
        isTrial,
        noOfAccounts,
      } = this.currentPlanDetails;

      let payload = {
        auto_renew_enabled: autoRenew,
        eventName: MEDUSA_EVENTS_MAP.CHANGE_DOMAIN_CLICKED,
        mailbox_count: noOfAccounts.active,
        source_hook: sourceHook,
      };

      if (isTrial) {
        const freeTrialDaysRemaining = calculateDateDifference(expiresOn);
        payload = {
          ...payload,
          free_trial_days_left: freeTrialDaysRemaining,
        };
      }

      sendOrderAndCustomerEvent.call(this, payload);

      const { OPEN_MODAL } = COMMON_MODAL_EMITTERS;
      eventBus.$emit(OPEN_MODAL, {
        component: DomainEditModal,
        props: {
          sourceHook,
        },
      });
    },
  },
  mounted() {
    if (!this.isAnyHigherPriorityBanner(BANNER_TYPES.poorEmailReputation)) {
      this.medusaEvents.trackEvent({
        ...getCommonMedusaParams(),
        eventName: MEDUSA_EVENTS_MAP.EMAIL_REPUTATION_BANNER_SHOWN,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.separator {
  height: 30px;
  border-left: 1px solid var(--primaryTextCol);
}

.change-domain {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 16px;

  a {
    font-weight: 600;
  }
}

.banner-wrapper {
  display: flex;
  align-items: center;

  .connect-automatically-btn {
    margin: 0px 12px;
    font-weight: 600;
    color: var(--primaryBtnCol);
    border: 1px solid var(--primaryBtnCol);
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .connect-manual-btn {
    font-weight: 600;
    margin-left: 12px;
  }
}

.shimmer {
  height: 16px;
  background: linear-gradient(
    90deg,
    #ffaca9 2.76%,
    rgba(255, 245, 245, 0.59) 35.74%,
    #ffc1be 82.91%
  );
  width: 60%;
  border-radius: 20px;
}
.animate {
  animation: shimmer 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(
    90deg,
    #ffaca9 2.76%,
    rgba(255, 245, 245, 0.59) 35.74%,
    #ffc1be 82.91%
  );
  background-size: 1000px 100%;
}
.bannerHeight {
  height: 50px;
}
</style>
