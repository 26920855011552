/**
 * This list should contain all events (internal and external ) across titan control panel
 */

// Medusa Events ( External)

export const MEDUSA_EVENTS_MAP = {
  MX_BANNER_CLICKED: "mx_banner_clicked",
  DOMAIN_VERIFICATION_VERIFYDNS_CLICKED:
    "domain_verification_verifydns_clicked",
  DOMAIN_VERIFICATION_VISITED: "domain_verification_visited",
  CONNECT_DOMAIN_BANNER_VIEWED: "connect_domain_banner_viewed",
  CONNECT_DOMAIN_BANNER_CLICKED: "connect_domain_banner_clicked",
  /**
   *  When the user sees the domain setup banner on the control panel.
   */
  CONNECT_DOMAIN_VIEWED: "connect_domain_viewed",
  /**
   *  When the user clicks on connect domain btn in dns site setup flow
   */
  CONNECT_DOMAIN_AUTOMATICALLY_CLICKED: "connect_domain_automatically_clicked",
  /**
   * When the user visits the new connect domain tab
   */
  DOMAIN_OWNERSHIP_VERIFICATION_VIEWED: "domain_ownership_verification_viewed",
  /**
   *  "Protect domain" section visited by user within the Connect Domain tab
   */
  MX_VERIFICATION_VIEWED: "mx_verification_viewed",
  /**
   * Verify MX section visited by user within the Connect Domain tab
   */
  CONNECT_DOMAIN_STATUS_CHECKED: "connect_domain_status_checked",
  /**
   * When user clicks on Retry verification button in any of the domain connection steps. Values are those BEFORE verification response is received.
   */
  CONNECT_DOMAIN_FAILED: "connect_domain_failed",
  /**
   * If all verifications [Domain ownership, MX, SPF] were not completed.
   */
  CONNECT_DOMAIN_COMPLETED: "connect_domain_completed",
  /**
   * when the domain admin finishes the domain connect flow successfully
   */
  CONNECT_DOMAIN_PROVIDER_CLICKED: "connect_domain_provider_clicked",
  /**
   * Support article link clicked in any setup page [Domain Ownership or MX]
   */
  SUPPORT_CHAT_CLICKED: "support_chat_clicked",
  /**
   * Chat was initiated with Support by clicking the "Chat with Us" icon.
   */
  DOMAIN_OWNERSHIP_VERIFICATION_REMINDER_SENT:
    "domain_ownership_verification_reminder_sent",
  /**
   * When order is auto cancelled after 14 days if user does not verify the ownership.
   */
  DOMAIN_OWNERSHIP_VERIFICATION_EXPIRED:
    "domain_ownership_verification_expired",
  /**
   * domain_ownership_verification_expired
   */
  AUTO_RENEW_CHANGED: "auto_renew_changed",
  /**
   * This value is the state of auto renew AFTER action
   */
  NEO_SITE_HOOK_CLICKED: "neo_site_hook_clicked",
  /**
   *  Neo Site tab clicked
   */
  PURCHASE_DETAILS_ENTERED: "purchase_details_entered",
  /**
   * When the user mentions details of the purchase in the control panel buying flows such as number of accounts
   */
  ORDER_SUMMARY_VIEWED: "order_summary_viewed",
  // when use click on proceed to payment button
  PROCEED_PAYMENT_CLICKED: "proceed_payment_clicked",
  // pay now clicked
  PAY_NOW_CLICKED: "pay_now_clicked",
  /**
   * When the user sees order summary and value
   */
  BILLING_DETAILS_ENTERED: "billing_details_entered",
  /**
   * When user enters the billing details after seeing the order details in the billing flow
   */
  PAYMENT_CARD_SUBMITTED: "payment_card_submitted",
  /**
   * When user enters the payment card for the purchase in the billing flow
   */
  PAYMENT_CARD_VERIFIED: "payment_card_verified",
  /**
   * When user payment card is verified for the purchase in the billing flow
   */
  PAYMENT_CARD_VERIFICATION_FAILED: "payment_card_verification_failed",
  /**
   * When card verification fails for any reason
   */
  ADD_TEAM_MEMBER_VIEWED: "add_team_member_viewed",
  /**
   * Add mailbox section visited by user within the Connect Domain tab
   */
  PAYMENT_COMPLETED: "payment_completed",
  PAYMENT_FAILED: "payment_failed",
  PURCHASE_COMPLETED: "purchase_completed", // Duplicate of PAYMENT_COMPLETED, but since it is older events , we are keeping as it is
  EMAIL_REPUTATION_BANNER_SHOWN: "email_reputation_banner_shown",
  EMAIL_REPUTATION_BANNER_CLICKED: "email_reputation_banner_clicked",
  /**
   * Change Domain Events
   */
  CHANGE_DOMAIN_CLICKED: "change_domain_clicked",
  CHANGE_DOMAIN_AVAILABILITY_CHECKED: "change_domain_availability_checked",
  DOMAIN_AVAILABILITY_CHECKED: "domain_availability_checked",
  CHANGE_DOMAIN_REQUESTED: "change_domain_requested",

  /**
   * User manage card on billing and subscription page
   */
  UPDATE_CARD_CLICKED: "update_card_clicked",
  REMOVE_CARD_CLICKED: "remove_card_clicked",

  /**
   * Manage DNS
   */
  MANAGE_DNS_VIEWED: "manage_dns_viewed",

  /**
   * Billing and subscription page
   */
  CANCEL_SUBSCRIPTION_CLICKED: "cancel_subscription_clicked",
  REACTIVATE_SUBSCRIPTION_REQUESTED: "reactivate_subscription_requested",
  CANCEL_SUBSCRIPTION_REQUESTED: "cancel_subscription_requested",
  BILLING_CYCLE_UPDATED: "billing_cycle_updated",
  CANCEL_REASON_SELECTED: "cancel_reason_selected",
  CANCEL_REASON_DETAILS_ADDED: "cancel_reason_details_added",
  CANCEL_DISMISSED: "cancel_dismissed",
  PRIORITY_SUPPORT_CLICKED: "priority_support_clicked",
  BILLING_TAB_VIEWED: "billing_tab_viewed",
  CHANGE_BILLING_CYCLE_CLICKED: "change_billing_cycle_clicked",

  /**
   * Buy Email Accounts page
   */
  BUY_EMAIL_ACCOUNTS_TAB_VIEWED: "buy_accounts_tab_viewed",

  /**
   * Purchase Events
   */
  UPGRADE_STARTED: "upgrade_started",
  PURCHASE_STARTED: "purchase_started",

  /**
   * Manage dns
   */
  ADD_DNS_RECORD_STARTED: "add_dns_record_started",
  DNS_RECORD_ADDED: "dns_record_added",
  ADD_DNS_RECORD_ERROR_SHOWN: "add_dns_record_error_shown",
  DNS_RECORD_DELETED: "dns_record_deleted",

  /**
   * ENTRI events
   */
  ENTRI_DOMAIN_CHECKED: "entri_domain_checked",
};

// Event Bus events (Internal)

export const EVENT_BUS_INTERNAL_EVENTS = {
  DOMAIN_RECORDS_NOT_VERIFIED: "DOMAIN_RECORDS_NOT_VERIFIED",
  VERIFY_DNS_CLICKED: "VERIFY_DNS_CLICKED",
  DOMAIN_RECORDS_RECHECKED: "DOMAIN_RECORDS_RECHECKED",
  MX_AND_TXT_VERIFIED: "MX_AND_TXT_VERIFIED",
  A_AND_CNAME_VERIFIED: "A_AND_CNAME_VERIFIED",
  SITE_RECORDS_NOT_VERIFIED: "SITE_RECORDS_NOT_VERIFIED",
  VERIFY_SITE_RECORD_CLICKED: "VERIFY_SITE_RECORD_CLICKED",
  SITE_RECORDS_RECHECKED: "SITE_RECORDS_RECHECKED",
  DOMAIN_OWNERSHIP_VERIFIED: "DOMAIN_OWNERSHIP_VERIFIED",
  SPF_VERIFIED: "SPF_VERIFIED",
  DKIM_VERIFIED: "DKIM_VERIFIED",
};

export const SIDE_TAB_MEDUSA = {
  UPGRADE_TAB: "upgrade_tab",
  BILLING_AND_SUBSCRIPTION_TAB: "billing_subscription_tab",
  BUY_ACCOUNTS_TAB: "buy_accounts_tab",
};
