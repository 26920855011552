<template>
  <div class="plan-card-container">
    <div class="plan-card">
      <h6 class="plan-card-title">
        {{ currentDomainDetails.planDisplayName }}
      </h6>
      <span class="divider"></span>
      <ul class="item-list">
        <li>
          {{ $t("mobile.planCard.planDetails.mailBoxes") }}:
          {{ currentPlanDetails.noOfAccounts.limit }}
        </li>
        <li>
          {{ $t("purchaseFlow.billingSection.billingCycle") }}:
          {{ $t("billingCycles." + currentPlanDetails.billingCycle) }}
        </li>
        <li v-if="currentPlanDetails.autoRenew">
          {{ $t("mobile.planCard.planDetails.renewsOn") }}:
          {{ formatDate(currentPlanDetails.expiresOn) }}
        </li>
        <li v-else>
          {{ $t("mobile.planCard.planDetails.expiresOn") }}:
          {{ formatDate(currentPlanDetails.expiresOn) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "../../helpers";

export default {
  data() {
    return {
      formatDate,
    };
  },
  computed: {
    ...mapGetters(["currentPlanDetails", "currentDomainDetails"]),
  },
};
</script>
<style lang="scss" scoped>
/* TODO: replace with css variables */

.plan-card {
  padding: 12px 16px;
  border-radius: 4px;
  background-color: #474977;

  * {
    color: white;
  }

  h3 {
    margin-bottom: 12px;
  }
}

.divider {
  height: 1px;
  display: block;
  background-color: rgba(255, 255, 255, 0.3);
}

.item-list {
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0 0;
}
</style>
