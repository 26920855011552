<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        class="modal-body-container change-billin-cycle-modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="Add card details for renewal"
      >
        <button
          type="button"
          class="btn-close"
          @click="close(false)"
          aria-label="Close modal"
          :disabled="showButtonSpinner"
        >
          &#215;
        </button>
        <div class="modal-content-wrapper">
          <h4>
            {{ $t("billingAndUpgrade.changeBillingCycle.heading") }}
          </h4>
          <ul>
            <li>
              <span>{{
                $t("billingAndUpgrade.changeBillingCycle.currentCycle")
              }}</span>
              <span>{{ $t(`billingCycles.${currentPlan.billingCycle}`) }}</span>
            </li>
            <li>
              <span>{{
                $t("billingAndUpgrade.changeBillingCycle.billingCycle")
              }}</span>
              <span>
                <select
                  v-model="billingFrequency"
                  @change="onBillingCycleChange"
                >
                  <option
                    v-bind:key="index"
                    v-for="(cycle, index) in supportedBillingCycles"
                    :value="cycle"
                  >
                    {{ $t(`billingCycles.${cycle}`) }}
                  </option>
                </select>
              </span>
            </li>
            <li
              v-for="(billing, index) in billingDetails"
              v-show="billing.isPaid !== false || billing.isTrial"
              v-bind:key="index"
            >
              <!-- trial -->
              <span v-if="billing.isTrial">{{
                $t(`billingAndUpgrade.changeBillingCycle.title`, [
                  billing.paidPlan.title,
                ])
              }}</span>
              <div v-if="billing.isTrial">
                <span
                  v-html="
                    $t(`billingAndUpgrade.changeBillingCycle.price`, [
                      `${cr(
                        scaleUpToTwoDecimals(
                          billing.paidPlan?.planPricing?.[billingFrequency]?.[
                            priceKey
                          ] ?? billing?.paidPlan?.pricing?.[billingFrequency]
                        )
                      )}`,
                    ])
                  "
                />
                <p
                  v-if="!isBillingFrequencyMonthly"
                  v-html="
                    $t(translationKeyForPlanPricing, [
                      `${cr(
                        scaleUpToTwoDecimals(
                          calculatePlanPricingCharge(
                            billing?.paidPlan?.planPricing?.[
                              billingFrequency
                            ]?.[priceKey] ??
                              billing?.paidPlan?.pricing?.[billingFrequency],
                            noOfMonths
                          )
                        )
                      )}`,
                    ])
                  "
                  class="plan-price"
                />
              </div>
              <!-- non trial -->
              <span v-if="!billing.isTrial">{{
                $t(`billingAndUpgrade.changeBillingCycle.title`, [
                  billing.title,
                ])
              }}</span>
              <div v-if="!billing.isTrial">
                <span
                  v-html="
                    $t(`billingAndUpgrade.changeBillingCycle.price`, [
                      `${cr(
                        scaleUpToTwoDecimals(
                          billing?.planPricing?.[billingFrequency]?.[
                            priceKey
                          ] ?? billing?.pricing?.[billingFrequency]
                        )
                      )}`,
                    ])
                  "
                />
                <p
                  v-if="!isBillingFrequencyMonthly"
                  v-html="
                    $t(translationKeyForPlanPricing, [
                      `${cr(
                        scaleUpToTwoDecimals(
                          calculatePlanPricingCharge(
                            billing?.planPricing?.[billingFrequency]?.[
                              priceKey
                            ] ?? billing?.pricing?.[billingFrequency],
                            noOfMonths
                          )
                        )
                      )}`,
                    ])
                  "
                  class="plan-price"
                />
              </div>
            </li>
            <li v-if="isDomainPaid">
              <span> {{ billingData.domainDetails.name }}</span>
              <div>
                <span
                  v-html="
                    $t(`billingAndUpgrade.changeBillingCycle.domainPrice`, [
                      `${cr(
                        scaleUpToTwoDecimals(
                          billingData.domainPricing[
                            billingFrequency
                          ].renewalCharge.toFixed(2)
                        )
                      )}`,
                    ])
                  "
                />
                <p
                  v-if="!isBillingFrequencyMonthly"
                  v-html="
                    $t(translationKeyForDomainPricing, [
                      `${cr(
                        scaleUpToTwoDecimals(
                          calculatePlanPricingCharge(
                            billingData.domainPricing[billingFrequency]
                              .renewalCharge,
                            noOfMonths
                          )
                        )
                      )}`,
                    ])
                  "
                  class="plan-price"
                />
              </div>
            </li>
          </ul>
          <p
            v-html="
              $t('billingAndUpgrade.changeBillingCycle.description', [
                $t(`billingCycles.${billingFrequency}`),
                formatDate(currentPlan.expiresOn),
              ])
            "
          />
          <button
            @click="changeBillingCycle"
            :class="['button', { 'show-spinner': showButtonSpinner }]"
            :disabled="showButtonSpinner || !cycleChanged"
          >
            {{ $t("billingAndUpgrade.changeBillingCycle.save") }}
          </button>
          <button @click="close(false)" class="button button__line-blue">
            {{ $t("billingAndUpgrade.changeBillingCycle.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import * as helper from "../../helpers";
import { mixin as clickaway } from "vue-clickaway";
import { sendOrderAndCustomerEvent } from "@/helpers/medusaUtils";
import { MEDUSA_EVENTS_MAP, SIDE_TAB_MEDUSA } from "@/helpers/events";
import {
  BILLING_FREQUENCY,
  BILLING_CYCLE_SOURCES,
  BILLING_MONTHS,
  PLAN_BILLING_TRANSLATION_KEY_MAP,
  DOMAIN_BILLING_TRANSLATION_KEY_MAP,
} from "@/helpers/const";
import { isNeoBrand } from "@/helpers";
import { getSupportedBillingCyclesForNeo } from "@/helpers/neo.helper";
import { sortBillingCycles } from "@/helpers/billingHelpers";

export default {
  name: "ChangeBillingCycle",
  mixins: [clickaway],
  data() {
    const currentPlan = this.currentPlan;
    let supportedBillingCycles = [];
    this.billingDetails.forEach((b) => {
      if (b.isPaid || b.isTrial) {
        Object.keys(
          b.isPaid ? b.planPricing ?? b.pricing : b.paidPlan.pricing
        ).forEach((k) => {
          if (supportedBillingCycles.indexOf(k) === -1) {
            supportedBillingCycles.push(k);
          }
        });
      }
    });
    if (supportedBillingCycles.length === 0) {
      supportedBillingCycles.push("monthly", "yearly");
    }
    supportedBillingCycles = !isNeoBrand()
      ? supportedBillingCycles
      : getSupportedBillingCyclesForNeo(
          supportedBillingCycles,
          currentPlan.planDetails.type,
          currentPlan.billingCycle
        );
    supportedBillingCycles = sortBillingCycles(supportedBillingCycles);
    return {
      cr: helper.bindCurrencySymbol.bind(this),
      // currentPlan,
      cycleChanged: false,
      formatDate: helper.formatDate,
      toCapitalise: helper.toCapitalise,
      calculatePlanPricingCharge: helper.calculatePlanPricingCharge,
      showButtonSpinner: false,
      billingFrequency: currentPlan.renewalBillingCycle,
      supportedBillingCycles,
      isDomainPaid: this.$store.getters.isDomainPaid,
      billingData: this.$props.billingDetails[0],
    };
  },
  props: {
    addons: {
      type: Array,
      default: [],
    },
    currency: {
      type: String,
    },
    billingDetails: {
      type: Array,
    },
    currentPlan: {
      type: Object,
    },
  },
  computed: {
    isBillingFrequencyMonthly() {
      return this.billingFrequency === BILLING_FREQUENCY.MONTHLY;
    },
    priceKey() {
      const params = {
        isTrial: this.billingDetails[0].isTrial,
        firstBillingCycleUntil: this.currentPlan.firstBillingCycleUntil,
        sourceHook: BILLING_CYCLE_SOURCES.CHANGE_BILLING_CYCLE,
        trialUntil: this.currentPlan.trialUntil,
        expiresOn: this.currentPlan.expiresOn,
      };
      return helper.planPricingKey(params);
    },
    noOfMonths() {
      return BILLING_MONTHS[this.billingFrequency];
    },
    translationKeyForPlanPricing() {
      return `billingAndUpgrade.changeBillingCycle.${
        PLAN_BILLING_TRANSLATION_KEY_MAP[this.billingFrequency]
      }`;
    },
    translationKeyForDomainPricing() {
      return `billingAndUpgrade.changeBillingCycle.${
        DOMAIN_BILLING_TRANSLATION_KEY_MAP[this.billingFrequency]
      }`;
    },
  },
  methods: {
    scaleUpToTwoDecimals: helper.scaleUpToTwoDecimals,
    close(refetchCurrentPlan) {
      this.$emit("close", refetchCurrentPlan);
    },
    onBillingCycleChange() {
      this.cycleChanged = true;
    },
    handleAPIError(err) {
      this.showButtonSpinner = false;
      const message =
        (err && err.data && err.data.desc) ||
        this.$t(
          "billingAndUpgrade.addonPurchase.accountSelectSection.commonError"
        );
      this.showToast(message);
    },
    changeBillingCycle() {
      this.showButtonSpinner = true;
      this.$http
        .post(`/panel/billing/update-config`, {
          domainName: smartStorage.getItem("domain"),
          renewalBillingCycle: this.billingFrequency,
        })
        .then(() => {
          this.showButtonSpinner = false;
          this.close(true);
          this.showToast(
            this.$t(`billingAndUpgrade.changeBillingCycle.message`, [
              this.toCapitalise(
                this.$t(`billingCycles.${this.billingFrequency}`)
              ),
            ]),
            { position: "bottom-center" }
          );
          if (this.currentPlan.billingCycle !== this.billingFrequency) {
            sendOrderAndCustomerEvent.call(
              this,
              {
                eventName: MEDUSA_EVENTS_MAP.BILLING_CYCLE_UPDATED,
                billing_cycle: this.currentPlan.billingCycle,
                new_billing_cycle: this.billingFrequency,
                source: helper.getAppSource(),
                source_hook: SIDE_TAB_MEDUSA.BILLING_AND_SUBSCRIPTION_TAB,
              },
              false
            );
          }
        })
        .catch(this.handleAPIError);
    },
  },
};
</script>
<style lang="scss">
.change-billin-cycle-modal {
  text-align: left;
  color: var(--primaryTextCol);
  min-width: unset;
  margin: 16px;

  select {
    width: 107px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px var(--lineSepaCol);
    display: inline-block;
    outline: none;
  }

  ul {
    margin: 0;
    padding: 12px 0 6px;

    li {
      display: flex;
      padding: 12px 0;
      align-items: center;

      & + li {
        border-top: solid 1px var(--lineSepaCol);
      }

      span:first-child {
        flex-basis: 250px;
      }
    }
  }

  #card-element {
    width: 100%;
    padding: 10px 5px;
    border-radius: 4px;
    border: solid 1px var(--disableCol);
    background-color: var(--tabBgCol);
  }

  .error {
    height: 15px;
    display: block;
    margin: 0 0 9px;
  }

  h4 {
    font-size: 20px;
    font-weight: normal !important;
    line-height: normal;
    color: var(--primaryTextCol);
  }

  p {
    margin: 8px 10px 28px 0;
    color: var(--secondaryTextCol);
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    color: var(--primaryTextCol);
  }

  .modal-content-wrapper button {
    padding: 10px 12px;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.show-spinner {
      @include button-loader();
    }
  }

  .plan-price {
    margin: 0;
  }
}
@import "../../styles/variables.scss";
@media screen and (max-width: $responsive-breakpoint) {
  .change-billin-cycle-modal {
    padding: 16px;
    ul {
      li {
        justify-content: space-between;
        span:first-child {
          flex-basis: 160px;
        }
        div {
          text-align: right;
        }
      }
    }
  }
}
</style>
