<template>
  <div class="widgetParent">
    <div class="widgetHeader" v-if="flags.showIFrameHomeHeader">
      <span class="header">{{ $t("widgetApp.home.header") }}</span>
      <img src="../../assets/powered-by-titan-header.svg" />
    </div>
    <div class="widget-grid-container">
      <!-- check `themeImage` filter under src/global/middleware.js -->
      <div
        :class="`${route.imgFilename} sections`"
        v-for="route in allRoutes"
        :key="route.name"
        @click="handleClickOnHomeScreenTiles(route)"
      >
        <a>
          <img :src="route.imgFilename | themeImage" class="commonImgClass" />
          <div
            class="external-tab"
            v-if="
              route.isBillingRoute && !flags.disableIFramePaymentRedirection
            "
          ></div>
          <div class="widget-section-name">{{ $t(route.name) }}</div>
          <div class="widget-section-description">
            {{ $t(route.description) }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as helpers from "@/helpers";
import views from "../../views";
export default {
  name: "cpWidget",
  data: function () {
    return {
      theme: null,
    };
  },
  props: {
    titanMaxEnabled: Boolean,
    planType: String,
    partnerId: Number,
    canUpgradePlan: Boolean,
  },
  computed: {
    ...mapGetters(["flags"]),
    allRoutes: function () {
      const newRoutes = this.routes.filter((route) => route.visible);
      return newRoutes.filter((route) => !route.isModal);
    },
    routes() {
      return [
        {
          name: "menuItems.emailAccounts",
          to: "/email_accounts",
          imgFilename: "email-accounts",
          description: "menuItemsDescription.emailAccounts",
          visible: true,
        },
        {
          name: "menuItems.internalForwarding",
          to: "/internal-forward",
          imgFilename: "internal-forwarding",
          description: "menuItemsDescription.emailForwarders",
          visible: true,
        },
        {
          name: "menuItems.catchAllEmail",
          to: "/catch_all_email",
          imgFilename: "catch-all-email",
          description: "menuItemsDescription.catchAllEmail",
          visible: true,
        },
        {
          name: "menuItems.importEmail",
          to: "/import-email",
          imgFilename: "import-email",
          description: "menuItemsDescription.importEmail",
          visible: true,
        },
        {
          name: "menuItems.domainActivation",
          to: "/domain-verification",
          imgFilename: "domain-activation",
          description: "menuItemsDescription.domainActivation",
          visible: true,
        },
        {
          name: "menuItems.emailReputation",
          to: "/email-reputation",
          imgFilename: "email-reputation",
          description: "menuItemsDescription.emailReputation",
          visible: true,
        },
        {
          name: "menuItems.downloadFlockmail",
          to: "/device-download",
          imgFilename: "mobile",
          description: "menuItemsDescription.getMobileApp",
          visible: true,
        },
        {
          name: `menuItems.${
            helpers.isNeoBrand()
              ? "neoConfigurationInstruction"
              : "titanConfigurationInstruction"
          }`,
          to: "/configure-desktop",
          imgFilename: "desktop-icon",
          description: "menuItemsDescription.configureDesktopAndMobile",
          visible: true,
        },
        {
          action: "upgradePlan",
          isBillingRoute: true,
          name: "menuItems.billingAndUpgrade",
          to: "/billing-and-upgrade",
          imgFilename: "upgrade",
          description: "menuItemsDescription.billingAndUpgrade",
          visible: !(
            this.flags.hideIFrameUpgradeSection ||
            (this.flags.hideIFrameUpgradeSectionOnHighestPlan &&
              !this.canUpgradePlan)
          ),
        },
        {
          action: "buyMoreAccounts",
          isBillingRoute: true,
          name: "menuItems.buyEmailAccounts",
          to: "/buy-email-account",
          imgFilename: "buyMoreAccounts",
          description: "menuItemsDescription.buyEmailAccounts",
          visible: !(
            this.flags.hideIFrameBuyMoreAccountSection ||
            smartStorage.getItem("isPaid").toString() !== "true"
          ),
        },
        {
          name: "menuItems.titan_max",
          to: "/addons/titan_max",
          imgFilename: "titan-max",
          description: "menuItemsDescription.titanMax",
          visible: this.titanMaxEnabled,
        },
        {
          action: "billing",
          isBillingRoute: true,
          name: "menuItems.billingAndSubscription",
          to: "/billing-and-subscription",
          imgFilename: "billing-subscription",
          description: "menuItemsDescription.billingAndSubscription",
          visible: !this.flags.hideIFrameBillingSection,
        },
        {
          name: "menuItems.createEmailAccountModal",
          to: "createEmailAccountModal",
          isModal: true,
        },
        {
          name: "menuItems.catchAllEmailModal",
          to: "catchAllEmailModal",
          isModal: true,
        },
        {
          name: "menuItems.manageAdminRightsModal",
          to: "manageAdminRightsModal",
          isModal: true,
        },
        {
          name: "menuItems.manageAliasModal",
          to: "manageAliasModal",
          isModal: true,
        },
        {
          name: "menuItems.createInternalForwardingAccountModal",
          to: "createInternalForwardingAccountModal",
          isModal: true,
        },
        {
          name: "menuItems.manageUsersModal",
          to: "manageUsersModal",
          isModal: true,
        },
        {
          name: "menuItems.createNewEmailAccountModal",
          to: "createNewEmailAccountModal",
          isModal: true,
        },
        {
          name: "menuItems.resetPasswordModal",
          to: "resetPasswordModal",
          isModal: true,
        },
      ];
    },
  },
  created() {
    this.theme = document.documentElement.getAttribute("theme");
    views.widget["backButtonForNavigation"] = true;
    views.widget["showPoweredByTitan"] = true;
    this.$emit("pageLoaded", true);
  },
  methods: {
    handleClickOnHomeScreenTiles(route) {
      if (route.isBillingRoute) {
        this.handleBilling(route.action, route.to.slice(1));
      } else {
        this.$router.push({ path: `${route.to}` });
      }
    },
    handleBilling(action, section) {
      this.$http
        .post(`panel/payment/initPayment`, {
          action,
        })
        .then((data) => this.initiateTransaction(data, section))
        .catch(console.error);
    },
    initiateTransaction(data, section) {
      if (
        helpers.isAppLoadedInsideIframe() &&
        this.flags.disableIFramePaymentRedirection
      ) {
        helpers.handleRedirectionOnPartnerSide(data.jwt);
        return true;
      }
      if (data.makePaymentUrl !== "inAppPayment") {
        const cpURL = encodeURIComponent(
          `${window.location.origin}/postPayment`
        );
        const redirectURL = `${data.makePaymentUrl}?jwt=${data.jwt}&redirect_url=${cpURL}`;
        smartStorage.hostRedirectURL = redirectURL;
        helpers.hardRedirect(
          redirectURL,
          true,
          views.widget["openPaymentURLInDiffTab"]
        ); // skip true, external URL
      } else {
        const partnerId = smartStorage.getItem("partnerId");
        const redirectURL = `/partner/cpWidget?jwt=${data.jwt}&partnerId=${partnerId}&section=${section}`;
        helpers.hardRedirect(
          redirectURL,
          true,
          views.widget["openPaymentURLInDiffTab"]
        );
      }
    },
  },
};
</script>

<style lang="scss">
.widgetParent {
  .widgetHeader {
    display: flex;
    align-items: center;
    .header {
      font-size: 28px;
      display: inline-block;
      padding-right: 22px;
      border-right: 1px solid var(--lineSepaCol);
      margin-right: 17px;
    }
    img {
      width: 80px;
      height: 30px;
    }
  }
  .widgetHeader {
    padding-bottom: 40px;
    .title {
      font-size: 20px;
      font-weight: 600;
    }
    .powered-by {
      width: 92px;
      height: 35px;
      padding-left: 16px;
      margin-left: 16px;
      border-left: 2px solid var(--lineSepaCol);
    }
  }
  .widget-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: var(--widgetHomeTileGap);
    .sections {
      position: relative;
      cursor: pointer;
      height: 205px;
      padding: 15px 20px 28px 20px;
      background: var(--tabBgCol);
      border: 1px solid var(--lightBorderCol);
      overflow: ellipsis;
      a {
        color: var(--primaryTextCol);
        text-decoration: none;
        .widget-section-name {
          text-align: left;
          font-weight: 600;
          font-size: 16px;
          padding-top: 15px;
          color: var(--sectionTitleColor);
          word-break: break-all;
        }
        .widget-section-description {
          text-align: left;
          padding-top: 3px;
          color: var(--sectionDescriptionColor);
        }
      }
    }
    .commonImgClass {
      background-color: var(--tabBgCol);
    }
    :hover {
      background: var(--hoverTabBgCol);
      .commonImgClass {
        background-color: var(--hoverTabBgCol);
      }
    }
  }
}
.external-tab {
  mask: url("../../assets/externalTabArrow.svg");
  background: var(--iconColor);
  height: 16px;
  width: 16px;
  position: absolute;
  top: 9px;
  right: 20px;
}
</style>
