<template>
  <div>
    <Loader v-if="!hasFetched" />
    <div class="billingInfo" v-if="hasFetched">
      <div class="header">
        {{ $t("purchaseFlow.billingSection.header") }}
      </div>
      <div class="domain">
        <div class="domainNameKey">
          {{ $t("purchaseFlow.billingSection.domain") }}
        </div>
        <div class="domainNameValue">
          {{ purchaseFlowJWTPayload.domainName }}
        </div>
      </div>
      <div class="plan">
        <div class="planNameKey">
          {{ $t("purchaseFlow.billingSection.plan") }}
        </div>
        <div class="planNameValue">{{ currentPlan.displayName }} plan</div>
      </div>
      <div class="billingCycle">
        <div class="billingCycleKey">
          {{ $t("purchaseFlow.billingSection.billingCycle") }}
          <!-- code for discount amount, we might show this nudge in future -->
          <!-- <span
            v-if="showDiscountOnAnnualPrice"
            v-html="
              $t('purchaseFlow.billingSection.discountOnAnnualPlan', [
                this.discountOnAnnualPrice,
              ])
            "
          /> -->
        </div>
        <div class="billingCycleValue">
          <div
            v-for="(planCycle, index) in sortedBillingCycles"
            :key="index"
            v-show="planCycle != 'id'"
          >
            <input
              type="radio"
              :id="planCycle.billingCycle"
              name="planCycle"
              :value="planCycle.billingCycle"
              v-model="selectedPlanCycle"
              :checked="selectedPlanCycle === planCycle.billingCycle"
            />
            <label :for="planCycle.billingCycle">{{
              $t(`billingCycles.${planCycle.billingCycle}`)
            }}</label>
          </div>
        </div>
      </div>
      <div class="price">
        <div class="priceKey">
          {{ $t("purchaseFlow.billingSection.price") }}
        </div>
        <div
          class="priceValue"
          v-html="
            $t(`purchaseFlow.accountsPerMonth`, [
              cr +
                scaleUpToTwoDecimals(
                  currPlanCyclesAndCharges[selectedPlanCycle]
                ),
            ])
          "
        ></div>
      </div>
      <div class="noOfAcc">
        <div class="noOfAccKey">
          {{ $t("purchaseFlow.billingSection.no_of_acc") }}
        </div>
        <div class="noOfAccValue">
          <counter
            :minCount="1"
            :maxCount="9999"
            @onChange="updateNoOfAccounts"
            :initialValue="+noOfAccounts"
            ref="counter"
          />
        </div>
      </div>
      <div class="totalAmt">
        <div class="totalAmtKey">
          {{ $t("purchaseFlow.billingSection.totalAmount") }}
        </div>
        <div class="totalPayableAmount price-label">
          <b
            v-if="!isTrailPlan"
            v-html="cr + (totalAmount && scaleUpToTwoDecimals(totalAmount))"
          />
          <span v-else>
            <b v-html="cr + '0'" />
            <span
              class="text-line-through"
              v-html="cr + (totalAmount && scaleUpToTwoDecimals(totalAmount))"
            />
          </span>
        </div>
      </div>
      <button class="nextBtn" @click="goToBillingForm()">
        {{ $t("purchaseFlow.billingSection.next") }}
      </button>
    </div>
  </div>
</template>

<script>
import Counter from "../Utility/Counter";
import Loader from "../Utility/Loader";
import * as helper from "../../helpers";

export default {
  name: "CycleAndAccounts",
  components: {
    Counter,
    Loader,
  },
  watch: {
    selectedPlanCycle() {
      this.updateNoOfAccounts(this.noOfAccounts);
    },
  },
  props: {
    changeIndex: Function,
    purchaseAuthData: Object,
    purchaseFlowJWTPayload: Object,
    partnerId: String,
    setPurchasedPlanDetails: Function,
    purchasedPlanDetails: Object,
  },
  data() {
    const purchasedPlanDetails = this.purchasedPlanDetails || {};

    return {
      currentPlan: null,
      allPlanDetails: {},
      currPlanCyclesAndCharges: {},
      selectedPlanCycle: purchasedPlanDetails.billingCycle,
      totalAmount: purchasedPlanDetails.chargeableAmount,
      noOfAccounts: purchasedPlanDetails.noOfAccounts || 1,
      proceed: false,
      showLoader: false,
      hasFetched: false,
      currency: null,
      discountOnAnnualPrice: -1,
      cr: helper.bindCurrencySymbol.bind(this),
    };
  },
  created() {
    this.medusaEvents.send(
      this.medusaEvents.createEventObj(
        {
          eventName: "purchase_started",
          partner_id: this.purchaseAuthData.partnerInternalId,
          domain_name: this.purchaseFlowJWTPayload.domainName,
          customer_name: this.purchaseFlowJWTPayload.customerName,
          customer_email_address: this.purchaseFlowJWTPayload
            .customerEmailAddress,
          customer_country: this.purchaseFlowJWTPayload.customerCountry,
          Plan_selected: this.purchaseFlowJWTPayload.planType,
        },
        this.purchaseFlowJWTPayload.domainName,
        "flock_order"
      )
    );
    this.$root.headers = {
      headers: {
        Authorization:
          this.purchaseAuthData.params && this.purchaseAuthData.params.token,
      },
    };
    this.http.getData(
      "/panel/purchase/fetch-plans?includeTrialPlans=true",
      this.$root.headers,
      this.onFetchPlanSuccess,
      () => {
        this.hasFetched = true;
        this.$emit("handleAPIFailure", "cycleAndAccountsError");
      }
    );
  },
  computed: {
    isTrailPlan() {
      return this.currentPlan && this.currentPlan.isTrial;
    },
    // showDiscountOnAnnualPrice() {
    //   return this.discountOnAnnualPrice > 10;
    // },
  },
  methods: {
    scaleUpToTwoDecimals(num) {
      return helper.scaleUpToTwoDecimals(num);
    },
    goToBillingForm() {
      if (!this.proceed) {
        return;
      }
      const purchasedPlanDetails = {
        planId: this.currPlanCyclesAndCharges.id,
        planType: this.currentPlan.type,
        billingCycle: this.selectedPlanCycle,
        noOfAccounts: this.noOfAccounts,
        currency: this.currentPlan.currency,
        chargeableAmount: this.totalAmount,
        isTrailPlan: this.isTrailPlan,
        defaultTrialDays: this.currentPlan.defaultTrialDays,
      };
      this.setPurchasedPlanDetails(purchasedPlanDetails);
      this.changeIndex(1);
    },
    onFetchPlanSuccess(data) {
      this.hasFetched = true;
      this.allPlanDetails = data;
      const planType = this.purchaseFlowJWTPayload.planType.toLowerCase();
      this.currentPlan = this.allPlanDetails.plans.find((plan) => {
        return plan.type === planType;
      });
      if (this.currentPlan.paidPlanId) {
        // incase of trial plan use the billing cycles of corresponding paid plan
        const paidPlan = this.allPlanDetails.plans.find((plan) => {
          return plan.id === this.currentPlan.paidPlanId;
        });
        if (paidPlan) {
          this.currentPlan.pricing = { ...paidPlan.pricing };
        }
      }
      this.currency = this.currentPlan.currency;
      this.cr = helper.getCurrencySymbol(this.currency);
      this.currPlanCyclesAndCharges = this.currentPlan.pricing;
      this.sortedBillingCycles = helper.orderBillingCycles(
        this.currentPlan.pricing
      );
      const cycleChoices = Object.keys(this.currPlanCyclesAndCharges).reverse();
      if (!this.selectedPlanCycle) this.selectedPlanCycle = cycleChoices[0];
      this.currPlanCyclesAndCharges["id"] = this.currentPlan.id;
      this.updateNoOfAccounts(this.noOfAccounts);
      // this.checkForAnnualDiscount();
    },
    // checkForAnnualDiscount() {
    //   const { monthly, yearly } = this.currentPlan.pricing;
    //   if (monthly && yearly) {
    //     this.discountOnAnnualPrice = ((monthly * 12 - yearly) / yearly) * 100;
    //   }
    // },
    updateNoOfAccounts(count) {
      this.showLoader = true;
      this.noOfAccounts = count;
      this.getTotalAmount(
        this.noOfAccounts,
        this.currPlanCyclesAndCharges,
        this.selectedPlanCycle
      );
    },
    getTotalAmount(accounts, planDetails, cycle) {
      this.showLoader = false;
      this.proceed = true;
      this.totalAmount = this.scaleUpToTwoDecimals(
        accounts * planDetails[cycle]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.billingInfo {
  width: 900px;
  padding: 40px 65px;
  background-color: var(--tabBgCol);
  border: 1px solid var(--lineSepaCol);
  margin: 0 auto;
  .header {
    font-size: 20px;
    padding-bottom: 20px;
  }
  .domain,
  .plan,
  .billingCycle,
  .price,
  .noOfAcc,
  .totalAmt {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid var(--lineSepaCol);
    > div:first-child {
      width: 50%;
      word-break: break-word;
    }
  }
  .billingCycleKey {
    span {
      display: block;
    }
  }
  .billingCycleValue {
    width: 40%;
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-bottom: 7px;
      flex-basis: 150px;
      display: inline-flex;
      align-items: center;
    }

    label {
      margin: 0;
      padding-left: 7px;
      font-weight: normal;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  input[type="radio"] {
    @include custom-radio-button();
  }
  .totalAmt {
    border-bottom: none;
  }
  .nextBtn {
    margin-top: 20px;
    background-color: var(--primaryBtnCol);
    padding: 12px 44px;
    color: var(--tabBgCol);
    border-radius: 5px;
    border: none;
    font-weight: bold;
    outline: none;
  }

  .planNameValue {
    text-transform: capitalize;
  }

  .price-label {
    font-size: 14px;

    b {
      font-size: 16px;
      margin-right: 4px;
    }
  }
}
</style>
