import { FLOW_TYPES } from "@/helpers/const";
import router from "@/router";
import {
  canShowRenewOption,
  getNeoSignupAppUrl,
  isNeoBrand,
  redirectToSameORDifferentTab,
} from "@/helpers";

const prefix = "mobile.navbar.sections.";
const redirectTo = (path) => {
  router.push(path);
};

const getIconYPosition = (index, offset = 0, iconHeight = 42) => {
  const position = 0 - (index * iconHeight + offset);
  return {
    "background-position-y": `${position}px`,
  };
};

const { RENEW_SUBSCRIPTION, CHANGE_BILLING_CYCLE } = FLOW_TYPES;

export const sections = [
  {
    name: "buy or upgrade",
    titleKey: prefix + "buyOrUpgrade.title",
    items: [
      {
        titleKey: prefix + "buyOrUpgrade.items.upgradePlan.title",
        subtitleKey: prefix + "buyOrUpgrade.items.upgradePlan.subTitle",
        style: getIconYPosition(0),
        handler: () => redirectTo("/billing-and-upgrade"),
        isVisible: () => true,
      },
      {
        titleKey: prefix + "buyOrUpgrade.items.buyMailBoxes.title",
        subtitleKey: prefix + "buyOrUpgrade.items.buyMailBoxes.subTitle",
        style: getIconYPosition(1),
        handler: () => redirectTo("/buy-email-account"),
        isVisible: () => true,
      },
      {
        titleKey: prefix + "buyOrUpgrade.items.emailForNewDomain.title",
        subtitleKey: prefix + "buyOrUpgrade.items.emailForNewDomain.subTitle",
        isExternalLink: true,
        style: getIconYPosition(2),
        subIconStyle: getIconYPosition(6, -64),
        handler: () => redirectToSameORDifferentTab(getNeoSignupAppUrl(), true),
        isVisible: () => isNeoBrand(),
      },
    ],
  },
  {
    name: "billing",
    titleKey: prefix + "billing.title",
    items: [
      {
        titleKey: prefix + "billing.items.renewSubscription.title",
        subtitleKey: prefix + "billing.items.renewSubscription.subTitle",
        style: getIconYPosition(3),
        handler: () =>
          redirectTo(`/billing-and-upgrade?${RENEW_SUBSCRIPTION}=true`),
        isVisible: () => canShowRenewOption(),
      },
      {
        titleKey: prefix + "billing.items.changeBillingCycle.title",
        subtitleKey: prefix + "billing.items.changeBillingCycle.subTitle",
        style: getIconYPosition(4),
        handler: () => redirectTo(`/mobile-home?${CHANGE_BILLING_CYCLE}=true`),
        isVisible: () => true,
      },
    ],
  },
  {
    name: "more features",
    titleKey: prefix + "moreFeatures.title",
    items: [
      {
        titleKey: prefix + "moreFeatures.items.moreFeatureOnDesktop.title",
        subtitleKey:
          prefix + "moreFeatures.items.moreFeatureOnDesktop.subTitle",
        style: getIconYPosition(5),
        hideRedirectIcon: true,
        isVisible: () => true,
      },
    ],
  },
];
