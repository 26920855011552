<template>
  <div>
    <div class="banner-note">
      <span v-if="isMobile()">{{ $t("login.bannerNoteMobile") }}</span>
      <span v-else>{{ $t("login.bannerNote") }}</span>
    </div>
    <div class="main-wrapper-login" v-if="showLogin">
      <div class="content-wrapper">
        <div class="logo-div">
          <h1>
            <img v-if="brandDetails.logo" :src="brandDetails.logo" alt="logo" />
          </h1>
        </div>
        <div
          class="login-box"
          :class="{ 'no-bottom-border': isPartnerSignupFlow }"
        >
          <h2>{{ $t(`login.${brandDetails.loginHeader}`) }}</h2>
          <form id="app" @submit="login" method="post" novalidate="true">
            <div>
              <label for="email">{{ $t("login.emailLabel") }}</label>
              <input
                type="text"
                name="email"
                id="email"
                v-model="email"
                placeholder="e.g. john.doe@example.com"
                :class="{ 'b-red': invalidFieldErrors.email }"
              />
              <p v-if="invalidFieldErrors.email" class="error">
                {{ invalidFieldErrors.email }}
              </p>
            </div>
            <div v-if="isCustomerIdNeeded">
              <div class="customer-id-wrapper">
                <label for="customerId">{{ $t("login.customerId") }}</label>
                <a
                  class="contact-support"
                  :href="`mailto:${brandDetails.supportEmail}`"
                  >{{ $t("login.contactSupport") }}</a
                >
              </div>
              <input
                type="text"
                name="customerId"
                id="customerId"
                v-model="customerId"
                :class="{ 'b-red': invalidFieldErrors.customerId }"
              />
              <p v-if="invalidFieldErrors.customerId" class="error">
                {{ invalidFieldErrors.customerId }}
              </p>
            </div>
            <div class="password-wrapper">
              <label for="password">{{ $t("login.passwordLabel") }}</label>
              <span class="forgot-password" @click="pushToForgotPass"
                >{{ $t("login.forgotPass") }}?</span
              >
              <div class="input-wrapper">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  id="password"
                  v-model="password"
                  :class="{ 'b-red': invalidFieldErrors.password }"
                  @keydown.space.prevent
                />
                <span
                  :class="{
                    'password-hidden': showPassword,
                    'password-shown': !showPassword,
                  }"
                  @click="togglePassword"
                  v-show="password.length > 0"
                />
              </div>

              <p v-if="invalidFieldErrors.password" class="error">
                {{ invalidFieldErrors.password }}
              </p>
            </div>
            <!-- TODO where is the customerId missing -->
            <titan-button
              class="submit-btn-container"
              :disabled="!email || !password"
              buttonType="submit"
              :loading="loading && !Object.keys(invalidFieldErrors).length"
            >
              {{ $t("login.login") }}
            </titan-button>
            <div class="error font-14px" v-if="authErrorMessage">
              {{ authErrorMessage }}
            </div>
            <!-- Show only when the user is domainAdmin as well as customer admin
            Will Apply the logic later once API is Done -->
            <div
              v-html="
                $t('login.domainAndCustomerAdminLoginError', [
                  domainName,
                  webMailUrl,
                  brandDetails.name,
                ])
              "
              class="domainAndCustomerAdminLoginError"
              v-if="false"
            ></div>
          </form>
        </div>

        <div class="partner-sign-up" v-if="isPartnerSignupFlow">
          <span>{{ $t("login.partnerSetupText") }}</span>
          <span @click="navigateToProvisionFlow()">{{
            $t("login.partnerSetupBtn")
          }}</span>
        </div>
        <div
          v-html="$t('login.loginToWebmail', [webMailUrl])"
          class="mt-40 loginToWebmail"
        ></div>
        <a
          class="contact-support mt-16"
          :href="`mailto:${brandDetails.supportEmail}`"
          >{{ $t("login.contactSupport") }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import brands from "../../helpers/brand";
import {
  storeLoginData,
  setWebsiteMetaData,
  isAppLoadedInsideIframe,
  getWebmailUrl,
  isMobile,
} from "@/helpers";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";

export default {
  name: "Login",
  data() {
    return {
      invalidFieldErrors: {},
      loading: false,
      email: "",
      password: "",
      showPassword: false,
      isCustomerIdNeeded: false,
      customerId: "",
      authErrorMessage: "",
      showLogin: false,
      isPartnerSignupFlow: false,
    };
  },
  computed: {
    ...mapGetters(["brandDetails"]),
    webMailUrl() {
      return getWebmailUrl();
    },
    domainName() {
      return this.email.split("@")[1];
    },
  },
  props: {
    setOfacParams: Function,
  },
  methods: {
    isMobile,
    ...mapActions({
      storeBrandDetails: "brandDetails",
    }),
    navigateToProvisionFlow() {
      const partnerID = this.$route.query.partnerID;
      this.$router.push({
        path: "/partner/provision",
        query: { partnerID: `${partnerID}` },
      });
    },
    login(e) {
      e.preventDefault();
      this.invalidFieldErrors = {};
      this.loading = true;
      // check invalid email error
      if (!this.email) {
        this.invalidFieldErrors.email = this.$i18n.t("login.emailError1");
      } else if (!this.validEmail(this.email)) {
        this.invalidFieldErrors.email = this.$i18n.t("login.emailError2");
      }
      // check invalid password error
      if (this.password.length === 0) {
        this.invalidFieldErrors.password = this.$i18n.t("login.passwordError");
      }
      // check invalid customerId error
      if (this.isCustomerIdNeeded && !this.customerId.length) {
        this.invalidFieldErrors.customerId = this.$i18n.t("login.custIdError");
      }

      // Do not call API if any field is invalid
      if (Object.keys(this.invalidFieldErrors).length) {
        this.loading = false;
        return;
      }

      this.authErrorMessage = "";
      let loginAPIReqParams = {
        email: this.email,
        password: this.password,
      };
      if (this.isCustomerIdNeeded) {
        loginAPIReqParams.userId = this.customerId;
      }
      this.$http
        .post("panel/login", loginAPIReqParams)
        .then(this.onSuccess)
        .catch(this.onError);
    },
    onSuccess(data) {
      if (data) {
        smartStorage.loginEmail = this.email;
        storeLoginData(data);
        var eventObj = {
          eventName: "control_panel_login_button_click",
          response: "success",
        };
        this.medusaEvents
          .send(
            this.medusaEvents.createEventObj(
              eventObj,
              smartStorage.getItem("domain"),
              "flock_order"
            )
          )
          .then(() => {
            this.$emit("authCallback", "credentials");
          });
      }
    },
    onError(err) {
      this.loading = false;
      if (err.data.code === "AMBIGUOUS_CREDENTIALS") {
        this.isCustomerIdNeeded = true;
        this.authErrorMessage = this.$i18n.t("login.ambiCredValidation");
      } else if (err.data.code === "OFAC_SANCTIONED_COUNTRY") {
        const { ofacDocumentation, supportEmail, logo } = this.brandDetails;
        this.setOfacParams({
          isOfacFlag: true,
          ofacDocumentation,
          supportEmail,
          brandLogo: logo,
        });
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            {
              eventName: "ofac_login_denied",
              ofac_country: err.data.attrs.countryName,
            },
            smartStorage.getItem("domain"),
            "flock_order"
          )
        );
      } else {
        this.authErrorMessage = this.$i18n.t("login.incorrectEmailOrPassword");
        var eventObj = {
          eventName: "control_panel_login_button_click",
          response: "failure",
        };
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            eventObj,
            smartStorage.getItem("domain"),
            "flock_order"
          )
        );
      }
    },
    pushToForgotPass() {
      this.$router.push("/reset-password");
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    setBrandDetails(data) {
      this.storeBrandDetails({
        ...this.brandDetails,
        logo: data?.resources?.controlPanelLogoUrl || this.brandDetails.logo,
        supportEmail:
          data?.emails?.supportEmail || this.brandDetails.supportEmail,
      });
      setWebsiteMetaData.call(this);
    },
  },
  mounted() {
    measurePagePerformance();
  },
  created() {
    if (isAppLoadedInsideIframe()) {
      this.$router.push({ name: "LoginFailureScreen" });
    }
    smartStorage.removeItem("theme");
    smartStorage.removeItem("view");
    this.isPartnerSignupFlow = this.$route.query.hasOwnProperty("partnerID");
    if (!smartStorage.getItem("auth")) {
      this.showLogin = true;
    }
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      let query = Object.assign({}, this.$route.query);
      delete query.email;
      this.$router.replace({ query });
    }
    this.storeBrandDetails(brands.getDetails());
    this.getPartnerInfo({ headers: { "X-Auth": "noAuth" } })
      .then((data) => this.setBrandDetails(data))
      .catch(this.setBrandDetails);
  },
};
</script>

<style lang="scss">
.banner-note {
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
  color: var(--primaryTextCol);
  background-color: var(--bannerBgCol);
  padding: 10px;
  border-bottom: solid 1px var(--lineSepaCol);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.main-wrapper-login {
  padding-top: 4em;
  text-align: center;
  background: var(--primaryBgCol);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 780px; // since the header and some content was getting cropped in small height device
  background: url("../../assets/app-login-bg.png") no-repeat center center;

  .content-wrapper {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo-div {
      margin-bottom: 25px;

      img {
        width: 157px;
        height: auto;
      }
    }
    .customer-id-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .partner-sign-up {
      box-shadow: 0 2px 4px 0 var(--boxShadowCol3);
      background-color: var(--boxShadowCol8);
      border-top: 1px solid var(--lineSepaCol);
      border-radius: 0 0 4px 4px;
      color: var(--secondaryTextCol);
      text-align: left;
      padding: 31px 0 31px 50px;
      font-size: 14px;
      span:nth-child(2) {
        color: var(--forgotPassCol);
        font-weight: 600;
        cursor: pointer;
      }
    }
    .no-bottom-border {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .login-box {
      padding: 46px 50px 50px;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 var(--boxShadowCol3);
      background-color: var(--boxShadowCol8);
      text-align: left;

      div {
        position: relative;
      }
      h2 {
        font-size: 28px;
        font-weight: 400 !important;
        letter-spacing: normal;
        color: var(--header2Col);
        margin-bottom: 25px;
      }

      label {
        display: block;
        font-size: 14px;
        color: var(--primaryTextCol);
        text-align: left;
        margin-top: 16px;
      }

      .password-wrapper {
        position: relative;
      }

      .input-wrapper {
        margin-bottom: 0;
        position: relative;

        .password-shown,
        .password-hidden {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
        }
      }

      .error {
        margin-bottom: 0;
      }

      input {
        border-radius: 4px;
        width: 100%;
        margin-top: 2px;
        border: solid 1px var(--inputBorderCol);
        font-size: 14px;
        line-height: 20px;
        padding: 9px 15px;
        outline: none;
      }

      .forgot-password {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.17;
        color: var(--forgotPassCol);
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 5px;
      }
      .password-shown {
        background: url("../../assets/password-toggle-on.svg") no-repeat center /
          contain;
      }

      .password-hidden {
        background: url("../../assets/password-toggle-off.svg") no-repeat center /
          contain;
      }
      .submit-btn-container {
        margin: 20px 0;
      }
      .active {
        background-color: var(--primaryBtnCol) !important;
        border-color: var(--primaryBtnCol);
      }
    }

    .contact-support {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--fadedTextCol);
      text-decoration: underline;
    }
  }
  .domainAndCustomerAdminLoginError {
    span,
    a {
      font-weight: 600;
    }
  }
}
.loginToWebmail {
  a {
    font-weight: 600;
  }
}

@import "../../styles/variables";
@media screen and (max-width: $responsive-breakpoint) {
  .main-wrapper-login {
    background: var(--tabBgCol) none;

    .content-wrapper {
      width: 100%;
      max-width: 500px;

      .logo-div {
        img {
          width: 120px;
        }
      }

      .login-box {
        padding: 32px;
        text-align: center;
        background: var(--tabBgCol) none;
        box-shadow: none;

        h2 {
          font-size: 24px;
          font-weight: 600 !important;
        }

        form {
          background-color: var(--tabBgCol) !important;

          label {
            font-size: 16px;
            font-weight: 600;
            color: var(--tertiaryTextCol);
            margin: 24px 0 8px;
          }

          input {
            padding: 15px;
            margin: 0;
          }

          .forgot-password {
            font-size: 14px;
          }

          .password-shown,
          .password-hidden {
            position: absolute;
            bottom: 15px;
          }

          .submit-btn-container {
            width: 100%;
            max-width: 500px;
            padding: 15px;
            margin-top: 24px;
          }
        }
      }
    }
  }
}
</style>
