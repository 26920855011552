<template>
  <div class="navbar-container">
    <div v-for="section in sections" class="section" :key="section.name">
      <div class="section-title">{{ $t(section.titleKey) }}</div>
      <ul class="section-item-list">
        <li
          v-for="item in section.items.filter((item) => item.isVisible())"
          :class="['section-item']"
          @click="item.handler ? item.handler() : null"
        >
          <div class="section-item-icons">
            <span class="card-icon" :style="item.style" />
            <span
              class="card-icon external-link-icon"
              :style="item.subIconStyle"
              v-if="item.isExternalLink"
            />
            <Titan-arrow v-else v-show="!item.hideRedirectIcon" />
          </div>
          <div class="section-item-details">
            <div class="section-item-title">{{ $t(item.titleKey) }}</div>
            <div class="section-item-subtitle">{{ $t(item.subtitleKey) }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { sections } from "./config";
import BackArrow from "../../assets/back-arrow.svg";

export default {
  data() {
    return {
      sections,
      BackArrow,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar-container {
  padding: 16px 0;
  background-color: #f8faff;
}

.section {
  margin-top: 20px;

  &:not(:first-of-type) {
    margin-top: 40px;
  }

  .section-title {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 8px;
    color: var(--primaryTextCol);
  }
}

.section-item-list {
  list-style: none;
  margin-inline-start: 0;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section-item {
  border: 1px solid white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 16px;
  cursor: pointer;
  background-color: var(--primaryBgCol);
  border-radius: 4px;

  .section-item-title {
    color: var(--primary);
    font-size: 16px;
    margin-bottom: 4px;
  }

  .section-item-subtitle {
    color: var(--tertiaryTextCol);
  }

  .section-item-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .card-icon {
      width: 32px;
      aspect-ratio: 1;
      display: inline-block;
      background: url("../../assets/mobile/nav-sprite.svg") center 0 no-repeat;
      background-size: 32px;

      &.external-link-icon {
        width: 24px;
        background-size: 24px;
      }
    }
  }
}
</style>
