<template>
  <titan-modal
    :closeBtnClass="'full mt-24'"
    :closeBtnText="$t('welcomeScreen.continue')"
  >
    <template #header>
      <div class="welcome-header font-24px">
        <div
          v-html="
            $t('welcomeScreen.header', [
              $t(`${brandDetails.panelName}`),
              domainName,
            ])
          "
        ></div>
        <span v-if="remainingDomains.length">
          {{ $t("welcomeScreen.and") }}
          <span id="other-domains" class="font-400 pointer"
            >{{ remainingDomains.length }} {{ $t("welcomeScreen.other") }}</span
          >
          <b-popover target="other-domains" triggers="hover" placement="bottom"
            ><ul class="p-0 list-style-none m-0">
              <li v-for="domain in remainingDomains" :key="domain.id">
                {{ domain.name }}
              </li>
            </ul></b-popover
          >
        </span>
      </div>
    </template>
    <template #content>
      <div class="welcome-content b-grey br-8 mt-24">
        <div>{{ $t("welcomeScreen.content1", [loggedInUserDomain]) }}</div>
        <div
          v-html="
            $t('welcomeScreen.content2', [
              webMailUrl,
              loggedInUserEmail,
              `${brandDetails.name} ${$t(brandDetails.panelName)}`,
            ])
          "
        ></div>
      </div>
    </template>
    <template #close />
  </titan-modal>
</template>
<script>
import { BPopover } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { getWebmailUrl } from "@/helpers/";
export default {
  components: {
    BPopover,
  },
  computed: {
    ...mapGetters(["domainName", "brandDetails", "domainList"]),
    webMailUrl() {
      return getWebmailUrl(this.loggedInUserEmail);
    },
    remainingDomains() {
      return this.domainList.filter(
        (domain) => domain.name !== this.domainName
      );
    },
    loggedInUserEmail() {
      return smartStorage.getItem("loginEmail");
    },
    loggedInUserDomain() {
      return this.loggedInUserEmail.split("@")[1];
    },
  },
};
</script>
<style lang="scss">
.welcome-header {
  span,
  a {
    &:first-child {
      font-weight: 600;
    }
  }
  #other-domains {
    text-decoration: underline dotted;
  }
}
.welcome-content {
  background: var(--tableDataHoverCol);
  padding: 16px;
  div {
    &:first-child {
      font-weight: 600;
    }
  }
  a {
    font-weight: 600;
  }
}
</style>
