<script>
import { REGX } from "../../helpers/const";
export default {
  name: "ManageAlias",
  data() {
    return {
      aliasName: "",
      displayName: "",
      serverError: "",
      currentAlias: "",
      addAliasShown: false,
      showActionForm: false,
      aliasActionType: null,
      maxAliasCount: 10,
      showButtonLoader: false,
      domainName: smartStorage.getItem("domain"),
      showTooltip: false,
      openingTag: "<",
      closingTag: ">",
    };
  },
  props: {
    email: String,
    aliases: Array,
    onAdd: Function,
    view: Object,
    manageAliasMedusaParams: Object,
  },
  computed: {
    isEditOrDeleteFormOpenForAlias() {
      return (alias) =>
        this.showActionForm && this.currentAlias === alias.email;
    },
    isNonEmptyDisplayNameAndAliasName() {
      return this.aliasName && this.displayName;
    },
    aliasEmail() {
      return this.aliasName + "@" + smartStorage.getItem("domain");
    },
  },
  methods: {
    handleTooltip(e) {
      this.showTooltip = e.target.offsetWidth < e.target.scrollWidth;
    },
    actionOnAliasData(aliasActionType, alias) {
      this.showActionForm = true;
      this.aliasActionType = aliasActionType;
      this.displayName = alias.displayName;
    },
    close() {
      this.$emit("close");
      this.aliasName = "";
      this.serverError = "";
      this.currentAlias = "";
      this.addAliasShown = false;
      this.showActionForm = false;
    },
    showAddAlias() {
      this.aliasName = "";
      this.addAliasShown = true;
      this.displayName = "";
      this.aliasActionType = "add";
      this.showActionForm = false;
    },
    hideAddAlias() {
      this.aliasName = "";
      this.serverError = "";
      this.addAliasShown = false;
      this.aliasActionType = null;
      this.showActionForm = false;
    },
    hideEditAlias() {
      this.aliasActionType = null;
      this.showActionForm = false;
    },
    addAlias() {
      let alias = this.aliasEmail;
      if (!REGX.email.test(alias)) {
        this.serverError = this.$t("emailAccounts.createEmail.invalidEmail");
        return;
      }
      this.showButtonLoader = true;
      this.http.postData(
        "panel/account/email/alias/add",
        {
          alias: alias,
          email: this.email,
          domainName: smartStorage.getItem("domain"),
          displayName: this.displayName,
        },
        this.$root.headers,
        this.onSuccessAdd,
        this.onErrorAdd
      );
    },
    triggerAddAliasMedusaEvent(type, response) {
      const medusaObject = {
        eventName: "manage_alias_add_new_alias",
        display_name: this.displayName.toString(),
        alias_email: this.aliasEmail,
        ...this.manageAliasMedusaParams,
      };
      if (type === "error") medusaObject["response"] = response;
      this.medusaEvents.trackEvent(medusaObject);
    },
    onSuccessAdd(data) {
      this.showButtonLoader = false;
      if (data) {
        const message = this.aliasEmail + "  " + this.$t("toast.isAdded");
        this.triggerAddAliasMedusaEvent("success");
        this.$emit("onAddAlias", {
          displayName: this.displayName.toString(),
          email: this.aliasEmail,
          senderId: data.senderId,
        });
        this.showToast(message, {
          modalName: "manage-alias",
          type: "success",
        });
        this.hideAddAlias();
      }
    },
    onErrorAdd(response) {
      this.showButtonLoader = false;
      if (response && response.data && response.data.desc) {
        this.serverError = response.data.desc;
        this.triggerAddAliasMedusaEvent("error", response.data.desc);
      }
    },
    onMouseEnter(alias) {
      if (!this.showActionForm) {
        this.currentAlias = alias.email;
      }
    },
    removeAlias(alias) {
      this.showButtonLoader = true;
      this.$http
        .post("panel/account/email/alias/remove", {
          aliases: [alias.email],
          email: this.email,
          domainName: smartStorage.getItem("domain"),
        })
        .then(() => this.onRemoveSuccess(alias))
        .catch(this.onRemoveError);
    },
    editAlias(alias) {
      this.showButtonLoader = true;
      const oldDisplayName = this.aliases.find(
        (item) => item.senderId === alias.senderId
      ).displayName;
      this.$http
        .post("panel/account/email/alias/update", {
          senderId: alias.senderId,
          displayName: this.displayName,
          domainName: smartStorage.getItem("domain"),
        })
        .then(() => this.onEditAliasSuccess(alias.email, oldDisplayName))
        .catch(() => this.onEditAliasError(oldDisplayName));
    },
    triggerEditAliasMedusaEvent(type, { oldDisplayName, userEmail, message }) {
      const medusaObject = {
        eventName: "manage_alias_edit_alias",
        new_display_name: this.displayName.toString(),
        old_display_name: oldDisplayName,
        alias_email: userEmail,
        ...this.manageAliasMedusaParams,
      };
      if (type === "error") medusaObject["response"] = message;
      this.medusaEvents.trackEvent(medusaObject);
    },
    onEditAliasSuccess(userEmail, oldDisplayName) {
      this.showButtonLoader = false;
      this.showActionForm = false;
      this.$emit("onEdit", userEmail, this.displayName);
      this.triggerEditAliasMedusaEvent("success", {
        oldDisplayName,
        userEmail,
      });
      const message = this.$t(`emailAccounts.manageAlias.displayNameChanged`, [
        userEmail,
      ]);
      this.showToast(message, {
        modalName: "manage-alias",
        type: "success",
      });
    },
    onEditAliasError(oldDisplayName) {
      this.showButtonLoader = false;
      this.showToast(this.$t("emailAccounts.manageAlias.updateError"), {
        modalName: "manage-alias",
        type: "error",
      });
      this.triggerEditAliasMedusaEvent("error", {
        oldDisplayName,
        message: "Failed to update display name",
      });
    },
    triggerRemoveAliasMedusaEvent(type, { alias_email, message }) {
      const medusaObject = {
        eventName: "manage_alias_delete_alias",
        alias_email,
        ...this.manageAliasMedusaParams,
      };
      if (type === "error") medusaObject["response"] = message;
      this.medusaEvents.trackEvent(medusaObject);
    },
    onRemoveSuccess(data) {
      if (data) {
        this.showButtonLoader = false;
        this.showActionForm = false;
        this.triggerRemoveAliasMedusaEvent("success", {
          alias_email: data.email,
        });
        this.$emit("onRemoveAlias", data);
        this.showToast(data.email + " " + this.$t("toast.isDeleted"), {
          modalName: "manage-alias",
          type: "success",
        });
      }
    },
    onRemoveError(response) {
      this.showButtonLoader = false;
      if (response && response.data && response.data.desc) {
        this.triggerRemoveAliasMedusaEvent("error", {
          message: response.data.desc,
        });
        this.showToast(response.data.desc, {
          type: "error",
          modalName: "manage-alias",
        });
      }
    },
  },
};
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        :class="[
          'modal-body-container',
          'manage-alias-modal',
          {
            modalPage: view.showModalAsPage,
          },
        ]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
          v-if="!view.showModalAsPage"
        >
          &times;
        </button>
        <widget-breadcrumb-nav
          modalName="manage-alias"
          :isModal="true"
          v-if="view.showModalAsPage"
          @onNavClick="close"
        />
        <div class="modal-content-wrapper">
          <div>
            <h4>{{ $t("emailAccounts.manageAlias.heading") }}</h4>
            <p class="color-grey email-info alias-email-info">
              {{ $t(`emailAccounts.manageAlias.description`, [email]) }}
            </p>
            <ul class="alias-listing">
              <li class="open">
                <div class="input-wrapper add-new-alias-wrapper">
                  <span
                    :class="{
                      'disable-cursor': aliases.length === maxAliasCount,
                    }"
                  >
                    <span
                      :class="[
                        {
                          'disable-pointer':
                            aliases.length === maxAliasCount || addAliasShown,
                          'add-alias': !addAliasShown,
                        },
                      ]"
                      @click="showAddAlias"
                      >+ {{ $t("emailAccounts.manageAlias.addAlias") }}</span
                    ></span
                  >
                  <span>{{
                    $t(`emailAccounts.manageAlias.maxAliasCount`, [
                      maxAliasCount,
                    ])
                  }}</span>
                </div>
                <div v-if="addAliasShown">
                  <div class="input-wrapper">
                    <div class="add-alias-data">
                      <label>{{
                        $t("emailAccounts.manageAlias.displayName")
                      }}</label>
                      <div class="input-addon-wrapper">
                        <input
                          type="text"
                          v-model.trim="displayName"
                          class="input"
                          :placeholder="
                            $t(
                              'emailAccounts.manageAlias.displayNamePlaceholder'
                            )
                          "
                          @keyup.enter="
                            isNonEmptyDisplayNameAndAliasName && addAlias()
                          "
                        />
                      </div>
                    </div>
                    <div class="add-alias-data">
                      <label>{{
                        $t("emailAccounts.manageAlias.emailAddress")
                      }}</label>
                      <div class="input-addon-wrapper alias-domain-wrapper">
                        <input
                          type="text"
                          v-model.trim="aliasName"
                          class="input email-input"
                          :placeholder="
                            $t('emailAccounts.manageAlias.emailPlaceholder')
                          "
                          @keyup.enter="
                            isNonEmptyDisplayNameAndAliasName && addAlias()
                          "
                          autocomplete="new-password"
                        />
                        <span
                          class="addon domain-name"
                          id="alias-domain-name"
                          @mouseover="handleTooltip"
                          >@{{ domainName }}
                        </span>
                        <b-popover
                          v-if="showTooltip"
                          target="alias-domain-name"
                          triggers="hover"
                          placement="top"
                          custom-class="alias-domain-tooltip"
                        >
                          @{{ domainName }}
                        </b-popover>
                      </div>
                      <div class="error">
                        {{ serverError }}
                      </div>
                    </div>
                  </div>
                  <div class="input-wrapper">
                    <span
                      :class="{
                        'disable-cursor': !isNonEmptyDisplayNameAndAliasName,
                      }"
                    >
                      <button
                        :class="[
                          'button',
                          {
                            invalid: !isNonEmptyDisplayNameAndAliasName,
                            'show-disabled-spinner':
                              showButtonLoader && aliasActionType === 'add',
                            'disable-pointer': !isNonEmptyDisplayNameAndAliasName,
                          },
                        ]"
                        @click="addAlias"
                      >
                        {{ $t("emailAccounts.manageAlias.createAlias") }}
                      </button></span
                    >
                    <button
                      class="button button__transparent color-grey"
                      @click="hideAddAlias"
                    >
                      {{ $t("emailAccounts.manageAlias.cancel") }}
                    </button>
                  </div>
                </div>
              </li>
              <li
                v-for="alias in aliases"
                :value="alias"
                @mouseenter="!showActionForm && onMouseEnter(alias)"
                :class="{
                  dimmed:
                    (showActionForm && currentAlias !== alias.email) ||
                    addAliasShown,
                  noHover: isEditOrDeleteFormOpenForAlias(alias),
                  removeTopPadding: isEditOrDeleteFormOpenForAlias(alias),
                }"
              >
                <!-- <span class="alias-email">{{ alias.email }}</span> -->
                <span
                  :class="[
                    'alias-list-items',
                    {
                      dimmed: showActionForm,
                      hide: isEditOrDeleteFormOpenForAlias(alias),
                    },
                  ]"
                  >{{
                    (isEditOrDeleteFormOpenForAlias(alias)
                      ? displayName
                      : alias.displayName) +
                    " " +
                    openingTag +
                    alias.email +
                    closingTag
                  }}</span
                >
                <span v-show="!showActionForm && !addAliasShown">
                  <span
                    class="trash"
                    @click="actionOnAliasData('delete', alias)"
                  ></span>
                  <span
                    class="edit"
                    @click="actionOnAliasData('edit', alias)"
                  ></span>
                </span>
                <div
                  v-show="
                    isEditOrDeleteFormOpenForAlias(alias) &&
                    aliasActionType === 'delete'
                  "
                  class="action-form-container removeTopMargin"
                >
                  <p>
                    {{
                      (isEditOrDeleteFormOpenForAlias(alias)
                        ? displayName
                        : alias.displayName) +
                      " " +
                      openingTag +
                      alias.email +
                      closingTag
                    }}
                  </p>
                  <div>
                    {{ $t("emailAccounts.manageAlias.sureWantToDelete") }}
                  </div>
                  <div class="btn-wrapper">
                    <button
                      :class="[
                        'button',
                        {
                          'show-disabled-spinner':
                            showButtonLoader && aliasActionType === 'delete',
                        },
                      ]"
                      @click="removeAlias(alias)"
                    >
                      {{ $t("emailAccounts.manageAlias.delete") }}
                    </button>
                    <button
                      class="button button__transparent color-grey"
                      @click="showActionForm = false"
                    >
                      {{ $t("emailAccounts.manageAlias.no") }}
                    </button>
                  </div>
                </div>
                <div
                  v-show="
                    isEditOrDeleteFormOpenForAlias(alias) &&
                    aliasActionType === 'edit'
                  "
                  class="action-form-container removeTopMargin"
                >
                  <div class="alias-edit-form-flex-wrapper">
                    <div class="input-addon-wrapper">
                      <input
                        type="text"
                        v-model.trim="displayName"
                        class="input"
                        :placeholder="
                          $t(
                            'emailAccounts.manageAlias.smallDisplayNamePlaceholder'
                          )
                        "
                        @keyup.enter="displayName && editAlias(alias)"
                      />
                    </div>
                    <div class="alias-email">
                      {{ openingTag + alias.email + closingTag }}
                    </div>
                  </div>
                  <div class="btn-wrapper">
                    <span
                      :class="{
                        'disable-cursor': !displayName,
                      }"
                    >
                      <button
                        class="button"
                        :class="{
                          invalid: !displayName,
                          'disable-pointer': !displayName,
                          'show-disabled-spinner': showButtonLoader,
                        }"
                        @click="editAlias(alias)"
                      >
                        {{ $t("emailAccounts.manageAlias.save") }}
                      </button>
                    </span>
                    <button
                      class="button button__transparent color-grey"
                      @click="hideEditAlias"
                    >
                      {{ $t("emailAccounts.manageAlias.cancel") }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="no-alias"
          v-show="!addAliasShown && aliases && aliases.length === 0"
        >
          <div>
            <img src="../../assets/no-alias.svg" alt="no-alias" />
          </div>
          <p class="color-grey">
            {{ $t("emailAccounts.manageAlias.noAlias") }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.manage-alias-modal {
  min-height: 500px;
  text-align: left;
}

.manage-recipients-modal {
  .alias-listing {
    min-height: 400px;
  }
}
p.alias-email-info {
  padding-bottom: 17px;
  border-bottom: 1px solid var(--lineSepaCol);
  margin-bottom: 0;
}
.alias-listing {
  list-style: none;
  padding: 0;
  max-height: 400px;
  overflow-y: scroll;
  margin-bottom: 0;

  & > li {
    padding: 12px 16px;
    border-bottom: 1px solid var(--lineSepaCol);
    position: relative;
    word-break: break-word;
    width: var(--inputBoxWidth);
    .alias-list-items {
      display: inline-block;
      padding-right: 70px;
    }
    .hide {
      display: none;
    }
    &.transparent {
      border-bottom: none;
    }
    &.add-new-alias-wrapper {
      border-top: 1px solid var(--lineSepaCol);
      color: var(--primaryBtnCol);

      &:hover {
        background: transparent;
      }

      .max-alias-reached {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:hover {
      background-color: var(--tableDataHoverCol);
      color: var(--genericTextCol);
      td,
      div,
      p,
      li,
      span {
        color: var(--genericTextCol);
      }

      .trash,
      .edit {
        display: inline-block;
      }
    }

    .trash,
    .edit {
      width: 24px;
      height: 24px;
      top: 10px;
      position: absolute;
      display: none;
      cursor: pointer;
    }
    .edit {
      right: 55px;
      background: url("../../assets/edit-icon.svg") no-repeat center / contain;
    }
    .trash {
      right: 16px;
      background: url("../../assets/delete-grey.svg") no-repeat center / contain;
    }
    &.open {
      width: var(--inputBoxWidth);
      background-color: var(--primaryBgCol);

      &:hover {
        background: var(--primaryBgCol);
      }
    }
    .add-new-alias-wrapper {
      display: flex;
      justify-content: space-between;
      span {
        color: var(--secondaryTextCol);
      }
    }

    .add-alias {
      cursor: pointer;
      font-weight: 600;
      color: var(--secondaryBtnCol) !important;
    }

    .action-form-container {
      top: 45px;
      left: 0;
      right: 0;
      padding: 12px 16px;
      background: var(--tableDataHoverCol);
      z-index: 1;
      margin: 12px -16px -12px -16px;
      .btn-wrapper {
        margin-top: 21px;
        .color-grey {
          margin-left: 0;
        }
      }
      p {
        font-weight: 600;
      }
      .alias-edit-form-flex-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 21px;
        .input-addon-wrapper {
          flex: 1;
          max-width: 180px;
          margin-right: 10px;
          input {
            padding-left: 6px;
            padding-right: 10px;
          }
        }
        .alias-email {
          flex: 1;
        }
      }
    }
    &.noHover {
      background: transparent;
    }
    &.removeTopPadding {
      padding-top: 0;
    }
    .removeTopMargin {
      margin-top: 0;
    }
    &.transparent {
      background-color: var(--tabBgCol);
    }

    &.selected {
      font-weight: 600;
      background: var(--tableDataHoverCol);
      color: var(--genericTextCol);
      .confirm-delete {
        font-weight: 400;
        color: var(--primaryTextCol);
      }
      .alias-email {
        padding-right: 30px;
        color: var(--secondaryTextCol);
        display: inline-block;
        &:hover {
          background: transparent;
        }
      }
    }
  }

  .input-wrapper {
    margin-bottom: 16px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .addon {
      color: var(--primaryTextCol);
    }
  }
}
.add-alias-data {
  margin-bottom: 20px;
  label {
    font-weight: 600;
  }
  .input-addon-wrapper {
    input {
      padding-right: 10px;
    }
    .email-input {
      padding-right: 53%;
    }
  }
  .domain-name {
    @include ellipsis();
  }
}

.no-alias {
  text-align: center;
  position: relative;
  top: 80px;
}
.dimmed {
  opacity: 0.5;
}
.disable-pointer {
  pointer-events: none;
}
.disable-cursor {
  cursor: not-allowed !important;
}
.alias-domain-tooltip {
  .popover-body {
    color: var(--primaryTextCol) !important;
    padding: 6px 8px !important;
  }
}
</style>
