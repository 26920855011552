var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domain-edit-modal"},[_c('div',{staticClass:"modal-heading"},[_c('h4',[_vm._v(_vm._s(_vm.$t("domainEditModal.heading")))]),_c('div',[_vm._v(_vm._s(_vm.$t("domainEditModal.subHeading")))])]),_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newDomainName),expression:"newDomainName"}],ref:"domainInput",staticClass:"input",class:{ 'b-red': _vm.hasError },attrs:{"type":"text","placeholder":_vm.$t('domainEditModal.inputPlaceholder'),"disabled":_vm.processing},domProps:{"value":(_vm.newDomainName)},on:{"input":function($event){if($event.target.composing)return;_vm.newDomainName=$event.target.value}}}),_c('div',{staticClass:"error",class:{ hidden: !_vm.hasError },domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}),_c('div',[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.domainOwnershipVerified),expression:"domainOwnershipVerified"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.domainOwnershipVerified)?_vm._i(_vm.domainOwnershipVerified,null)>-1:(_vm.domainOwnershipVerified)},on:{"change":function($event){var $$a=_vm.domainOwnershipVerified,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.domainOwnershipVerified=$$a.concat([$$v]))}else{$$i>-1&&(_vm.domainOwnershipVerified=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.domainOwnershipVerified=$$c}}}})]),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$tc('domainEditModal.checkboxLabel', 1, {
            0: `<span class='domain-name'>${
              _vm.newDomainName || _vm.newDomainNameFallback
            }</span>`,
          })
        )}})])]),_c('div',{staticClass:"button-wrapper"},[_c('button',{staticClass:"btn btn-primary",class:{ processing: _vm.processing },attrs:{"disabled":!_vm.domainOwnershipVerified || _vm.newDomainName === '' || _vm.processing},on:{"click":_vm.handleUpdateDomainBtnClick}},[(_vm.newDomainName !== '')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("domainEditModal.btnText", [_vm.newDomainName]))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("genericText.update")))])])]),_c('div',{staticClass:"footer",domProps:{"innerHTML":_vm._s(_vm.$t('domainEditModal.footerText'))}})])
}
var staticRenderFns = []

export { render, staticRenderFns }