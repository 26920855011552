<template>
  <div class="domain-edit-success-modal">
    <div class="success-icon">
      <img src="@/assets/domainEditSuccessIcon.svg" alt="Success" />
    </div>
    <h4>{{ $t("domainEditSuccessModal.heading") }}</h4>
    <div
      class="subHeading"
      v-html="$t('domainEditSuccessModal.subHeading', [newDomainName])"
    />

    <div
      class="confirmation"
      v-html="$t('domainEditSuccessModal.confirmationNote', [userEmail])"
    />
  </div>
</template>

<script>
import { getCurrentUserEmail } from "@/helpers";

export default {
  name: "DomainEditSuccessModal",
  data() {
    return {
      userEmail: getCurrentUserEmail(),
    };
  },
  props: ["newDomainName"],
};
</script>

<style lang="scss" scoped>
.domain-edit-success-modal {
  padding: 40px;
  text-align: center;

  .success-icon {
    margin-bottom: 48px;

    img {
      width: 100%;
      max-width: 150px;
    }
  }

  .subHeading,
  .confirmation {
    ::v-deep span {
      font-weight: 600;
    }
  }

  .confirmation {
    background: var(--note-bg-color);
    padding: 8px 12px;
    margin-top: 48px;
  }
}
</style>
