export default {
  namespaced: true,
  state: () => ({
    modalType: "",
  }),
  actions: {
    modalType({ commit }, payload) {
      commit("modalType", payload);
    },
  },
  mutations: {
    modalType(state, payload) {
      state.modalType = payload;
    },
  },
  getters: {
    modalType(state) {
      return state.modalType;
    },
  },
};
